import React, { useMemo } from "react";
import * as Scrivito from "scrivito";
import { priceToString } from "utils/priceToString";
import { translate } from "utils";

export const ProductListTableRow = Scrivito.connect(({ product }) => {
  const {
    title,
    presentation,
    quantity = 1,
    unitPrice,
    _totalPrice,
    priceGroup,
  } = product;

  const { unitPriceString, totalPriceString } = useMemo(
    () => ({
      unitPriceString: priceToString({
        price: unitPrice,
        noPriceText: translate("NO_PRICE"),
      }),
      // TODO should be total price from BE "price: totalPrice", but currently we have issues with that so it's FE monkeypatch for that
      totalPriceString: priceToString({
        price: {
          ...unitPrice,
          centAmount: unitPrice?.centAmount
            ? unitPrice.centAmount * quantity
            : 0,
        },
        noPriceText: translate("NO_PRICE"),
      }),
    }),
    [quantity, unitPrice],
  );

  return (
    <tr>
      <td>
        <div className="text-gray-700 fw-bold">{title}</div>
      </td>
      <td>{presentation}</td>
      <td>{quantity}</td>
      <td>{unitPriceString}</td>
      <td>{totalPriceString}</td>
      <td>{priceGroup}</td>
    </tr>
  );
});
