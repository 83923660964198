import * as Scrivito from "scrivito";
import { formatDate } from "utils";

const locationFromJob = (job) => ({
  "@type": "Place",
  name: job.get("locationName"),
  address: addressFromJob(job),
});

const addressFromJob = (job) => ({
  "@type": "PostalAddress",
  streetAddress: job.get("locationStreetAddress"),
  addressLocality: job.get("locationLocality"),
  addressRegion: job.get("locationRegion"),
  postalCode: job.get("locationPostalCode"),
  addressCountry: job.get("locationCountry"),
});

const dataFromJob = (job) => ({
  "@context": "http://schema.org",
  "@type": "JobPosting",
  title: job.get("title"),
  datePosted: formatDate(job.get("datePosted"), "YYYY-MM-DD"),
  validThrough: formatDate(job.get("validThrough"), "YYYY-MM-DD"),
  description: Scrivito.extractText(job),
  employmentType: job.get("employmentType"),
  hiringOrganization: {
    "@type": "Organization",
    name: job.get("hiringOrganizationName"),
    sameAs: job.get("hiringOrganizationWebsite"),
  },
  jobLocation: locationFromJob(job),
});

export default dataFromJob;
