import React, { useCallback, useMemo } from "react";
import { Field, Form, Formik } from "formik";
import { useMutation } from "urql";
import * as Scrivito from "scrivito";
import NiceModal from "@ebay/nice-modal-react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Button } from "components/Button";
import { useAppBaseContext } from "providers";
import { DuplicateProjectMut } from "api/mutations";
import * as Yup from "yup";
import { TextInput } from "components/Inputs";
import { RadioInput } from "components/Inputs/FormikInputs/RadioInput";
import { translate } from "utils";

const initialValues = {
  copy: false,
  email: "",
  notes: "",
};

export const ShareProjectModal = NiceModal.create(
  Scrivito.connect(({ project, refetchProjectList }) => {
    const modal = NiceModal.useModal();
    const [{ fetching }, exeShareMutation] = useMutation(DuplicateProjectMut);
    const { lang } = useAppBaseContext();

    const validation = useMemo(
      () =>
        Yup.object({
          email: Yup.string().required(
            translate("PROJECT_SHARE_RECIPIENT_EMPTY"),
          ),
        }),
      [],
    );

    const handleSubmit = useCallback(
      (values, { setFieldError }) => {
        if (fetching) {
          return;
        }
        exeShareMutation({
          id: project.id,
          languageCode: lang,
          recipientEmail: values.email,
          notes: values.notes,
        }).then((data) => {
          if (!data.error) {
            refetchProjectList && refetchProjectList();
            modal.hide();
          } else {
            setFieldError("email", translate("PROJECT_SHARE_ERROR"));
          }
        });
      },
      [refetchProjectList, exeShareMutation, lang, project, modal, fetching],
    );

    return (
      <Modal
        isOpen={modal.visible}
        centered={true}
        size="md"
        toggle={modal.hide}
      >
        <Formik
          initialValues={initialValues}
          enableReinitialize
          onSubmit={handleSubmit}
          validationSchema={validation}
        >
          <Form>
            <ModalHeader
              toggle={modal.hide}
              tag="div"
              className="pb-2_5 px-3_5"
            >
              <h3 className="ms-0 text-uppercase mb-0">
                {translate("PROJECT_SHARE_TITLE")}
              </h3>
              <h4 className="ms-0 mb-0">{project.title}</h4>
            </ModalHeader>
            <ModalBody className="d-flex row w-100 ms-0 pt-0 px-2">
              <div>
                <Field
                  component={RadioInput}
                  htmlFor="copy"
                  name="copy"
                  label={translate("PROJECT_COPY_LABEL")}
                  type="radio"
                  className="fw-bold mt-1"
                  value="true"
                  labelclass="fw-bold mt-1"
                  disabled
                />
                <div className="mt-2">{translate("PROJECT_COPY_TEXT")}</div>
              </div>
              <div className="col-12 mt-1">
                <Field
                  component={TextInput}
                  name="email"
                  type="email"
                  autoFocus={true}
                  label={translate("PROJECT_EMAIL_LABEL")}
                  data-recording-disable={true}
                  placeholder="Email"
                  wrapperClassName="mt-3"
                />
                <div className="mt-n2_5">
                  {translate("PROJECT_SHARE_EMAIL")}
                </div>
              </div>
              <div className="col-12 mt-2_5">
                <Field
                  component={TextInput}
                  name="notes"
                  type="textarea"
                  label={translate("PROJECT_NOTES_LABEL")}
                  autoFocus={true}
                  data-recording-disable={true}
                  placeholder=""
                  wrapperClassName="h-100 mb-3 pb-2 d-flex flex-column"
                  maxLength={500}
                />
              </div>
            </ModalBody>
            <ModalFooter className="py-3 d-flex gap-2_5">
              <Button color="outline-dark" type="button" onClick={modal.hide}>
                {translate("CANCEL")}
              </Button>
              <Button
                color="primary"
                type="submit"
                disabled={fetching}
                isLoading={fetching}
              >
                {translate("SHARE")}
              </Button>
            </ModalFooter>
          </Form>
        </Formik>
      </Modal>
    );
  }),
);
