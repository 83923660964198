import React, { useState, useEffect } from "react";
import * as Scrivito from "scrivito";
import { SliderWithGallery } from "components/Sliders/SliderWithGallery";
import { VideoComponent } from "components/VideoComponent";

export const MediaItemComponent = Scrivito.connect(({ page }) => {
  const [videos, setVideos] = useState([]);
  const [images, setImages] = useState([]);

  useEffect(() => {
    Scrivito.load(() => {
      const mediasObjs = page.get("medias") || [];
      const videoObjs = mediasObjs.filter(
        (media) => media.objClass() === "VideoVimeo",
      );
      const imageObjs = mediasObjs.filter(
        (media) => media.objClass() === "Image",
      );
      setVideos(videoObjs);
      setImages(imageObjs);
    });
  }, [page]);

  if (images.length > 0) {
    return <SliderWithGallery images={images} />;
  }
  if (videos.length > 0) {
    return <VideoComponent obj={videos[0]} />;
  }
  return null;
});
