import defaultTranslations from "config/locals/defaultTranslations";

export const allDefaultTranslationKeys =
  Object.keys(defaultTranslations).sort();

export const initKeyGroups = [
  { value: "A-E", label: "A-E" },
  { value: "F-J", label: "F-J" },
  { value: "K-O", label: "K-O" },
  { value: "P-T", label: "P-T" },
  { value: "U-Z", label: "U-Z" },
];

export const initLangFilters = [
  { value: "de-DE", label: "de-DE" },
  { value: "en-DE", label: "en-DE" },
  { value: "es-DE", label: "es-DE" },
  { value: "nl-DE", label: "nl-DE" },
];
