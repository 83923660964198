import React, { useEffect, useRef } from "react";
import * as Scrivito from "scrivito";
import cx from "classnames";
import { useResizeDetector } from "react-resize-detector";
import { useMediaQuery } from "react-responsive";
import { getPageLink } from "utils";
import { SCREEN_LG } from "variables";

import s from "./Footer.module.scss";
import { adjustLinkGaps } from "./FooterNavigationHelpers";

export const FooterNavigation = Scrivito.connect(({ homepage }) => {
  const navigationFooterLinks = homepage.get("navigationFooterLinks") || [];
  const listRef = useRef(null);
  const isMobile = useMediaQuery({
    query: `(max-width: ${SCREEN_LG - 1}px)`,
  });
  const { width: rowWidth, ref: navRef } = useResizeDetector();

  useEffect(() => {
    adjustLinkGaps(isMobile, listRef);
  }, [isMobile, rowWidth, navigationFooterLinks.length]);

  return (
    <>
      <nav ref={navRef} className={cx(s.footerNav, "text-white")}>
        <ul
          ref={listRef}
          className="list-unstyled p-0 d-grid flex-wrap d-lg-flex"
        >
          {navigationFooterLinks.map((bottomLink, idx) => (
            <li key={`footerBottomLink-${idx}`} className="d-inline-block">
              <Scrivito.LinkTag to={getPageLink(bottomLink)}>
                {bottomLink.title() ||
                  (bottomLink.isInternal()
                    ? bottomLink.obj().get("title")
                    : bottomLink.url())}
              </Scrivito.LinkTag>
            </li>
          ))}
        </ul>
      </nav>
      <p className={cx(s.footerCopyright)}>
        {new Date().getFullYear()} &copy; TROX GmbH
      </p>
    </>
  );
});
