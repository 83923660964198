import { useMemo } from "react";
import { useQuery } from "urql";
import { ProjectStatusesQry } from "api/queries";

export const useProjectStatuses = (pause = false) => {
  const [{ data }] = useQuery({
    query: ProjectStatusesQry,
    context: useMemo(() => ({ requestPolicy: "cache-first" }), []),
    pause,
  });

  return data?.currentUser?.projectStatuses;
};
