import React, { useCallback, useEffect, useRef, useState } from "react";
import { Input, InputGroup, InputGroupText } from "reactstrap";
import cx from "classnames";
import { useClickOutside } from "hooks";
import { Icon } from "../Icon/Icon";

export const NumberInput = ({
  initialValue,
  onSave,
  allowOutsidePropagation = true,
  min = 0,
  max,
  disabled,
  classNames,
}) => {
  const inputRef = useRef(null);
  const initialValueRef = useRef(initialValue); // Store initial value in a ref

  const [inputValue, setInputValue] = useState(initialValue || 0);

  const handlePlusClick = useCallback(() => {
    if (disabled) {
      return;
    }
    setInputValue((val) => (!max || val < max ? +val + 1 : val));
  }, [max, disabled]);

  const handleMinusClick = useCallback(() => {
    if (disabled) {
      return;
    }
    setInputValue((val) => (val > min ? +val - 1 : val));
  }, [min, disabled]);

  const handleChange = useCallback((e) => {
    setInputValue(e.target.value);
  }, []);

  const handleSave = useCallback(() => {
    if (inputValue !== initialValueRef.current) {
      let valueToSave = inputValue;
      if (inputValue < min) {
        valueToSave = min;
        setInputValue(min);
      }
      if (max && inputValue > max) {
        valueToSave = max;
        setInputValue(max);
      }
      onSave(valueToSave);
    }
  }, [inputValue, max, min, onSave]);

  // Update initialValueRef if initialValue changes
  useEffect(() => {
    initialValueRef.current = initialValue;
  }, [initialValue]);

  const handleEnterPressed = useCallback(
    (e) => {
      if (e.key === "Enter") {
        handleSave();
      }
    },
    [handleSave],
  );

  useEffect(() => {
    document.addEventListener("keydown", handleEnterPressed);
    return () => document.removeEventListener("keydown", handleEnterPressed);
  }, [handleEnterPressed]);

  useClickOutside(inputRef, handleSave, true, allowOutsidePropagation);

  return (
    <div ref={inputRef} className={classNames}>
      <InputGroup>
        <InputGroupText
          className="g-gray-100 p-1"
          onClick={handleMinusClick}
          data-testid="-"
        >
          <Icon
            iconName="minus"
            className={cx("icon-12", {
              "cursor-not-allowed opacity-20 disabled":
                +min === inputValue || disabled,
            })}
          />
        </InputGroupText>
        <Input
          className="rounded-0 text-center appearance-none fs-md p-0"
          style={{ width: "40px", opacity: disabled ? 0.5 : 1 }}
          bsSize="sm"
          type="number"
          min={0}
          onChange={handleChange}
          disabled={disabled}
          value={inputValue}
          data-testid="number-input"
          onBlur={(ev) => !ev.target.value && setInputValue(min)}
        />
        <InputGroupText
          className="bg-gray-100 p-1"
          onClick={handlePlusClick}
          data-testid="+"
        >
          <Icon
            iconName="plus"
            className={cx("icon-12", {
              "cursor-not-allowed opacity-20 disabled":
                +max === inputValue || disabled,
            })}
          />
        </InputGroupText>
      </InputGroup>
    </div>
  );
};
