import moment from "moment";

export const formatDate = (date, format, parseDateFormat = null) => {
  if (!date) {
    return null;
  }

  if (parseDateFormat) {
    return moment(date, parseDateFormat).format(format);
  }

  return moment(date).format(format);
};
