const minGap = 10;

export const getListItemsData = (listItems, listWidth) =>
  [].reduce.call(
    listItems,
    (sum, elem, index) => {
      const { innerText } = elem;
      const { width: elemWidth } = elem.getBoundingClientRect();
      const total = sum.total + elemWidth;
      const totalWithGaps =
        sum.items.length > 0
          ? sum.items[index - 1].totalWithGaps + elemWidth + minGap
          : elemWidth + minGap;
      const isFit = totalWithGaps <= listWidth;
      const itemInfo = {
        w: elemWidth,
        innerText,
        elem,
        total,
        totalWithGaps,
        isFit,
      };
      sum.items.push(itemInfo);
      sum.total = total;
      sum.totalWithGaps = totalWithGaps;
      sum.lastFitIndex = isFit ? index : sum.lastFitIndex;
      return sum;
    },
    { total: 0, items: [] },
  );

export const getNewGap = (listItemsData, listWidth) => {
  const { lastFitIndex, items } = listItemsData;
  if (!lastFitIndex || !items[lastFitIndex]) {
    return minGap;
  }
  const { total } = items[lastFitIndex];
  const diff = Math.abs(listWidth - items[lastFitIndex].total);
  let newGap = Math.floor(diff / lastFitIndex) + 100;
  const getNewGaps = () => newGap * lastFitIndex;
  while (getNewGaps() + total >= listWidth) {
    newGap -= 1;
  }

  const difference = listWidth - total - getNewGaps();

  return { difference, newGap };
};

export const adjustLinkGaps = (isMobile, listRef) => {
  if (isMobile) {
    return;
  }
  const list = listRef.current;
  if (!list) {
    return;
  }
  const { childNodes: listItems } = list;
  const { width: listWidth } = list.getBoundingClientRect();
  const listItemsData = getListItemsData(listItems, listWidth);
  const { difference, newGap } = getNewGap(listItemsData, listWidth);

  list.style.columnGap = `${newGap}px`;

  for (let i = 0; i < listItems.length; i += 1) {
    if (i < difference - 1) {
      listItems[i].style.marginRight = "1px";
    } else {
      listItems[i].style.marginRight = null;
    }
  }
};
