import React, { useCallback } from "react";
import { Field, Form, Formik } from "formik";
import * as Scrivito from "scrivito";
import NiceModal from "@ebay/nice-modal-react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { translate } from "utils";
import { Button } from "../Button";
import { RadioInput } from "../Inputs/FormikInputs/RadioInput";
import { PriceCalculationModal } from "./PriceCalculationModal";
import { QuestionWrapper } from "../QuestionWrapper";

const initialValues = {
  configuration: "RoomControl_OnSiteConf",
};

export const ServicePriceModal = NiceModal.create(
  Scrivito.connect(({ projectId, nodeId, subType }) => {
    const modal = NiceModal.useModal();
    const handleSubmit = useCallback(
      (fields) => {
        modal.resolve();
        modal.remove();
        NiceModal.show(PriceCalculationModal, {
          configurationOption: fields.configuration,
          projectId,
          nodeId,
          subType,
        });
      },
      [modal, nodeId, projectId, subType],
    );

    return (
      <Modal
        isOpen={modal.visible}
        centered={true}
        size="md"
        toggle={modal.hide}
      >
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialValues}
          enableReinitialize
        >
          <Form>
            <ModalHeader
              toggle={modal.hide}
              tag="div"
              className="d-flex align-items-start pb-3"
            >
              <h3 className="text-uppercase mb-1">
                {translate("SERVICE_MODAL_TITLE")}
              </h3>
              <h4 className="mb-2_5">{translate("SERVICE_MODAL_SUBTITLE")}</h4>
              <div className="mb-0 font16 font-normal text-gray-700">
                {translate("SERVICE_MODAL_DESCRIPTION")}
              </div>
            </ModalHeader>
            <ModalBody className="d-flex row px-3 py-0 text-gray-700">
              <QuestionWrapper
                data={{
                  info: translate("SERVICE_MODAL_QUESTION_INFO"),
                  title: translate("SERVICE_MODAL_QUESTION_TITLE"),
                  identifier: "commissioning",
                }}
              >
                <div className="d-flex flex-nowrap">
                  <Field
                    component={RadioInput}
                    name="configuration"
                    type="radio"
                    autoFocus={true}
                    data-recording-disable={true}
                    label={translate("SERVICE_MODAL_OPT_ON_SITE")}
                    value="RoomControl_OnSiteConf"
                    id="on-site-configuration"
                    htmlFor="on-site-configuration"
                    classNames="me-4"
                  />
                  <Field
                    component={RadioInput}
                    name="configuration"
                    type="radio"
                    data-recording-disable={true}
                    label={translate("SERVICE_MODAL_WITHOUT_COMMISSIONING")}
                    value="commissionNotIncluded"
                    id="no-commision"
                    htmlFor="no-commision"
                  />
                </div>
              </QuestionWrapper>
            </ModalBody>
            <ModalFooter className="pt-0">
              <Button
                data-testid="ServicePriceModal_continue-btn"
                color="primary"
                type="submit"
              >
                {translate("CONTINUE")}
              </Button>
            </ModalFooter>
          </Form>
        </Formik>
      </Modal>
    );
  }),
);
