import * as React from "react";
import * as Scrivito from "scrivito";
import { getPageLink } from "utils/getPageLink";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";

import s from "./Icon.module.scss";

const SVG_SPRITE_PATH = "/icons/svg-sprite/svg-sprite-e24d4a89.svg";

const IconDefault = ({
  iconName,
  prefix,
  className = "icon-16",
  title,
  ...rest
}) => {
  let actualIcon = iconName || "fa-coffee";
  const faReg = /^fa-/i;
  const oReg = /-o\b/;

  let actualPrefix;

  if (oReg.test(actualIcon)) {
    actualPrefix = "fa-regular";
    actualIcon = iconName.replace(oReg, "");
  }

  if (!faReg.test(actualIcon) && prefix !== "trox") {
    actualIcon = `fa-${actualIcon}`;
  }

  if (prefix) {
    actualPrefix = prefix;
  }

  if (!actualPrefix) {
    actualPrefix = "fa-solid";
  }

  const iconProp = `${actualPrefix} ${actualIcon}`;
  const isDefaultSize = !!className?.match(/icon-/g)?.length;

  return (
    <React.Suspense fallback={<div>Loading...</div>}>
      <FontAwesomeIcon
        title={title}
        className={cx("svg-inline--fa", className, s.outlineNone, {
          "icon-16": !isDefaultSize,
        })}
        icon={iconProp}
        {...rest}
      />
    </React.Suspense>
  );
};

const IconCustom = ({
  className = "icon-16",
  iconName = "star-light",
  ...rest
}) => {
  // TODO: Change hardcode path to dynamic
  // const svgModules = require.context(
  //   "/icons/svg-sprite",
  //   false,
  //   /\.svg$/
  // );
  // const svgSpritePath = svgModules.resolve(svgModules.keys()[0]);

  const isDefaultSize = !!className?.match(/icon-/g)?.length;

  return (
    <svg
      className={cx("svg-inline--fa", s.svg, s.outlineNone, className, {
        "icon-16": !isDefaultSize,
      })}
      {...rest}
    >
      <use href={`${SVG_SPRITE_PATH}#${iconName}`} />
    </svg>
  );
};

export const Icon = Scrivito.connect(
  ({ iconName = "coffee", prefix, link, title, className, ...rest }) => {
    const titleProp = title || link?.title?.() || "";

    const currentIcon =
      prefix === "trox" ? (
        <IconCustom
          iconName={iconName}
          prefix={prefix}
          title={titleProp}
          className={className}
          {...rest}
        />
      ) : (
        <IconDefault
          iconName={iconName}
          prefix={prefix}
          title={titleProp}
          className={className}
          {...rest}
        />
      );

    if (!link) {
      return currentIcon;
    }

    return (
      <Scrivito.LinkTag to={getPageLink(link)}>{currentIcon}</Scrivito.LinkTag>
    );
  },
);
