import React from "react";
import cx from "classnames";

import { Icon } from "../Icon/Icon";
import s from "./StructureTree.module.scss";
import { TranslateWrapper } from "../TranslateWrapper";

export const FolderWrapper = React.memo(
  ({
    children,
    className: classNameProps = "",
    dropArea,
    handlePaste,
    hasChildren,
    iconset,
    isDroppable = true,
    isDragging = false,
    isDragPreview = false,
    isRightStep = false,
    zonesActive = false,
    itemToCopy,
    onClick,
    folderWrapperRef = null,
    translations = {},
    isSummary,
    ...rest
  }) => (
    <div
      className={cx(
        "d-flex justify-content-between align-items-center text-gray-700 rounded-2 position-relative",
        s.folder,
        {
          [s.folder]: !isDragPreview && !itemToCopy,
          "opacity-50": isDragging,
          [s.dragPreview]: isDragPreview,
          "border-info": isDragPreview,
          [s.pastePreview]: isDroppable && !!itemToCopy,
          [s.leftSidebar]: isRightStep,
          [s.leftSidebarActive]: zonesActive,
          [s.summary]: isSummary,
        },
        classNameProps,
      )}
      onClick={onClick}
      ref={folderWrapperRef}
      {...rest}
    >
      {dropArea && dropArea}
      <div
        id="project-node"
        className={cx(
          "position-absolute flex-nowrap w-100 h-100 me-3 z-index-1",
          s.paste,
          {
            "cursor-pointer": isDroppable && !!itemToCopy,
            "d-none": !itemToCopy,
            "d-block": !!itemToCopy,
          },
        )}
        onClick={handlePaste}
        data-testid="folder-paste-area"
      />
      <div className="d-flex align-items-center w-100 text-nowrap h-100">
        {children}
        <div
          className={cx("ms-2 text-blue-300 text-nowrap", s.pastePreviewButton)}
        >
          <Icon
            className="text-blue-300 me-1"
            iconName="paste-light"
            prefix="trox"
            data-testid="paste-light"
          />
          <>
            {translations.nodePaste}
            {typeof translations.nodePaste === "object" && (
              <TranslateWrapper
                tooltipText={translations.nodePaste.props.tooltipText}
                tooltipId="project-node"
              />
            )}
          </>
        </div>
      </div>
      {!isSummary && (iconset || hasChildren) && (
        <div
          className={cx("d-flex align-items-center", {
            "pe-1": !hasChildren,
          })}
        >
          {iconset && iconset}
        </div>
      )}
    </div>
  ),
);
