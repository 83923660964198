import React, { createContext, useContext, useState } from "react";

const ProjectSummarySidebarContext = createContext({});
export const ProjectSummarySidebarProvider = ({ children }) => {
  const [isExtended, setIsExtended] = useState(false);
  const toggleIsExtended = () => setIsExtended((prev) => !prev);
  const context = {
    isExtended,
    toggleIsExtended,
  };
  return (
    <ProjectSummarySidebarContext.Provider value={context}>
      {children}
    </ProjectSummarySidebarContext.Provider>
  );
};

export const useProjectSummarySidebarContext = () =>
  useContext(ProjectSummarySidebarContext);
