import React, { createContext, useContext, useState, useCallback } from "react";
import { useMutation } from "urql";
import NiceModal from "@ebay/nice-modal-react";
import { translate } from "utils";
import { ConfigUpdateMut } from "api/mutations";
import { ConfirmationModal } from "../components/_Modals/ConfirmationModal";

const ConfiguratorPopUpContext = createContext({});

export const ConfiguratorPopupProvider = ({ children }) => {
  const [params, setParams] = useState(null);
  const [isUpdateButtonActive, setIsUpdateButtonActive] = useState(false);
  const [isResetButtonActive, setIsResetButtonActive] = useState(false);
  const [, exeEditProduct] = useMutation(ConfigUpdateMut);

  const handleUpdate = useCallback(
    (navFunc) => {
      exeEditProduct({
        ...params,
      }).then(() => navFunc());
    },
    [exeEditProduct, params],
  );

  const showConfiguratorPopup = useCallback(
    (navFunc) => {
      NiceModal.show(ConfirmationModal, {
        iconName: "save-regular",
        title: translate("TEXT_SAVE_CHANGES"),
        alertText: translate("SAVE_PRODUCT_DATA_CONFIRMATION_ALERT"),
        submitBtnText: translate("SAVE"),
        onConfirm: () => handleUpdate(navFunc),
        onCancel: () => navFunc(),
      }).finally(() => {
        setIsUpdateButtonActive(false);
        setParams(null);
      });
    },
    [handleUpdate],
  );

  const context = {
    setIsUpdateButtonActive,
    setParams,
    isUpdateButtonActive,
    showConfiguratorPopup,
    isResetButtonActive,
    setIsResetButtonActive,
  };

  return (
    <ConfiguratorPopUpContext.Provider value={context}>
      {children}
    </ConfiguratorPopUpContext.Provider>
  );
};
export const useConfiguratorPopupContext = () =>
  useContext(ConfiguratorPopUpContext);
