import { useEffect, useState } from "react";
import { load, Obj } from "scrivito";

export const useFetchScrivitoImages = (images) => {
  const [scrivitoImages, setScrivitoImages] = useState([]);

  useEffect(() => {
    const productImagePimIds = images
      .map((img) => img.pimId)
      .filter((item) => item !== "");

    const fetchData = async () => {
      if (productImagePimIds.length > 0) {
        await load(() =>
          Obj.where("pimId", "equals", productImagePimIds).take(),
        ).then((objs) => {
          const idList = images.map((img) => img.pimId);

          const sortedFilledObjs = idList.map((pimId) => {
            const selectedObj = objs.find((obj) => obj.get("pimId") === pimId);
            return selectedObj || pimId;
          });

          setScrivitoImages(sortedFilledObjs);
        });
      }
    };

    fetchData();
  }, [images]);

  return scrivitoImages;
};
