import React, { useCallback, useMemo } from "react";
import { useFormikContext } from "formik";
import cx from "classnames";
import {
  Label,
  FormGroup,
  FormFeedback,
  Input,
  InputGroup,
  InputGroupText,
} from "reactstrap";

export const CurrencyInput = ({
  field,
  form,
  wrapperClassName,
  label,
  ...restProps
}) => {
  const meta = form.getFieldMeta(field.name);
  const { setFieldValue } = useFormikContext();
  const isInvalid = useMemo(
    () => meta.touched && !!meta.error,
    [meta.error, meta.touched],
  );
  const handleBlur = useCallback(() => {
    field.value &&
      setFieldValue(field.name, parseFloat(field.value).toFixed(2));
  }, [field, setFieldValue]);

  return (
    <FormGroup className={wrapperClassName}>
      {label && (
        <Label
          className="label-small fw-bolder d-flex align-items-center justify-content-between mt-1 text-gray-700"
          for={field.name}
        >
          {label}
        </Label>
      )}
      {field && (
        <InputGroup>
          <Input
            {...field}
            invalid={isInvalid}
            onBlur={handleBlur}
            type="number"
            value={field.value}
            {...restProps}
          />
          <InputGroupText className="text-gray-700 bg-white border-start-0">
            EUR
          </InputGroupText>
        </InputGroup>
      )}
      <FormFeedback className={cx("mt-2", { "d-flex": isInvalid })}>
        {meta.error}
      </FormFeedback>
    </FormGroup>
  );
};
