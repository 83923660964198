import tenantsJSONfile from "config/tenants.json";
import { isBrowserEnv } from "./isWindowObjAvailable";

export const getTenantSettings = (tenantsJSON = tenantsJSONfile) => {
  const troxEnv = process.env.TROX_ENVIRONMENT;
  if (!troxEnv) {
    return console.error("TROX_ENVIRONMENT needs to be defined");
  }
  if (!tenantsJSON[troxEnv] || !tenantsJSON[troxEnv].tenants) {
    return console.error("No tenants set for declared TROX_ENVIRONMENT");
  }
  if (!tenantsJSON[troxEnv].default_tenant) {
    return console.error("No default_tenant set for tenants set");
  }
  if (
    isBrowserEnv() &&
    tenantsJSON[troxEnv].tenants[window.location.hostname]
  ) {
    return tenantsJSON[troxEnv].tenants[window.location.hostname];
  }
  const defaultTenant = tenantsJSON[troxEnv].default_tenant;
  return tenantsJSON[troxEnv].tenants[defaultTenant];
};

export const linkHostname = (tenantsJSON = tenantsJSONfile) =>
  `www.${tenantsJSON.production.default_tenant}`;
