import { gql } from "@urql/core";

// QUERIES

const questionsFragment = gql`
  fragment questionsFragment on Questionnaire {
    questions {
      __typename
      title
      identifier
      position
      description
      info
      iconName
      constraints {
        scope
        reference
        minValue
        maxValue
        warningMessage
      }
      fields {
        title
        fieldKey
        fieldValue
        fieldType
        fieldInputType
        fieldDefaultValue
        fieldMin
        fieldMax
        values
        enabled
        onAction
        iconName
      }
    }
  }
`;

const basicQuestionnaireInfoFragment = gql`
  fragment basicQuestionnaireInfoFragment on Questionnaire {
    __typename
    title
    description
    info
    position
    id
  }
`;

export const questionnaireFragment = gql`
  fragment questionnaireFragment on Questionnaire {
    ...basicQuestionnaireInfoFragment
    ...questionsFragment
  }
  ${basicQuestionnaireInfoFragment}
  ${questionsFragment}
`;

export const wizardBodyFragment = gql`
  fragment wizardBodyFragment on wizardsInfo {
    __typename
    availableSteps
    constraints {
      minValue
      maxValue
      scope
      reference
      warningMessage
    }
    language
    node
    nodeId
    projectId
    step
    steps
    subType
    topology
  }
`;

const WizardRunQry = gql`
  query (
    $subType: String
    $language: String
    $projectId: String
    $nodeId: String
    $step: Int
  ) {
    wizardRun(
      subType: $subType
      language: $language
      projectId: $projectId
      nodeId: $nodeId
      step: $step
    ) {
      ...wizardBodyFragment
      currentQuestionnaire {
        ...questionnaireFragment
      }
      questionnaireLists {
        ...basicQuestionnaireInfoFragment
      }
    }
  }
  ${wizardBodyFragment}
  ${questionnaireFragment}
  ${basicQuestionnaireInfoFragment}
`;

const WizardStepQuestionnaire = gql`
  query ($nodeId: String!, $step: Int!, $id: String!) {
    wizardStepQuestionnaire(nodeId: $nodeId, step: $step, id: $id) {
      ...questionnaireFragment
    }
  }
  ${questionnaireFragment}
`;

export { WizardRunQry, WizardStepQuestionnaire };
