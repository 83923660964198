import React, { useState } from "react";
import * as Scrivito from "scrivito";
import {
  Collapse,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Nav,
  NavItem,
  Navbar,
} from "reactstrap";
import {
  getAvailableLanguages,
  getBasePath,
  getPageLink,
  translate,
} from "utils";

export const UpperNavigation = Scrivito.connect(({ homepage }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  if (!homepage) {
    return null;
  }
  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);
  const upperNavLinks = homepage.get("navigationServiceLinks") || [];
  const homepagesLangs = getAvailableLanguages();

  return (
    <Navbar light expand="lg" className="nav-upper-container p-0">
      <Collapse navbar className="nav-main-collapse">
        <Nav navbar className="mt-0 mb-5 nav-upper">
          {upperNavLinks.map((link, idx) => (
            <NavItem
              data-testid={`UpperNavigation_item-${idx}`}
              key={`nav-upper-item-${idx}`}
            >
              <Scrivito.LinkTag
                to={getPageLink(link)}
                className="nav-upper-link"
              >
                {link.title()}
              </Scrivito.LinkTag>
            </NavItem>
          ))}
          {homepagesLangs.length > 1 && (
            <NavItem>
              <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                <DropdownToggle className="p-0 bg-transparent text-primary border-0 nav-upper-lang-dropdown-btn lh-1">
                  {translate("TEXT_LANGUAGE")}
                </DropdownToggle>
                <DropdownMenu className="m-0 nav-upper-lang-dropdown-menu">
                  {homepagesLangs.map((lang) => (
                    <DropdownItem
                      key={`nav-upper-lang-${lang}`}
                      className="nav-upper-lang-dropdown-menu-item"
                    >
                      <Scrivito.LinkTag
                        data-testid={`UpperNavigation_lang-${lang}`}
                        className="nav-upper-lang-dropdown-menu-link"
                        to={getPageLink(
                          Scrivito.Obj.getByPath(`${getBasePath()}/${lang}`) ||
                            homepage,
                        )}
                      >
                        {translate(`LANG_${lang}`)}
                      </Scrivito.LinkTag>
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
            </NavItem>
          )}
        </Nav>
      </Collapse>
    </Navbar>
  );
});
