import React from "react";
import { Honeybadger, HoneybadgerErrorBoundary } from "@honeybadger-io/react";

export const HBErrorBoundary = ({ children }) => {
  const environment = process.env.TROX_ENVIRONMENT;
  const config = {
    apiKey: process.env.HONEYBADGER_KEY,
    environment,
  };

  if (environment === "production" || environment === "staging") {
    const honeybadger = Honeybadger.configure(config);
    return (
      <HoneybadgerErrorBoundary honeybadger={honeybadger}>
        {children}
      </HoneybadgerErrorBoundary>
    );
  }
  return <>{children}</>;
};
