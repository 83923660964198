export const items = [
  {
    title: "PRODUCT_INFORMATION",
    content: [
      {
        id: 1,
        title: "Installation manual Air terminal devices",
        fileName:
          "IM_2018_06_A00000054447 | Installation manual | 1,2 MB | pdf",
      },
    ],
    checked: false,
  },
  {
    title: "SPECIFICATION_TEXT",
    content: [
      {
        id: 1,
        title: "Installation manual Air terminal devices",
        fileName:
          "IM_2018_06_A00000054447 | Installation manual | 1,2 MB | pdf",
      },
    ],
    checked: false,
  },
  // {
  //   title: "BIM Data",
  //   content: [
  //     {
  //       id: 1,
  //       title: "Installation manual Air terminal devices",
  //       fileName:
  //         "IM_2018_06_A00000054447 | Installation manual | 1,2 MB | pdf",
  //     },
  //   ],
  //   checked: true,
  // },
  // {
  //   title: "Manuals",
  //   content: [
  //     {
  //       id: 1,
  //       title: "Installation manual Air terminal devices",
  //       fileName:
  //         "IM_2018_06_A00000054447 | Installation manual | 1,2 MB | pdf",
  //     },
  //   ],
  //   checked: true,
  // },
  // {
  //   title: "Certificates",
  //   content: [
  //     {
  //       id: 1,
  //       title: "Installation manual Air terminal devices",
  //       fileName:
  //         "IM_2018_06_A00000054447 | Installation manual | 1,2 MB | pdf",
  //     },
  //   ],
  //   checked: true,
  // },
];

export const systemExportItems = [
  {
    title: "PROJECT_REPORT",
    id: 1,
    content: [
      {
        id: "Project Report",
        // title: "Installation manual Air terminal devices",
        // fileName:
        //   "IM_2018_06_A00000054447 | Installation manual | 1,2 MB | pdf",
      },
    ],
    checked: true,
  },
  // {
  //   title: "BIM Data",
  //   content: [
  //     {
  //       id: 1,
  //       title: "Installation manual Air terminal devices",
  //       fileName:
  //         "IM_2018_06_A00000054447 | Installation manual | 1,2 MB | pdf",
  //     },
  //   ],
  //   checked: true,
  // },
  {
    title: "PRODUCT_LIST",
    id: 2,
    content: [
      {
        id: "BOM",
        // title: "Installation manual Air terminal devices",
        // fileName:
        //   "IM_2018_06_A00000054447 | Installation manual | 1,2 MB | pdf",
      },
    ],
    checked: true,
  },
  // {
  //   title: "System Description",
  //   content: [
  //     {
  //       id: 1,
  //       title: "Installation manual Air terminal devices",
  //       fileName:
  //         "IM_2018_06_A00000054447 | Installation manual | 1,2 MB | pdf",
  //     },
  //   ],
  //   checked: true,
  // },
];

// export const productDataItems = [
//   {
//     title: "Airnamic",
//     sublist: false,
//     content: [
//       {
//         id: 1,
//         title: "Installation manual Air terminal devices",
//         fileName:
//           "IM_2018_06_A00000054447 | Installation manual | 1,2 MB | pdf",
//       },
//     ],
//     checked: true,
//   },
//   {
//     title: "TVE",
//     sublist: true,
//     content: items,
//     checked: true,
//   },
// ];
