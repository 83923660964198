import React from "react";

// import { Button } from "../../Button";
// import { Icon } from "../../Icon/Icon";

export const ProjectDownloadAccordionItemSublist = ({ listOfDownloads }) => (
  <ul className="list-unstyled">
    {listOfDownloads.map((contentItem) => (
      <li
        key={contentItem.id}
        className="d-flex justify-content-between align-items-center"
      >
        {/* <Button
          color="link"
          className="p-0 text-gray-700"

          disabled
        >
          <Icon
            prefix="trox"
            iconName="arrow-down-bracket-regular"
            className="me-1"
          />
          {contentItem.title}
        </Button> */}
        <span className="text-gray-400">{contentItem.fileName}</span>
      </li>
    ))}
  </ul>
);
