import * as Scrivito from "scrivito";
import moment from "moment";
import { getCategoryClient, getCategoryLanguage } from "./filterByTenant";
import { getHomepage } from "./getHomepage";

export const getPresentObjSearch = () => {
  const currentDate = moment().startOf("day").toDate();
  return Scrivito.Obj.all()
    .andNot("publishFrom", "isGreaterThan", currentDate)
    .andNot("publishUntil", "isLessThan", currentDate);
};

export const getObjsByCategoryClient = (objSearch) => {
  if (!objSearch) {
    return console.error("parameter objSearch is required");
  }
  if (
    !objSearch.constructor ||
    typeof objSearch.toArray !== "function" ||
    !Array.isArray(objSearch.toArray())
  ) {
    return console.error("parameter objSearch needs to be ObjSearch type");
  }
  const categoryClient = getCategoryClient();

  return objSearch.and("categoryClients", "equals", categoryClient);
};

export const getObjsByCategoryLanguage = (objSearch) => {
  if (!objSearch) {
    return console.error("parameter objSearch is required");
  }
  if (
    !objSearch.constructor ||
    typeof objSearch.toArray !== "function" ||
    !Array.isArray(objSearch.toArray())
  ) {
    return console.error("parameter objSearch needs to be ObjSearch type");
  }
  return objSearch.and("categoryLanguages", "equals", getCategoryLanguage());
};

export const getObjSearchWithHomepage = () => {
  const homepage = getHomepage();
  return getPresentObjSearch().and("_path", "startsWith", homepage.path());
};
