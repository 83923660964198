import { useEffect, useState } from "react";
import * as Scrivito from "scrivito";

export const usePageType = () => {
  const [pageType, setPageType] = useState(null);

  const currentPageType = Scrivito.currentPage()?.objClass() || null;

  useEffect(() => {
    setPageType(currentPageType);
  }, [currentPageType]);
  return pageType;
};
