import * as React from "react";
import * as Scrivito from "scrivito";
import Slider from "react-slick";
import { InPlaceEditingPlaceholder } from "components/InPlaceEditingPlaceholder";
import { NextArrow } from "./SliderNavigation/nextArrow";
import { PrevArrow } from "./SliderNavigation/prevArrow";

import "./HeroSliderComponent.scss";

export const SliderForImages = Scrivito.connect(({ images = [] }) => {
  const settings = {
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    autoplay: true,
    autoplaySpeed: 3000,
  };
  if (!images.length) {
    return (
      <InPlaceEditingPlaceholder center>
        Select images in the properties.
      </InPlaceEditingPlaceholder>
    );
  }
  return (
    <div className="d-flex justify-content-center">
      <div className="hero-slider-container image-slider">
        <Slider {...settings}>
          {images.map((image, index) =>
            image.objClass() === "Image" ? (
              <div className="slide" key={index}>
                <Scrivito.ImageTag content={image} className="img-fluid" />
              </div>
            ) : (
              <p key={`no-image-${index}`}>
                Slides needs to be type of Image Obj
              </p>
            ),
          )}
        </Slider>
      </div>
    </div>
  );
});
