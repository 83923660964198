import React, { createContext, useContext, useState, useMemo } from "react";

const PageLoadStateContext = createContext({});

export const PageLoadStateProvider = ({ children }) => {
  const [isFetching, setIsFetching] = useState(false);
  const [isLoaderXCircle, setIsLoaderXCircle] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const context = useMemo(
    () => ({
      isFetching,
      setIsFetching,
      isLoaderLine: isFetching && (!isLoaderXCircle || isModalOpen),
      isLoaderXCircle: isFetching && isLoaderXCircle && !isModalOpen,
      setIsLoaderXCircle,
      isModalOpen,
      setIsModalOpen,
    }),
    [isFetching, isLoaderXCircle, isModalOpen],
  );

  return (
    <PageLoadStateContext.Provider value={context}>
      {children}
    </PageLoadStateContext.Provider>
  );
};

export const useStateLoaderContext = () => useContext(PageLoadStateContext);
