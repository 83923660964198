import { useEffect, useState, useRef } from "react";
import { useClient } from "urql";

export const useFetchQuery = (query, initialVariables) => {
  const client = useClient();
  const [data, setData] = useState(null);
  const [fetching, setFetching] = useState(true);
  const [error, setError] = useState(null);

  const variablesRef = useRef(initialVariables);

  useEffect(() => {
    const fetchData = async () => {
      setFetching(true);
      try {
        const result = await client
          .query(query, variablesRef.current)
          .toPromise();
        if (result.error) {
          setError(result.error);
        } else {
          setData(result.data);
        }
      } catch (err) {
        setError(err);
      } finally {
        setFetching(false);
      }
    };

    fetchData();
  }, [client, query]);

  return { data, fetching, error };
};
