import React from "react";
import * as Scrivito from "scrivito";
import { translate } from "utils";
import { Alert } from "reactstrap";

import { Marker } from "../../Marker";

export const ProductListCalculationMarker = Scrivito.connect(() => (
  <Alert color="light" className="bg-gray-100 p-0 d-flex flex-nowrap">
    <Marker color="gray-400" />
    <div className="d-inline-block p-2_5 text-gray-700">
      <p className="m-0">{translate("PRICE_CALC_MODAL_DUMMIES_INFO")}</p>
    </div>
  </Alert>
));
