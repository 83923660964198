import React, { useMemo, useRef } from "react";
import * as Scrivito from "scrivito";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import { getPageLink, isActiveObj } from "utils";

import "./HeroSliderComponent.scss";

const pagination = {
  clickable: true,
  renderBullet(_, className) {
    return `<span class="${className}"></span>`;
  },
};

const settingsMainImage = {
  loop: true,
  speed: 3000,
  slidesPerView: 1,
  spaceBetween: 50,
  modules: [Navigation, Pagination],
};

const settingsText = {
  loop: true,
  speed: 2000,
  slidesPerView: 1,
  modules: [Navigation, Pagination],
  pagination,
};

export const HeroSlider = Scrivito.connect(({ slides }) => {
  const firstSliderRef = useRef(null);
  const secondSliderRef = useRef(null);

  const activeSlides = useMemo(
    () =>
      slides?.filter(
        (slide) =>
          isActiveObj(slide) &&
          (slide.objClass() === "TextImage2dSlide" ||
            slide.objClass() === "TextImageSlide" ||
            slide.objClass() === "HomepagePresentationSlide" ||
            slide.objClass() === "ImagesSlide"),
      ),
    [slides],
  );

  return (
    <div className="d-flex justify-content-center">
      <div className="hero-slider-container">
        <div className="hero-main-image-container">
          <Swiper
            ref={firstSliderRef}
            {...settingsMainImage}
            className="mySwiper"
          >
            {activeSlides?.map((slide, index) => (
              <SwiperSlide key={index}>
                <div className="hero-main-image-slide">
                  {slide.get("backgroundImage") && (
                    <Scrivito.ImageTag
                      content={slide}
                      attribute="backgroundImage"
                    />
                  )}
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="hero-text-container">
          <Swiper ref={secondSliderRef} className="mySwiper" {...settingsText}>
            {activeSlides?.map((slide, index) => {
              const link = slide.get("targetLink");
              const title = slide.get("title");
              // render TextImageSlide and TextImage2dSlide in a similar way
              // Trox waits for general designs change, so we shouldn't recreate all possibe Slides Objs
              return (
                <SwiperSlide key={index}>
                  <div className="text-container">
                    <div className="slide-text slide-x-right slide-y-center text-start">
                      {slide.objClass() === "TextImageSlide" && (
                        <Scrivito.ContentTag
                          tag="small"
                          content={slide}
                          attribute="smallHeader"
                          className="font16 font-normal gray"
                        />
                      )}
                      {title && (
                        <Scrivito.ContentTag
                          tag="p"
                          content={slide}
                          attribute="title"
                          className="headline font50 font-normal blue-light"
                        />
                      )}
                      {slide.objClass() === "TextImageSlide" && (
                        <Scrivito.ContentTag
                          tag="p"
                          content={slide}
                          attribute={"teaserText"}
                          className="font16 font-normal gray"
                        />
                      )}
                      {slide.objClass() === "TextImage2dSlide" && (
                        <Scrivito.ContentTag
                          tag="p"
                          content={slide}
                          attribute={"subtitle"}
                          className="font16 font-normal gray"
                        />
                      )}
                      {link && (
                        <div>
                          <Scrivito.LinkTag
                            content={slide}
                            to={getPageLink(link)}
                            className="button button-link"
                          >
                            {link.title()}
                          </Scrivito.LinkTag>
                        </div>
                      )}
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </div>
  );
});
