import React, { useEffect, useState } from "react";
import * as Scrivito from "scrivito";
import { transformationParameters } from "./transformationParameters";

export const ImageResized = Scrivito.connect(
  ({ obj, variant, transformationObj, ...tagEditingOptions }) => {
    const [binary, setBinary] = useState(null);
    const [isImage, setIsImage] = useState(false);

    useEffect(() => {
      let isCancelled = false;
      Scrivito.load(() => ({
        obj,
        objClass: obj && obj.objClass(),
        blob: obj && obj.get("blob"),
      })).then((res) => {
        if (!isCancelled) {
          setIsImage(
            res.obj &&
              (res.objClass === "Image" || res.objClass === "ContactImage"),
          );
          setBinary(
            (res.obj && res.blob) ||
              (Scrivito.isMock && { optimizeFor: () => obj }), // don't check if obj contains blob if we use ScrivitoMock (test aim)
          );
        }
      });
      return () => {
        isCancelled = true;
      };
    }, [obj]);

    if (!obj || !binary || !isImage) {
      return null;
    }
    const transformation = transformationParameters(variant, transformationObj);
    const fittedImage = binary.optimizeFor(transformation);
    let imageClass = "";

    if (transformation.fit === "crop") {
      imageClass = "object-fit-cover";
    }
    const className = tagEditingOptions.className
      ? `${variant} ${imageClass} ${tagEditingOptions.className}`
      : `${variant} ${imageClass}`;
    return (
      <Scrivito.ImageTag
        content={fittedImage}
        {...tagEditingOptions}
        className={className}
      />
    );
  },
);
