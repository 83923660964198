import React, { useCallback, useMemo } from "react";
import * as Scrivito from "scrivito";
import { UncontrolledTooltip } from "reactstrap";
import { useFormikContext } from "formik";
import { Icon } from "components/Icon/Icon";
import { TranslateWrapper } from "components/TranslateWrapper";
import {
  groupObjectsByTitle,
  getValuesArr,
  calculateSumAndConcatenate,
} from "objs/_ConfiguratorObjs/Wizard/SubComponents/WizardQuestionnaire/questionnaireHelpers";
import { translate } from "utils";
import { Button } from "./Button";

export const QuestionWrapper = Scrivito.connect(({ children, data }) => {
  const { description, info, title, identifier, fields, iconName } = data;
  const { values, setFieldValue } = useFormikContext();

  const iconNameFormatted = useMemo(
    () => iconName?.replace(" ", "-").toLowerCase(),
    [iconName],
  );

  const { isTable, isNeedResetBtn } = useMemo(() => {
    const isIdentifierMatch = identifier === "systemlayout.fieldModules";
    return {
      isNeedResetBtn: isIdentifierMatch,
      isTable: isIdentifierMatch && fields[0]?.fieldInputType === "tableheader",
    };
  }, [identifier, fields]);

  const tooltipIconId = identifier.split(".").join("_");

  const resetData = useCallback(() => {
    data.fields.slice(1).forEach(({ values: rowValues, fieldKey }) => {
      const fieldName = `${identifier.replace(".", ",")}/#${fieldKey}/#${
        rowValues[2].fieldKey
      }/#${rowValues[2].fieldType}`;
      const defaultValue = rowValues[2].fieldDefaultValue;
      setFieldValue(fieldName, defaultValue);
    });
  }, [data, identifier, setFieldValue]);

  const totalModuleCount = useMemo(() => {
    let result = "";
    let grouped;
    if (isTable) {
      const rowCount = fields[0].values.length;
      const rows = fields.slice(1);
      grouped = groupObjectsByTitle(rows, rowCount);
      const fieldVals = getValuesArr(values);
      result = calculateSumAndConcatenate(grouped, fieldVals);
    }
    return result;
  }, [fields, values, isTable]);

  return (
    <div className="bg-white px-4 pt-3 pb-4 mb-4">
      <div className="d-flex align-items-center justify-content-between mb-2_5">
        <div className="d-flex align-items-center">
          {iconName && (
            <Icon
              className="icon-32 me-2"
              prefix="trox"
              iconName={iconNameFormatted}
            />
          )}
          <h5 className="mb-0 text-uppercase">{title}</h5>
        </div>
        {info && (
          <>
            <Icon
              className="text-gray-400 cursor-pointer"
              id={tooltipIconId}
              tabIndex={-1}
              iconName="circle-info-regular"
              prefix="trox"
            />
            <UncontrolledTooltip target={tooltipIconId} placement="left">
              {info}
            </UncontrolledTooltip>
          </>
        )}
      </div>
      {typeof info === "object" && (
        <TranslateWrapper
          tooltipText={info.props.tooltipText}
          tooltipId={tooltipIconId}
        />
      )}
      {description && (
        <div className="d-flex justify-content-between align-items-center mb-3">
          <div className="text-gray-700 font16">
            {isTable ? totalModuleCount : description}
          </div>
          {isNeedResetBtn && (
            <Button
              color="secondary"
              size="sm"
              icon="arrow-rotate-left-regular"
              iconPrefix="trox"
              iconClassName="icon-12 p-0 me-1"
              className="flex-grow-0 width-auto bg-gray-100 border border-gray-300 text-gray-700"
              onClick={resetData}
            >
              {translate("WIZARD_RESET_TO_DEFAULT")}
            </Button>
          )}
        </div>
      )}
      <div className="d-flex flex-wrap mx-2_5">{children}</div>
    </div>
  );
});
