// 1. Please use this file (generally default translations feature) to supply translations for
// components which are shared(!) between Objs, Widgets or other Components and cannot/shouldn't be
// set via ...ObjClass.js/WidgetClass.js and ...EditingConfig.js OR for multienum/enum attributes
// translations (especially, if they are shown beyond different views)

// 2. Please keep here an alphabetical order (it makes the files easier to read) :)

import { projectTranslations } from "config/locals/projectTranslations";
import { searchPageTranslations } from "config/locals/searchPageTranslations";
import { sharedGeneralTranslations } from "config/locals/sharedGeneralTranslations";
import { productTranslations } from "config/locals/productTranslations";
import { wizardsTranslations } from "config/locals/wizardsTranslations";
import { productConfigurator } from "config/locals/productConfigurator";
import { legacyAppLocals } from "config/locals/legacyAppLocals";
import { addProductToProjectModal } from "config/locals/addProductToProjectModal";

const translations = {
  ...legacyAppLocals,
  ...projectTranslations,
  ...sharedGeneralTranslations,
  ...searchPageTranslations,
  ...productTranslations,
  ...wizardsTranslations,
  ...productConfigurator,
  ...addProductToProjectModal,
};

export default translations;
