import React from "react";
import { translate } from "./localsUtils";

export const renderProjectsShowing = (
  currentPageNo,
  totalCount,
  itemsPerPage,
) => {
  const startNumber =
    currentPageNo === 1 ? 1 : (currentPageNo - 1) * itemsPerPage;
  const endNumber = Math.min(currentPageNo * itemsPerPage, totalCount);

  const output = (
    <>
      {translate("PROJECT_LIST_SHOWING")}
      {` ${startNumber} - ${endNumber} `}
      {translate("PROJECT_LIST_OF")} {` ${totalCount}`}
    </>
  );

  return totalCount ? output : null;
};
