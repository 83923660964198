import React from "react";
import * as Scrivito from "scrivito";
import { translate, getTranslationTextForEditMode } from "utils";
import { TranslateWrapper } from "components/TranslateWrapper";

export const ProductListSizeSwitcher = Scrivito.connect(() => {
  const projectProductCompactList = translate("PROJECT_PRODUCT_COMPACT_LIST");
  const projectProductExtendedList = translate("PROJECT_PRODUCT_EXTENDED_LIST");

  return (
    <div className="mx-2">
      <span id="compactList">{` ${getTranslationTextForEditMode(
        "PROJECT_PRODUCT_COMPACT_LIST",
      )}`}</span>
      <span id="extendedList" className="font-normal text-gray-400">
        {` - ${getTranslationTextForEditMode("PROJECT_PRODUCT_EXTENDED_LIST")}`}
      </span>
      {typeof projectProductCompactList === "object" && (
        <TranslateWrapper
          tooltipId="compactList"
          tooltipText={projectProductCompactList.props.tooltipText}
        />
      )}
      {typeof translate("PROJECT_PRODUCT_EXTENDED_LIST") === "object" && (
        <TranslateWrapper
          tooltipId="extendedList"
          tooltipText={projectProductExtendedList.props.tooltipText}
        />
      )}
    </div>
  );
});
