import * as React from "react";
import * as Scrivito from "scrivito";
import "./UnifiedTextImageSlideComponent.scss";
import "../HeroSliderComponent.scss";
import { isActiveObj, getPageLink } from "utils";

export const UnifiedTextImageSlide = Scrivito.connect(({ slide }) => {
  const link = slide.get("targetLink");
  const title = slide.get("title");
  const backgroundImage = slide.get("backgroundImage");
  const attributeName = {
    TextImageSlide: "teaserText",
    TextImage2dSlide: "subtitle",
    HomepagePresentationSlide: "subtitle",
    ImagesSlide: "smallHeader",
  };
  const attributeValue = attributeName[slide.objClass()];

  // render TextImageSlide and TextImage2dSlide in a similar way
  // Trox waits for general designs change, so we shouldn't recreate all possibe Slides Objs
  if (!isActiveObj(slide)) {
    return null;
  }
  return (
    <div className="hero-container">
      <div className="slide-container">
        <div className="slide-image">
          {backgroundImage && (
            <Scrivito.ImageTag content={slide} attribute="backgroundImage" />
          )}
        </div>
        <div className="slide-text">
          <div className="stage-text">
            {slide.objClass() === "TextImageSlide" && (
              <Scrivito.ContentTag
                tag="small"
                content={slide}
                attribute="smallHeader"
              />
            )}
            {title && (
              <Scrivito.ContentTag
                tag="p"
                content={slide}
                attribute="title"
                className="headline"
              />
            )}
            {attributeValue && (
              <Scrivito.ContentTag
                tag="p"
                content={slide}
                attribute={attributeValue}
                className="font16 font-normal gray"
              />
            )}
            {link && (
              <Scrivito.LinkTag
                content={slide}
                to={getPageLink(slide.get("targetLink"))}
                className="button button-link"
              >
                {link.title()}
              </Scrivito.LinkTag>
            )}
          </div>
        </div>
      </div>
    </div>
  );
});
