const WORDS_AROUND_SEARCHED_STRING_COUNT = 9;

const getSearchedResult = (searchedSubStr, searchedString) => {
  const subStr = searchedSubStr[0];
  const subStrArr = subStr.split(" ");
  const isDotsBefore =
    subStrArr.indexOf(searchedString) + 1 >= WORDS_AROUND_SEARCHED_STRING_COUNT;
  const isDotsAfter =
    subStrArr.length - subStrArr.indexOf(searchedString) + 1 >=
    WORDS_AROUND_SEARCHED_STRING_COUNT;
  return `${isDotsBefore ? "... " : ""}${subStr}${isDotsAfter ? "..." : ""}`;
};

export const getSearchedPartOfText = (text, searchedString) => {
  const regSubStr = new RegExp(
    `((\\w+ ){0,10})([^\\s]*${searchedString}[^\\s]*)(( \\w+){0,10})`,
    "giu",
  );

  const searchedSubStr = text?.match(regSubStr);

  return searchedSubStr
    ? getSearchedResult(searchedSubStr, searchedString)
    : null;
};
