import React from "react";
import * as Scrivito from "scrivito";
import { translate, getPageLink } from "utils";

export const FooterLinkedImage = Scrivito.connect(({ widget, className }) => {
  const title = widget.objClass() === "ImageFooterWidget" && (
    <Scrivito.ContentTag content={widget} attribute="title" tag="h5" />
  );

  const image = (
    <Scrivito.ImageTag
      content={widget}
      attribute="image"
      className="img-fluid mb-0"
      alt="footer_img"
      height="235"
    />
  );

  const text = widget.objClass() === "ImageFooterWidget" && (
    <Scrivito.ContentTag content={widget} attribute="text" />
  );

  const link = widget.get("link");
  let linkTitle = translate("TEXT_READ_MORE");
  if (link && link.title()) {
    linkTitle = link.title();
  }
  if (link && link.isInternal()) {
    linkTitle = link.obj().get("title");
  }

  return (
    <aside className={className}>
      {title}
      <div className="mb-3">
        {link ? (
          <Scrivito.LinkTag to={getPageLink(link)}>{image}</Scrivito.LinkTag>
        ) : (
          image
        )}
      </div>
      {text}
      {link && (
        <Scrivito.LinkTag to={getPageLink(link)} className="button button-link">
          {linkTitle}
        </Scrivito.LinkTag>
      )}
    </aside>
  );
});
