import { gql } from "@urql/core";

export const ConfigUpdateMut = gql`
  mutation (
    $id: String!
    $presentation: String
    $orderCode: OrderCodeInput
    $productName: String
    $pimId: String
    $quantity: Int
  ) {
    editProjectNodeConfiguration(
      id: $id
      orderCode: $orderCode
      presentation: $presentation
      productName: $productName
      pimId: $pimId
      quantity: $quantity
    ) {
      id
      title
      quantity
      systemPath
      __typename
      presentation
      productName
      quantity
      orderCode
      pimId
    }
  }
`;

export const ConfigCopyMut = gql`
  mutation ($id: String!, $destinationNodeId: String!) {
    copyProjectNodeConfiguration(
      id: $id
      destinationNodeId: $destinationNodeId
    ) {
      id
      quantity
      nodeId
      systemPath
    }
  }
`;

export const ConfigMoveMut = gql`
  mutation ($id: String!, $destinationNodeId: String!) {
    moveProjectNodeConfiguration(
      id: $id
      destinationNodeId: $destinationNodeId
    ) {
      id
      quantity
      nodeId
      systemPath
      referencePosition
    }
  }
`;

export const ConfigDeleteMut = gql`
  mutation ($id: String!) {
    deleteProjectNodeConfiguration(id: $id) {
      id
      nodeId
      systemPath
    }
  }
`;
