import * as React from "react";
import * as Scrivito from "scrivito";

export const VideoComponent = Scrivito.connect(({ obj, width, height }) => {
  const vimeoId = obj.get("vimeoId");
  const iframeWidth = width || 702;
  const iframeHeight = height || iframeWidth / 1.7777777778;
  if (obj && obj.objClass() === "VideoVimeo" && vimeoId) {
    const iframeSrc = `https://player.vimeo.com/video/${vimeoId}?byline=0&player_id=vimeo_${vimeoId}`;
    return (
      <iframe
        title={`vimeo-${obj.id()}`}
        frameBorder="0"
        height={iframeHeight}
        id={`vimeo_${obj.id()}`}
        src={iframeSrc}
        width={iframeWidth}
      />
    );
  }
  return null;
});
