import React from "react";
import cx from "classnames";
import { topologyItems } from "objs/_ConfiguratorObjs/Wizard/wizardHelpers";
import { IconPDF } from "./IconPDF";

import s from "./Preview.module.scss";

export const VMpdf = ({ classNames, title, iconName, kind }) => (
  <div className={cx("d-flex flex-column", classNames)}>
    <div className="bg-white d-flex align-items-center justify-content-center p-2 border border-2 border-gray-700">
      <IconPDF
        className={cx(
          "icon-32 d-flex align-items-center justify-content-center text-gray-700",
          s.svgIcon,
        )}
        name={iconName || topologyItems[kind]?.iconName}
      />
    </div>
    <div className="bg-gray-700 text-white d-flex justify-content-center position-relative text-nowrap overflow-hidden">
      {title}
    </div>
  </div>
);
