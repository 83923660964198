export const addProductToProjectModal = {
  ADD_PRODUCT_MODAL_SELECT_LABEL: "Add to an existing Project",
  ADD_PRODUCT_MODAL_OR: "or",
  ADD_PRODUCT_MODAL_CREATE_NEW_PROJECT: "Create new Project",
  ADD_PRODUCT_MODAL_PRODUCT_TITLE: "Configured Component",
  ADD_PRODUCT_MODAL_PRODUCT_SUBTITLE:
    "Select project structure level to add the product.",
  ADD_PRODUCT_MODAL_PROJECT_STRUCTURE_TITLE: "project structure",
  ADD_PRODUCT_MODAL_PROJECT_STRUCTURE_SUBTITLE:
    'To configure your Building structure please go into "Project Summary"',
};
