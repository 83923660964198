import React from "react";

export const useSwiperPagination = () => {
  const swiperRef = React.useRef();
  const [activeSlide, setActiveSlide] = React.useState(0);

  const handleSlideChange = (swiper) => {
    const { realIndex } = swiper;
    setActiveSlide(realIndex);
  };

  const handleDotClick = (index) => {
    setActiveSlide(index);
    swiperRef.current.swiper.slideTo(index);
  };
  return { swiperRef, handleSlideChange, activeSlide, handleDotClick };
};
