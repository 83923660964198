import React, { useState } from "react";
import * as Scrivito from "scrivito";
import { Collapse, Navbar, NavbarToggler, Nav, NavItem } from "reactstrap";
import { getPageLink } from "utils";
import { Spinner } from "components/Spinner";
import { SearchField } from "./SearchField";
import { SubNavigation } from "./SubNavigation";

export const MainNavigation = Scrivito.connect(
  ({ mainNavItems = [], isNavItemsFetched, isFetchNavItemsErr }) => {
    const [subNavOpen, setSubNavOpen] = useState(false);
    const [currentItemChildren, setCurrentItemChildren] = useState([]);

    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);
    if (isFetchNavItemsErr) {
      return null;
    }
    if (!isNavItemsFetched) {
      return (
        <div className="text-center">
          <Spinner />
        </div>
      );
    }

    const handleOpenSubNav = (_, item) => {
      const itemChildren = item.children || [];
      setCurrentItemChildren(itemChildren);
      itemChildren.length && setSubNavOpen(true);
    };
    const handleCloseSubNav = () => {
      setSubNavOpen(false);
    };
    return (
      <>
        <NavbarToggler
          onClick={toggle}
          className="position-absolute top-0 end-0 d-lg-none m-0 p-3 border-0 rounded-0 navbar-toggler"
        />
        <Navbar expand="lg" className="nav-main-container">
          <Collapse
            isOpen={isOpen}
            navbar
            className="nav-main-collapse flex-column align-items-start align-items-lg-center flex-lg-row justify-content-between"
          >
            <Nav navbar className="mb-4 mt-4 m-lg-0 nav-main">
              {mainNavItems.map((item, idx) => {
                const itemLink = getPageLink(Scrivito.Obj.get(item.id));
                return (
                  <NavItem
                    className="nav-main-item fw-bolder"
                    key={`nav-main-item-${idx}`}
                    onMouseEnter={(e) => handleOpenSubNav(e, item)}
                    onClick={handleCloseSubNav}
                  >
                    <Scrivito.LinkTag
                      data-testid={`MainNavigation_item-${idx}-link`}
                      to={itemLink}
                      className="nav-main-link"
                    >
                      {item.title}
                    </Scrivito.LinkTag>
                  </NavItem>
                );
              })}
            </Nav>
            <div className="d-lg-none d-flex justify-lg-content-end w-100">
              <SearchField />
            </div>
          </Collapse>
        </Navbar>
        <SubNavigation
          subNavOpen={subNavOpen}
          handleCloseSubNav={handleCloseSubNav}
          currentItemChildren={currentItemChildren}
        />
      </>
    );
  },
);
