import React, { useCallback, useMemo, useState } from "react";
import * as Scrivito from "scrivito";
import { Label, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import NiceModal from "@ebay/nice-modal-react";
import { translate } from "utils";
import cx from "classnames";
import { ProjectStructure } from "components/_Modals/ProductAddingModal/ProjectStructure";
import { useQuery } from "urql";
import { ProjectListTitlesAndIds } from "api/queries";
import { ProjectDetailsModal } from "components/_Modals/ProjectDetailsModal/ProjectDetailsModal";
import { Button } from "components/Button";
import { ProductItem } from "components/Product/ProductItem";
import { ModalWrapper } from "components/ModalWrapper";
import { REFRESH_DELAY } from "variables";
import { ProjectCreateMut } from "api/mutations";
import { ProductAddingModalContextProvider } from "providers";
import { ProjectSelect } from "./ProjectSelect";
import styles from "./ProjectAddingModal.module.scss";

export const ProductAddingModal = NiceModal.create(
  Scrivito.connect(({ productImage, requestParams }) => {
    const modal = NiceModal.useModal();
    const [quantity, setQuantity] = useState(1);
    const { projectId } = Scrivito.currentPageParams();

    const [selectedProjectId, setSelectedProjectId] = useState(projectId);
    const [addedProducts, setAddedProducts] = useState([]);

    const [{ data, fetching, stale }, exeGetProjectsOptions] = useQuery({
      query: ProjectListTitlesAndIds,
      variables: {
        limit: 1000,
        page: 1,
      },
    });

    const { projects } = data?.currentUser?.projectList || [];

    const options = useMemo(
      () =>
        projects?.map(
          (item) => item !== null && { value: item.id, label: item.title },
        ),
      [projects],
    );
    const handleOptionChange = useCallback((option) => {
      const { foldedOrdercode, projectId: currentProjId } =
        Scrivito.currentPageParams();

      setSelectedProjectId(option.value);
      const currentUrl = new URL(window.location.href);

      // Preserve existing URL parameters
      const existingParams = new URLSearchParams(window.location.search);
      existingParams.forEach((value, key) => {
        currentUrl.searchParams.set(key, value);
      });
      const isAddToAnotherProject = foldedOrdercode
        ? currentProjId
        : option.value;

      currentUrl.searchParams.set("projectId", isAddToAnotherProject);
      window.history.pushState({}, "", currentUrl);
    }, []);

    const selectedOption = useMemo(
      () => options?.find((option) => option.value === selectedProjectId),
      [options, selectedProjectId],
    );

    const toggleModal = useCallback(() => {
      NiceModal.show(ProjectDetailsModal, {
        mutation: ProjectCreateMut,
      }).then(({ id, title }) => {
        setTimeout(() => exeGetProjectsOptions(), REFRESH_DELAY);
        handleOptionChange({ value: id, label: title });
      });
    }, [exeGetProjectsOptions, handleOptionChange]);

    return (
      <ProductAddingModalContextProvider
        value={{ addedProducts, setAddedProducts }}
      >
        <ModalWrapper
          isOpen={modal.visible}
          centered={true}
          size="xl"
          toggle={modal.remove}
          onClosed={modal.remove}
          isLoading={fetching || stale}
        >
          <ModalHeader
            toggle={modal.hide}
            tag="div"
            className="pb-0 d-flex align-items-start"
          >
            <h3 className="text-uppercase mb-1">
              {translate("ADD_TO_PROJECT")}
            </h3>
            <h6 className="mb-0">{requestParams.productName}</h6>
          </ModalHeader>
          <ModalBody className="pt-3 row">
            <div className="col-lg-6 col-12 d-flex">
              <div className="border rounded-1 mr-2 px-3 py-4 w-100">
                <h5 className="mb-1">
                  {translate("ADD_PRODUCT_MODAL_PRODUCT_TITLE")}
                </h5>
                <span className="text-gray-500">
                  {translate("ADD_PRODUCT_MODAL_PRODUCT_SUBTITLE")}
                </span>
                <div className="mt-5">
                  <ProductItem
                    title={requestParams.productName}
                    quantity={quantity}
                    withIcons={false}
                    quantityTitle={translate("QUANTITY")}
                    presentationTitle={translate("PRESENTATION_ORDER_CODE")}
                    isEditMode={true}
                    handleQuantityUpdate={setQuantity}
                    presentation={requestParams.presentation}
                    productImage={productImage}
                    allowProductInputOutsidePropagation={false}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-12 d-flex mh-100">
              <div className="border rounded-1 mr-2 d-flex flex-column px-3 pt-4 w-100">
                <div className="mb-5_5">
                  <Label
                    className={cx(
                      "d-flex align-items-center justify-content-between mb-1 fs-16 fw-bolder text-gray-700 noTextSelection",
                      styles.noselect,
                    )}
                    for="productSelect"
                  >
                    {translate("ADD_PRODUCT_MODAL_SELECT_LABEL")}
                  </Label>
                  <div className="d-flex justify-content-between align-items-center flex-nowrap">
                    <div className="d-flex flex-column flex-grow-1">
                      <ProjectSelect
                        {...{ handleOptionChange, options, selectedOption }}
                      />
                    </div>
                    <div className="px-4">
                      {translate("ADD_PRODUCT_MODAL_OR")}
                    </div>
                    <Button
                      data-testid="ProductAddingModal_create-new-project-btn"
                      color="secondary"
                      onClick={toggleModal}
                    >
                      {translate("ADD_PRODUCT_MODAL_CREATE_NEW_PROJECT")}
                    </Button>
                  </div>
                </div>
                {selectedProjectId && selectedOption && (
                  <ProjectStructure
                    projectId={selectedProjectId}
                    projectTitle={selectedOption.label}
                    requestParams={{ ...requestParams, quantity }}
                    isModalView
                  />
                )}
              </div>
            </div>
          </ModalBody>
          <ModalFooter className="pt-0" />
        </ModalWrapper>
      </ProductAddingModalContextProvider>
    );
  }),
);
