import * as React from "react";
import * as Scrivito from "scrivito";
import moment from "moment";
import { translate } from "utils";

export const DatePeriod = Scrivito.connect(({ page }) => {
  const start = page.get("startDate")
    ? moment(page.get("startDate")).format("DD.MM.YYYY")
    : "";
  const end = page.get("endDate")
    ? moment(page.get("endDate")).format("DD.MM.YYYY")
    : "";
  if (start && end) {
    return (
      <div className="eventdate">
        <span>{translate("DATE_PERIOD_EVENT_RANGE")}</span>
        {/* poper formatting is needed here */}
        <p>
          {start} - {end}
        </p>
      </div>
    );
  }
  if (start) {
    return (
      <div className="eventdate">
        <span>{translate("DATE_PERIOD_EVENT_START")}</span>
        {/* poper formatting is needed here */}
        <p>{start}</p>
      </div>
    );
  }
  return null;
});
