import React from "react";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { PreviewHeader } from "./PreviewHeader";
import { SCpdf } from "./SCpdf";

export const PreviewTopology = ({
  wizardTitle,
  structuredTopology,
  innerRef,
}) => (
  <TransformWrapper minScale={0.1} maxScale={3} wheel={{ step: 0.04 }}>
    <TransformComponent wrapperClass="mh-100 mw-100 w-100 p-3 overflow-hidden position-absolute">
      <PreviewHeader wizardTitle={wizardTitle} />
      <div ref={innerRef}>
        <SCpdf {...structuredTopology} />
      </div>
    </TransformComponent>
  </TransformWrapper>
);
