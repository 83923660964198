import React from "react";
import cx from "classnames";
import { translate } from "utils";
import { IconPDF } from "./IconPDF";
import s from "./Preview.module.scss";

export const PreviewHeader = ({ wizardTitle }) => (
  <div className="d-flex flex-column w-100" id="pdfHeader">
    <div className={cx("d-flex align-items-end", s.previewHeader)}>
      <IconPDF className={cx("me-2", s.svgLogoWrapper)} name="TROX-logo" />
      <span
        className={cx(
          "mb-0 fs-18 fw-bold text-uppercase text-gray-500",
          s.configuratorText,
        )}
      >
        {translate("CONFIGURATOR")}
      </span>
    </div>
    <div className="d-flex align-items-center w-100 mb-5 py-2_5 border-bottom border-gray-500">
      <IconPDF
        className={cx("icon-16 text-gray-500 me-2", s.svgIcon)}
        name="folder-regular"
      />
      <div className="fs-md text-gray-500">{wizardTitle}</div>
    </div>
  </div>
);
