import cx from "classnames";
import React from "react";
import { Button as ReactstrapButton } from "reactstrap";
import { Icon } from "./Icon/Icon";
import { Spinner } from "./Spinner";

export const Button = ({
  size = "md",
  color = "primary",
  icon,
  disabled = false,
  classNames = "",
  isLoading = false,
  square = false,
  children,
  iconClassName,
  iconPrefix,
  ...rest
}) => (
  <div className="position-relative m-0 p-0">
    <ReactstrapButton
      size={size}
      disabled={disabled || isLoading}
      color={color}
      className={cx(
        "d-flex align-items-center justify-content-center user-select-none",
        classNames,
        {
          "btn-square": square,
          "btn-close": color === "close",
          "w-100": size === "lg",
        },
      )}
      {...rest}
    >
      {isLoading && (
        <div
          className="position-absolute pt-1"
          style={{
            top: "50%",
            transform: "translate(50%, -50%)",
            right: "50%",
          }}
        >
          <Spinner
            className="loader"
            size="xs"
            color={color === "primary" ? "white" : "primary"}
          />
        </div>
      )}
      {icon && (
        <Icon
          iconName={icon}
          prefix={iconPrefix}
          className={iconClassName || "me-2"}
        />
      )}
      {children}
    </ReactstrapButton>
  </div>
);
