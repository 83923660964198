import React, { useCallback, useState } from "react";
import { Input, Label } from "reactstrap";
import cx from "classnames";
import { Icon } from "../Icon/Icon";
import styles from "./Inputs.module.scss";

export const PasswordInput = ({
  value,
  label,
  error,
  onChange,
  name,
  id,
  testId,
  classNames = "mb-3",
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const toggleIsFocused = useCallback(() => {
    setIsFocused((val) => !val);
  }, []);

  const togglePasswordShow = useCallback(() => {
    setShowPassword((prev) => !prev);
  }, []);

  return (
    <div className={cx(styles.wrapper, classNames)}>
      {(value || isFocused) && (
        <Label for={name} className={styles.label}>
          {label}
        </Label>
      )}
      <Input
        type={showPassword ? "text" : "password"}
        required
        placeholder={!isFocused ? label : ""}
        className={cx(styles.textInput, { "border-danger": error })}
        name={name}
        id={id}
        data-testid={testId}
        onChange={onChange}
        value={value}
        onFocus={toggleIsFocused}
        onBlur={toggleIsFocused}
      />
      {value && (
        <Icon
          className={cx(styles.icon, "cursor-pointer icon-16")}
          iconName={showPassword ? "eye-slash-regular" : "eye-regular"}
          prefix="trox"
          data-testid={`eye-icon-${testId}`}
          onClick={togglePasswordShow}
        />
      )}
    </div>
  );
};
