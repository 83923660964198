import React, { useCallback, useEffect, useRef, useState } from "react";
import * as Scrivito from "scrivito";
import cx from "classnames";
import { useClickOutside } from "hooks";

import { Button } from "../Button";
import { Icon } from "../Icon/Icon";
import s from "./Dropdown.module.scss";

export const Dropdown = Scrivito.connect(
  ({
    btnChildren,
    btnClassName,
    btnId,
    options,
    onClick,
    openToTopForce = false,
    disabled,
    testId,
  }) => {
    const btnChildrenFinalValue = btnChildren ?? (
      <Icon iconName="ellipsis-vertical-regular" prefix="trox" />
    );
    const dropdownRef = useRef();
    const dropdownMenuRef = useRef();

    const [isMenuOpened, setIsMenuOpened] = useState(false);
    const [openToTop, setOpenToTop] = useState(openToTopForce);

    const toggleDropdown = useCallback(
      (...args) => {
        setIsMenuOpened((prev) => !prev);
        onClick?.(...args);
      },
      [onClick],
    );

    const handleClickOutsideDropdown = useCallback(() => {
      setIsMenuOpened((prev) => prev && !prev);
    }, []);

    useClickOutside(dropdownRef, handleClickOutsideDropdown);

    useEffect(() => {
      const handleDirectionToOpen = () => {
        if (openToTopForce) {
          return;
        }
        const buttonBounding =
          dropdownRef.current?.getBoundingClientRect() || {};
        const menuBounding =
          dropdownMenuRef.current?.getBoundingClientRect() || {};

        const { bottom: buttonBottom } = buttonBounding;
        const { height: menuHeight } = menuBounding;
        const { innerHeight } = window;

        if (buttonBottom + menuHeight > innerHeight) {
          setOpenToTop(true);
        } else {
          setOpenToTop(false);
        }
      };

      handleDirectionToOpen();

      window.addEventListener("scroll", () => {
        if (!isMenuOpened) {
          return;
        }
        handleDirectionToOpen();
      });
    }, [openToTopForce, isMenuOpened]);

    return (
      <div className="position-relative" ref={dropdownRef}>
        <Button
          classNames={cx("h-100 px-2 py-0 border-0", btnClassName)}
          id={btnId}
          onClick={toggleDropdown}
          color=""
          disabled={disabled}
          data-testid={testId}
        >
          {btnChildrenFinalValue}
        </Button>
        {isMenuOpened && (
          <div
            className={cx(s.dropdownMenu, { [s.dropdownMenuToTop]: openToTop })}
            ref={dropdownMenuRef}
          >
            {options?.map((button, index) => (
              <div
                key={index}
                className={cx("p-0_5", {
                  "border-1 border-bottom border-gray-300":
                    index < options.length - 1,
                })}
              >
                <Button
                  className={cx(s.dropdownMenuItem, {
                    "bg-blue-300 text-white": button.isActive,
                  })}
                  color="option"
                  data-testid={button.testId}
                  onClick={button.onClick}
                >
                  {button.title}
                </Button>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  },
);
