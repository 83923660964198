export const handleTableDownload = (
  tableData,
  { filename = "table", extention = "csv" },
  isTableDomDataDownload = false,
) => {
  const handleAsync = async () => {
    if (extention === "csv") {
      const fixedTableData = tableData.map((row) =>
        row.map((col) =>
          !col?.match(/\n/gm) ? col : `${col.split(/\n/gm).join(" / ")}`,
        ),
      );
      const fileContent = `data:text/csv;charset=utf-8,\uFEFF${fixedTableData
        .map((e) =>
          // Set comma as delimiter for forward and backward compatibility in excel
          isTableDomDataDownload
            ? e.map((value) => `"${value}"`).join(",")
            : e.join(";"),
        )
        .join("\n")}`;

      const encodedUri = encodeURI(fileContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", `${filename}.csv`);
      document.body.appendChild(link);

      link.click();
      document.body.removeChild(link);
    }
  };

  handleAsync();
};
