import React from "react";
import { Input } from "reactstrap";
import cx from "classnames";

import { Icon } from "../../Icon/Icon";
import styles from "./Checkbox.module.scss";

export const Checkbox = ({
  checked,
  onChange,
  wrapperClassName,
  ...restProps
}) => (
  <div
    className={cx("position-relative d-inline-block icon-18", wrapperClassName)}
    onClick={onChange}
  >
    <Input
      className="mt-0"
      type="checkbox"
      checked={checked}
      onChange={onChange}
      {...restProps}
    />
    <div
      className={cx(
        styles.checkbox,
        "position-absolute top-0 start-0 end-0 bottom-0",
        "d-flex justify-content-center align-items-center",
        {
          "bg-blue-300 border-info": checked,
        },
      )}
    >
      {checked && (
        <Icon
          prefix="trox"
          iconName="check-regular"
          style={{ transform: "rotate(0)" }}
          className="icon-14 text-white"
        />
      )}
    </div>
  </div>
);
