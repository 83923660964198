import * as React from "react";
import * as Scrivito from "scrivito";

import cx from "classnames";

export const BackgroundWrapper = Scrivito.connect(
  ({ page, classNames, children }) => {
    const backgroundImage = page.get("backgroundImage");
    return backgroundImage ? (
      <Scrivito.BackgroundImageTag
        className="background-image bg-gray-700"
        style={{
          background: {
            image: backgroundImage,
            position: "right",
            size: "contain",
          },
        }}
      >
        <div className={cx("container", classNames)}>{children}</div>
      </Scrivito.BackgroundImageTag>
    ) : (
      <div className={cx("container", classNames)}>{children}</div>
    );
  },
);
