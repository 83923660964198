import { useEffect } from "react";

export const useOpenAndScrollLeftSidebarTopologyItem = ({
  leftSidebarTopologyItemId,
  leftSidebarTopologyCollapsesMap,
  selectedTopologyItemId,
  setLeftSidebarTopologyItemId,
  topologyMenuWrapperRef,
}) => {
  useEffect(() => {
    if (!leftSidebarTopologyItemId) {
      return;
    }

    const openParentCollapses = leftSidebarTopologyCollapsesMap.current.get(
      selectedTopologyItemId,
    );

    openParentCollapses && openParentCollapses(true);
    // After a delay to allow for the parent to open, scroll the child into view.
    setTimeout(() => {
      const leftSidebarTopologyItem = document.getElementById(
        leftSidebarTopologyItemId,
      );
      if (leftSidebarTopologyItem) {
        leftSidebarTopologyItem.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        setTimeout(() => setLeftSidebarTopologyItemId(""), 1000);
      }
    }, 500);
  }, [
    leftSidebarTopologyCollapsesMap,
    leftSidebarTopologyItemId,
    selectedTopologyItemId,
    setLeftSidebarTopologyItemId,
    topologyMenuWrapperRef,
  ]);
};
