import React from "react";
import * as Scrivito from "scrivito";

export const Marker = Scrivito.connect(({ color = "blue-300", children }) => (
  <>
    <div
      style={{
        width: "4px",
        height: "100%",
        borderRadius: "2px",
        marginLeft: "2px",
      }}
      className={`d-inline-block h-100 bg-${color}`}
    ></div>
    {children}
  </>
));
