import React, { useMemo } from "react";
import cx from "classnames";
import { TopologyItemPDF } from "./TopologyItemPDF";
import { CMpdf } from "./CMpdf";

import s from "./Preview.module.scss";

export const FAMpdf = ({
  kind,
  chainedChildren,
  isLastFAM,
  rightPlacedChildren,
  bottomPlacedChildren,
  info,
  ...restProps
}) => {
  const directBottomPlacedChildrenCount = useMemo(() => {
    if (chainedChildren && bottomPlacedChildren) {
      return bottomPlacedChildren.length + 1;
    }
    if (bottomPlacedChildren) {
      return bottomPlacedChildren.length;
    }
    if (chainedChildren) {
      return 1;
    }
    return null;
  }, [bottomPlacedChildren, chainedChildren]);

  const rightPlacedChildrenWithOffset = useMemo(() => {
    let previouslyAddedElementsCount = 0;
    return rightPlacedChildren?.map((item) => {
      if (item.directlyConnected) {
        previouslyAddedElementsCount += item.directlyConnected.length;
      }
      return { ...item, previouslyAddedElementsCount };
    });
  }, [rightPlacedChildren]);

  return (
    <div className={cx("d-flex")}>
      <div
        className={cx(
          "d-flex flex-column position-relative flex-shrink-0 pe-4",
          {
            [s.famWithNeighbor]: !isLastFAM,
          },
        )}
      >
        <div className="d-flex align-items-start position-relative">
          <TopologyItemPDF
            kind={kind}
            classNames={!rightPlacedChildren && "me-4"}
            {...restProps}
          />
          <div className="position-relative">
            {rightPlacedChildren && (
              <div
                className={cx("d-flex flex-column ms-6", {
                  [s.lessThanFourRightChildren]:
                    rightPlacedChildren.length <= 4,
                  [s.moreThanFourRightChildren]: rightPlacedChildren.length > 4,
                })}
              >
                {rightPlacedChildrenWithOffset.map((item) => (
                  <TopologyItemPDF key={item.id} {...item} />
                ))}
              </div>
            )}
            <div />
          </div>
          {/* We need that to provide vertical lines from FAM to bottomPlacedChildren,
          we need one line for each of bottomPlacedChildren
          and additional one for the first element of chainedChildren */}
          {directBottomPlacedChildrenCount && (
            <div className={s.linesFromNodeToBottomPlacedChildren}>
              {Array.from(Array(directBottomPlacedChildrenCount)).map(
                (_, key) => (
                  <div key={key} style={{ "--left": `${28 - key * 4}px` }} />
                ),
              )}
              {info && (
                <div
                  className="pb-2 border-0 h6 text-gray-700 text-nowrap"
                  style={{
                    "--left": `${28 - directBottomPlacedChildrenCount * 4}px`,
                  }}
                >
                  {info}
                </div>
              )}
            </div>
          )}
        </div>

        {bottomPlacedChildren && (
          <div className="position-relative">
            {bottomPlacedChildren.map((item, key) => (
              <div className="ps-7" key={item.id}>
                <CMpdf
                  {...item}
                  classNames={s[`node-${key}`]}
                  withConnectionToBottomPlacedItem={false}
                  wrapperClassNames={
                    s[
                      `wrapper-${directBottomPlacedChildrenCount}-${
                        directBottomPlacedChildrenCount - key
                      }`
                    ]
                  }
                />
              </div>
            ))}
          </div>
        )}
        {chainedChildren && (
          <div
            className={cx(
              "px-6 position-relative",
              s[`modulesWrapper-${directBottomPlacedChildrenCount}`],
            )}
          >
            {chainedChildren.map((item, key) => (
              <CMpdf
                key={item.id}
                withConnectionToBottomPlacedItem={
                  key !== chainedChildren.length - 1
                }
                {...item}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
