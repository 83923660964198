import React, { useCallback, useMemo, useRef, useState } from "react";
import { Collapse } from "reactstrap";

import { RootFolder } from "components/ProjectTree/Folder/RootFolder";
import { CustomDragLayer } from "components/CustomDragLayer/CustomDragLayer";
import TreeNode from "components/ProjectTree/TreeNode";

import styles from "components/ProjectTree/ProjectTree.module.scss";
import { useProjectTreeContext } from "providers";
import { Folder } from "components/ProjectTree/Folder/Folder";

export const ProjectTreeContent = React.memo(({ project }) => {
  const [isOpen, setIsOpen] = useState(true);
  const { withDnd, isWizardObjsOmit } = useProjectTreeContext();
  const { projectTree, title } = project;

  const ulRef = useRef(null);

  const projectTreeToDisplay = useMemo(
    () =>
      isWizardObjsOmit
        ? projectTree?.filter(
            (item) =>
              !["wizardFireprotection", "wizardRoomcontrol"].includes(
                item.kind,
              ),
          )
        : projectTree,
    [isWizardObjsOmit, projectTree],
  );

  const handleToggle = useCallback(() => setIsOpen((val) => !val), []);

  return (
    <>
      {/* Main Folder with project name title - not a part of project tree */}
      <RootFolder
        title={title}
        hasChildren={!!projectTreeToDisplay}
        handleToggle={handleToggle}
        isOpen={isOpen}
        nodes={projectTreeToDisplay}
        ulRef={ulRef}
      />
      {/* TreeNodes/Folders made from project tree */}
      {projectTreeToDisplay && (
        <Collapse isOpen={isOpen}>
          <div className={styles.nodeTreeWrapper}>
            <ul ref={ulRef} className="mt-2">
              {withDnd && (
                <CustomDragLayer
                  render={(props) => (
                    <Folder
                      hasChildren={
                        props.nodes?.length || props.configurations || null
                      }
                      {...props}
                    />
                  )}
                />
              )}
              {projectTreeToDisplay.map((node) => (
                <TreeNode key={node.id} {...node} projectName={title} />
              ))}
            </ul>
          </div>
        </Collapse>
      )}
    </>
  );
});
