import React, { useMemo } from "react";
import cx from "classnames";
import { convertFAMs } from "objs/_ConfiguratorObjs/Wizard/wizardHelpers";
import { FAMpdf } from "./FAMpdf";
import { VMpdf } from "./VMpdf";

import s from "./Preview.module.scss";

export const SWpdf = ({
  kind,
  connectedByTopBracketChildren,
  sameLayer,
  ...restProps
}) => {
  const splitByGroupsFAMs = useMemo(
    () =>
      connectedByTopBracketChildren &&
      convertFAMs(connectedByTopBracketChildren),
    [connectedByTopBracketChildren],
  );

  return (
    <div className="d-flex flex-column">
      <div className="d-flex">
        <VMpdf kind={kind} {...restProps} />
        {sameLayer?.map((item) => (
          <VMpdf
            key={item.id}
            classNames={"position-relative ms-10 ps-10"}
            {...item}
          />
        ))}
        <div
          className={cx(
            "d-flex align-self-end bg-gray-300 ps-2_5 w-100 position-absolute",
            s.switchBus,
          )}
        >
          {restProps.info}
        </div>
      </div>
      <div className={cx("pt-7_5 position-relative d-flex")}>
        {splitByGroupsFAMs?.map((item, key) => (
          <div className="position-relative" key={key}>
            <div className={cx("d-flex", s.switchLayer)}>
              {item.map((fam) => (
                <FAMpdf {...fam} key={fam.id} famGroupLength={item.length} />
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
