import * as React from "react";
import { Helmet } from "react-helmet-async";
import { Icon } from "../Icon/Icon";

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    this.state = { hasError: false };
  }

  componentDidCatch(_error, _info) {
    this.setState({ hasError: true });
    // often it could be very helpful in debugging
    if (process.env.TROX_ENVIRONMENT === "development") {
      console.error(_error);
    }
  }

  render() {
    if (!this.state.hasError) {
      return this.props.children;
    }

    const backgroundImage = [
      "linear-gradient(rgba(46, 53, 60, 0.7), rgba(46, 53, 60, 0.7))",
      "url(https://long-lasting-assets.scrivitojs.com/scrivito_example_app_js/500_error.jpeg)",
    ].join(", ");

    return (
      <React.Fragment>
        <section
          className="bg-dark-image full-height"
          style={{ background: "no-repeat center / cover", backgroundImage }}
        >
          <div className="container">
            <div className="text-center">
              <h1 className="hero-bold">Sorry</h1>
            </div>
            <div className="text-center">
              <h2 className="hero-small">Something went wrong.</h2>
            </div>
            <div className="text-center">
              <button
                data-testid="error-boundary_try-again-btn"
                type="button"
                className="btn btn-primary"
                onClick={() => window.location.reload(true)}
              >
                Please try again
                <Icon prefix="trox" iconName="chevron-right-regular" />
              </button>
            </div>
          </div>
        </section>
        <Helmet meta={[{ name: "prerender-status-code", content: "500" }]} />
      </React.Fragment>
    );
  }
}
