import * as Scrivito from "scrivito";
import { getBasePath, getCategoryLanguage } from "./filterByTenant";

const getHomepageUsingTenantsJSON = () => {
  // if Obj has no path, then look for its Homepage basing on tenants.json and URL address
  const basePath = getBasePath();
  const defaultLocale = getCategoryLanguage();
  const homepagePath = `${basePath}/${defaultLocale}`;
  return Scrivito.Obj.getByPath(`${homepagePath}`);
};

export const getHomepage = () => {
  const currentPage = Scrivito.currentPage();
  if (!currentPage) {
    return;
  }
  if (currentPage.objClass() === "Homepage") {
    return Scrivito.Obj.getByPath(currentPage.path());
  }
  if (
    !currentPage.path() ||
    currentPage.objClass() === "Root" ||
    currentPage.objClass() === "Website"
  ) {
    return getHomepageUsingTenantsJSON();
  }
  const ancestors = currentPage.ancestors();
  const homepage = ancestors
    .map((anc) => ({
      path: anc && anc.path(),
      objClass: anc && anc.objClass(),
    }))
    .filter(
      (page) =>
        page?.objClass === "Homepage" || page?.objClass === "HomepageSame",
    )[0];
  // if page has no homepage Obj in its ancestors use one from tenantsJSON, else use homepage path
  return !homepage
    ? getHomepageUsingTenantsJSON()
    : Scrivito.Obj.getByPath(homepage.path);
};
