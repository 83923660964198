import React, { useCallback, useState } from "react";
import { Input, Label } from "reactstrap";
import cx from "classnames";

import styles from "./Inputs.module.scss";

export const UserNameInput = ({
  type,
  value,
  label,
  error,
  onChange,
  name,
  id,
  testId,
  classNames = "mb-3",
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const toggleIsFocused = useCallback(() => {
    setIsFocused((val) => !val);
  }, []);

  return (
    <div className={cx(styles.wrapper, classNames)}>
      {(value || isFocused) && (
        <Label for={name} className={styles.label}>
          {label}
        </Label>
      )}
      <Input
        type={type}
        required
        placeholder={!isFocused ? label : ""}
        className={cx(styles.textInput, { "border-danger": error })}
        name={name}
        id={id}
        data-testid={testId}
        onChange={onChange}
        value={value}
        onFocus={toggleIsFocused}
        onBlur={toggleIsFocused}
      />
    </div>
  );
};
