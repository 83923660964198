import * as Scrivito from "scrivito";
import { isBrowserEnv } from "utils";

export function configureScrivito(options) {
  let path = "/website/de";
  if (isBrowserEnv()) {
    path = window?.location?.hostname.startsWith("sales")
      ? "same/en"
      : "/website/de";
  }

  const config = {
    adoptUi: true,
    strictSearchOperators: true,
    tenant: process.env.SCRIVITO_TENANT,
    homepage: () => Scrivito.Obj.getByPath(path).get("welcomePage"),
  };

  if (process.env.SCRIVITO_ORIGIN) {
    config.origin = process.env.SCRIVITO_ORIGIN;
  }

  if (process.env.SCRIVITO_ENDPOINT) {
    config.endpoint = process.env.SCRIVITO_ENDPOINT;
  }

  if (options?.priority) {
    config.priority = options.priority;
  }

  Scrivito.configure(config);
}
