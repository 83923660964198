import React, { useCallback, useMemo } from "react";
import * as Scrivito from "scrivito";
import cx from "classnames";
import { Offcanvas, OffcanvasBody, OffcanvasHeader } from "reactstrap";
import { getHomepage, getPageLink, translate } from "utils";
import { authIndicatorFlag } from "api/auth";
import { useAppBaseContext } from "providers";
import { LogoutButton } from "../LogoutButton";

const supportedLanguages = ["de", "en", "es", "nl"];

export const NavRightSidebar = Scrivito.connect(
  ({ onToggleSideBar, isOpen }) => {
    const { isSameProj } = useAppBaseContext();
    const searchOptions = useMemo(
      () => ({
        homepageObjName: isSameProj ? "HomepageSame" : "Homepage",
        categoryOwner: isSameProj ? "SAME" : "DE",
      }),
      [isSameProj],
    );

    const homepages = Scrivito.Obj.where(
      "_objClass",
      "equals",
      searchOptions.homepageObjName,
    )
      .and("categoryOwner", "equals", searchOptions.categoryOwner)
      .and("categoryLanguages", "equals", supportedLanguages)
      .toArray()
      .map((home) => ({
        categoryLanguage: home.get("categoryLanguages")[0],
        welcomePage: home.get("welcomePage") || home,
        welcomePageId: home.get("welcomePage")?.id() || "",
        obj: home,
      }));
    const homepage = getHomepage();
    const homepageLang = homepage.get("categoryLanguages")[0] || "de";

    const handleLinkClicked = useCallback(
      (e, externalLink, objId, objClass) => {
        if (objClass === "Homepage" && objId) {
          e.preventDefault();
          const newWindow = window.open(objId, "_blank");
          if (newWindow) {
            newWindow.focus();
          }
        }
        if (objClass === "Redirect" && externalLink) {
          e.preventDefault();
          const newWindow = window.open(externalLink, "_blank");
          if (newWindow) {
            newWindow.focus();
          }
        }
        onToggleSideBar();
      },
      [onToggleSideBar],
    );

    if (!homepage || !homepages.length) {
      return null;
    }

    const configuratorNavLinks =
      homepage.get("configuratorNavigationServiceLinks") || [];

    const isLoggedIn = localStorage.getItem(authIndicatorFlag);

    return (
      <Offcanvas
        direction="end"
        toggle={onToggleSideBar}
        isOpen={isOpen}
        data-testid="right-sidebar"
      >
        <OffcanvasHeader toggle={onToggleSideBar} tag="div">
          <div className="d-flex">
            {homepages.map((home) => (
              <Scrivito.LinkTag
                data-testid={`NavRightSidebar_lang-${home.categoryLanguage}-link`}
                className={cx(
                  "me-3 p-0 border-0 bg-transparent text-decoration-none text-uppercase text-gray-700 bold-hover font-md",
                  {
                    "fw-bold":
                      homepageLang.toLowerCase() === home.categoryLanguage,
                  },
                )}
                key={home.categoryLanguage}
                to={home.welcomePage} // just cannot stay empty, but instead of "to" attr "onClick" is used
                onClick={(e) => {
                  e.preventDefault();
                  window.location.href = `${window.location.origin}/${home.welcomePageId}`;
                }}
              >
                {home.categoryLanguage}
              </Scrivito.LinkTag>
            ))}
          </div>
        </OffcanvasHeader>
        <OffcanvasBody className="pt-6 d-flex flex-column justify-content-between">
          <div>
            <div className="d-flex flex-column mb-7">
              <h3 className="mb-1 text-uppercase">
                {homepage.get("configuratorNavMenu")}
              </h3>
              <h4 className="mb-0">{homepage.get("configuratorNavTitle")}</h4>
            </div>
            <nav className={cx("w-100")}>
              {configuratorNavLinks.map((obj, key) => {
                // TODO: dirty fix for ISH, pls provide a clean way (open links to
                // Homepage/Redirections in the new tab in cleaner way)
                const targetLink = getPageLink(obj);
                const objClass = obj.objClass();
                const objId = obj.id();
                const externalLink = obj.get("link")?.url();
                return (
                  <div key={key} className={cx("w-100 border-bottom")}>
                    <Scrivito.LinkTag
                      data-testid={`NavRightSidebar_nav-${
                        obj.get("navigationTitle") || obj.get("title")
                      }-link`}
                      to={targetLink}
                      onClick={(e) =>
                        handleLinkClicked(e, externalLink, objId, objClass)
                      }
                      className={cx(
                        "text-decoration-none nav-link py-4 px-0 m-0",
                        {
                          "pe-none": !isLoggedIn,
                        },
                      )}
                    >
                      <div
                        className={cx(
                          "h5 mb-0 text-uppercase main-menu-link-hover",
                          {
                            "text-gray-400": !isLoggedIn,
                          },
                        )}
                      >
                        {
                          objClass === "Homepage" && translate("TROX_HOMEPAGE") // TODO: when we could publish a change for homepage title, we could get
                          // rid of this condition (now we cannot do that - it would affect Ruby-app
                          // data)
                        }
                        {objClass === "ProjectList" && translate("MY_PROJECTS")}
                        {objClass === "ContentPage" && translate("WELCOME")}
                        {objClass === "Redirect" && translate("MY_CONTACTS")}
                      </div>
                    </Scrivito.LinkTag>
                  </div>
                );
              })}
            </nav>
          </div>
          <LogoutButton onToggleShow={onToggleSideBar} />
        </OffcanvasBody>
      </Offcanvas>
    );
  },
);
