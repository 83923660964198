import { getTenantSettings } from "./getTenantSettings";

export const getCategoryClient = (tenantsJSON) => {
  const clientName = getTenantSettings(tenantsJSON).client_name;
  if (!clientName) {
    return console.error(
      "No client_name defined for current tenant (from tenants.json)",
    );
  }
  return getTenantSettings(tenantsJSON).client_name;
};

export const getCategoryLanguage = (tenantsJSON) => {
  const categoryLanguage = getTenantSettings(tenantsJSON).default_locale;
  if (!categoryLanguage) {
    return console.error(
      "No default_locale defined for current tenant (from tenants.json)",
    );
  }
  return categoryLanguage.split("-")[0];
};

export const getDefaultLocale = (tenantsJSON) => {
  const defaultLocale = getTenantSettings(tenantsJSON).default_locale;
  if (!defaultLocale) {
    return console.error(
      "No default_locale defined for current tenant (from tenants.json)",
    );
  }
  return defaultLocale;
};

export const getAvailableLanguages = (tenantsJSON) => {
  const allowedLocales = (getTenantSettings(tenantsJSON).allowed_locales || [])
    .sort()
    .map((locale) => locale.split("-")[0]);
  if (allowedLocales.length === 0) {
    return console.error(
      "No allowed_locales defined for current tenant (from tenants.json)",
    );
  }
  return allowedLocales;
};

export const getBasePath = (tenantsJSON) => {
  const basePath = getTenantSettings(tenantsJSON).base_path;
  if (!basePath) {
    return console.error(
      "No base_path defined for current tenant (from tenants.json)",
    );
  }
  return basePath;
};

export const getRubyTenantVersion = (tenantsJSON) => {
  const rubyTenantVersion = getTenantSettings(tenantsJSON).ruby_tenant_version;
  if (!rubyTenantVersion) {
    return console.error(
      "No ruby_tenant_version defined for current tenant (from tenants.json)",
    );
  }
  return rubyTenantVersion;
};
