import * as Scrivito from "scrivito";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useQuery } from "urql";
import { ProjectTreeContent } from "components/ProjectTree/ProjectTreeContent";
import { ProjectTreeQry } from "api/queries";
import { Clipboard } from "components/ProjectTree/Clipboard";
import { ProjectTreeContextProvider } from "providers";
import { projectTreeContentTranlations } from "variables/projectTreeContentTranlations";
import { ProjectStructureButtons } from "objs/_ConfiguratorObjs/ProjectConfigurator/ProjectStructureButtons";

export const ProjectTree = Scrivito.connect(
  ({
    projectId,
    withDnd = false,
    pasteModeOnly = false,
    pasteItem = null,
    isWizardObjsOmit = false,
    withClipboard = false,
    withFiltering = false,
    isSummary = false,
    isModalView = false,
  }) => {
    const [copiedItem, setCopiedItem] = useState(pasteItem);
    const [showProductPictures, setShowProductPictures] = useState(true);

    const [{ data }, exeGetProjectTree] = useQuery({
      query: ProjectTreeQry,
      variables: { id: projectId },
    });

    useEffect(() => {
      setCopiedItem(pasteItem);
    }, [pasteItem]);

    const handleStartCopy = useCallback((itemData) => {
      setCopiedItem(itemData);
    }, []);

    const handleCopyCancel = useCallback(() => {
      setCopiedItem(null);
    }, []);

    const translations = projectTreeContentTranlations();

    const context = useMemo(
      () => ({
        copiedItem,
        exeGetProjectTree,
        handleStartCopy,
        isWizardObjsOmit,
        pasteModeOnly,
        projectId,
        translations,
        withDnd,
        isSummary,
        isModalView,
        showProductPictures,
        setShowProductPictures,
      }),
      [
        copiedItem,
        exeGetProjectTree,
        handleStartCopy,
        isWizardObjsOmit,
        pasteModeOnly,
        projectId,
        translations,
        withDnd,
        isSummary,
        isModalView,
        showProductPictures,
        setShowProductPictures,
      ],
    );

    if (!projectId) {
      return null;
    }

    return (
      <ProjectTreeContextProvider value={context}>
        {data && (
          <>
            {withClipboard && copiedItem && !pasteModeOnly && (
              <Clipboard
                {...copiedItem}
                handleCopyCancel={handleCopyCancel}
                projectName={data.project?.title}
              />
            )}
            <ProjectStructureButtons
              withFiltering={withFiltering}
              showProductPictures={showProductPictures}
              setShowProductPictures={setShowProductPictures}
              isSummary={isSummary}
            />
            <DndProvider backend={HTML5Backend}>
              <ProjectTreeContent project={data?.project} />
            </DndProvider>
          </>
        )}
      </ProjectTreeContextProvider>
    );
  },
);
