import * as React from "react";
import * as Scrivito from "scrivito";
import { getPageLink } from "utils";
import { ImageResized } from "components/ImageResized/ImageResized";

export const SubNavigation = Scrivito.connect(
  ({ subNavOpen, handleCloseSubNav, currentItemChildren = [] }) => (
    <div
      className={`nav-sub-container ${subNavOpen && "open"}`}
      onMouseLeave={handleCloseSubNav}
    >
      {!!currentItemChildren.length && (
        <ul>
          {currentItemChildren.map((child) => (
            <li key={child.id}>
              <Scrivito.LinkTag
                to={getPageLink(Scrivito.Obj.get(child.id))}
                onClick={handleCloseSubNav}
              >
                <div>
                  {child.img && (
                    <ImageResized
                      obj={Scrivito.Obj.get(child.img)}
                      variant="subNavigationChild"
                      className="img-fluid sub-nav-child-img"
                    />
                  )}
                </div>
                <span>{child.title}</span>
              </Scrivito.LinkTag>
            </li>
          ))}
        </ul>
      )}
    </div>
  ),
);
