import React, { useCallback } from "react";
import * as Scrivito from "scrivito";
import NiceModal from "@ebay/nice-modal-react";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { translate } from "utils";
import { useWizardData } from "hooks";
import cx from "classnames";
import { Button } from "../Button";
import { ProductListWithControls } from "../ProductListOfProject/ProductListWithControls";
import { ModalWrapper } from "../ModalWrapper";

export const PriceCalculationModal = NiceModal.create(
  Scrivito.connect(({ configurationOption, projectId, nodeId, subType }) => {
    const modal = NiceModal.useModal();
    const { wizardItemTitle } = useWizardData(subType);

    const handleHide = useCallback(() => {
      modal.hide(); // do not resolve or remove the modal, it needs to be hidden only to process
      // price calculation, even when user closes it
    }, [modal]);

    return (
      <ModalWrapper
        isOpen={modal.visible}
        centered={true}
        size="xl"
        toggle={modal.hide}
        className="my-0"
        isLoading={!configurationOption}
      >
        <div className="d-flex flex-column position-relative">
          <ModalHeader
            toggle={modal.hide}
            tag="div"
            className="d-flex align-items-start pb-0"
          >
            <h3 className="text-uppercase mb-1">
              {translate("PRICE_CALC_MODAL_TITLE")}
            </h3>
            <h4 className="mb-2">{wizardItemTitle}</h4>
          </ModalHeader>
          <ModalBody className={cx("m-0 pt-2_5 d-flex row pb-0")}>
            <ProductListWithControls
              projectId={projectId}
              nodeId={nodeId}
              configurationOption={configurationOption}
              isInModal={true}
            />
          </ModalBody>
          <ModalFooter className="pt-0">
            <div className="m-0 d-flex">
              <Button
                color="secondary"
                data-testid="PriceCalculationModal_minimize-btn"
                outline
                iconPrefix="trox"
                icon="arrows-rotate-regular"
                onClick={handleHide}
              >
                {translate("MINIMIZE")}
              </Button>
            </div>
          </ModalFooter>
        </div>
      </ModalWrapper>
    );
  }),
);
