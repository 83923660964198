import { useCallback, useEffect } from "react";

export const useClickOutside = (
  ref,
  onClick,
  isActive = true,
  stopPropagation = false,
) => {
  const handleClickOutside = useCallback(
    (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        stopPropagation && event.stopImmediatePropagation();
        onClick(onClick, event);
      }
    },
    [onClick, ref, stopPropagation],
  );

  useEffect(() => {
    if (isActive) {
      document.addEventListener("mousedown", handleClickOutside, {
        capture: true,
      });
      return () => {
        document.removeEventListener("mousedown", handleClickOutside, {
          capture: true,
        });
      };
    }
  }, [handleClickOutside, isActive]);
};
