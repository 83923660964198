// Project tree node manipulations
import { get, set } from "lodash-es";
import { ProjectTreeQry } from "./queries";

const cloneTree = (tree) => JSON.parse(JSON.stringify(tree));

const getParentPath = (node) => {
  if (node?.systemPath.length > 9) {
    let parentPath = node.systemPath.slice(0, -9);
    if (!parentPath.endsWith("]")) {
      const lastCloseBracketIndex = parentPath.lastIndexOf("]");
      if (lastCloseBracketIndex !== -1) {
        const invalidSubstringLength = parentPath.slice(
          lastCloseBracketIndex + 1,
        ).length;
        parentPath = parentPath.slice(0, -invalidSubstringLength);
      }
    }
    return parentPath;
  }
  return null;
};

const getIndexFromPath = (node) => Number(node.systemPath.match(/\d+(?=\D*$)/));

const updateReferencePositions = (nodes, startIndex, shift) => {
  for (let i = startIndex; i < nodes.length; i += 1) {
    nodes[i].referencePosition += shift;
  }
};

// CREATE
// TODO potential improvement: if AB returned us entire parent node of newly created node, we might
// simplify it (no update call would be needed) note: nodeCreate will NOT update the cache. Cache
// will be updated after call (to API) nodeUpdate for parent of the created node this is the one
// way to omit expensive "recursion" method (where we would need to update all referencePosition
// and all systemPaths of all nested nodes)
const nodeCreate = (projectTree, newNode, _args) => {
  const newProjectTree = cloneTree(projectTree);
  const parentNodePath = getParentPath(newNode);
  const createdNodeIndex = getIndexFromPath(newNode);
  let parentNode;
  if (parentNodePath) {
    parentNode = get(newProjectTree, parentNodePath);
    if (!parentNode.nodes) {
      set(newProjectTree, newNode.systemPath, newNode);
    } else {
      parentNode.nodes.splice(createdNodeIndex, 0, newNode);
      updateReferencePositions(parentNode.nodes, createdNodeIndex, 1);
      set(newProjectTree, parentNodePath, parentNode);
    }
  } else {
    newProjectTree.splice(createdNodeIndex, 0, newNode);
    updateReferencePositions(newProjectTree, createdNodeIndex, 1);
  }

  return newProjectTree;
};

// UPDATE
// updates and/or MOVES node
// TODO potential improvement: if AB returned us new data of oldParent and newParent, we might
// simplify it (no additional update call for "move" would be needed)

const nodeUpdate = (projectTree, { __typename, ...newNode }, _args) => {
  const newProjectTree = JSON.parse(JSON.stringify(projectTree));
  // move action
  if (_args.referencePosition) {
    return newProjectTree;
  }
  // update action
  set(newProjectTree, newNode.systemPath, newNode);

  return newProjectTree;
};

const configUpdate = (projectTree, { __typename, ...newConfig }, _args) => {
  const newProjectTree = JSON.parse(JSON.stringify(projectTree));
  set(newProjectTree, newConfig.systemPath, {
    ...get(projectTree, newConfig.systemPath),
    ...newConfig,
  });

  return newProjectTree;
};
// MOVE
const configMove = (projectTree, _movedConfig, _args) => projectTree;

// DELETE
// TODO potential improvement: if AB returned us entire parent node of just deleted node, we might
// simplify it (no update call would be needed)
const nodeDelete = (projectTree, deletedNode, _args) => {
  if (deletedNode) {
    const newProjectTree = cloneTree(projectTree);
    const parentNodePath = getParentPath(deletedNode);
    const deletedNodeIndex = getIndexFromPath(deletedNode);

    if (parentNodePath) {
      const parentNode = get(newProjectTree, parentNodePath);
      parentNode.nodes.splice(deletedNodeIndex, 1);
      updateReferencePositions(parentNode.nodes, deletedNodeIndex, -1);
      set(newProjectTree, parentNodePath, parentNode);
    } else {
      newProjectTree.splice(deletedNodeIndex, 1);
      updateReferencePositions(newProjectTree, deletedNodeIndex, -1);
    }
    return newProjectTree;
  }
};
const configDelete = (projectTree, _deletedConfig, _args) => projectTree;

// COPY
// TODO potential improvement: if AB returned us entire parent node of target node (new place where
// the new node should appear), we might simplify it (no update call would be needed)

const nodeCopy = (projectTree, copiedNode, _args) => {
  const newProjectTree = cloneTree(projectTree);
  const parentNodePath = getParentPath(copiedNode);
  const copiedNodeIndex = getIndexFromPath(copiedNode);
  let parentNode;
  if (parentNodePath) {
    parentNode = get(newProjectTree, parentNodePath);
    if (!parentNode.nodes) {
      set(newProjectTree, copiedNode.systemPath, copiedNode);
    } else {
      parentNode.nodes.splice(copiedNodeIndex, 0, copiedNode);
      updateReferencePositions(parentNode.nodes, copiedNodeIndex, 1);
      set(newProjectTree, parentNodePath, parentNode);
    }
  } else {
    newProjectTree.splice(copiedNodeIndex, 0, copiedNode);
    updateReferencePositions(newProjectTree, copiedNodeIndex, 1);
  }
  return newProjectTree;
};

const configCopy = (projectTree, _copiedConfig, _args) => projectTree;

export const refreshCacheProjectTree = (action, result, args, cache) => {
  let callback = null;
  switch (action) {
    case "createNode":
      callback = nodeCreate;
      break;
    case "updateNode": // updates and/or moves node
      callback = nodeUpdate;
      break;
    case "deleteNode":
      callback = nodeDelete;
      break;
    case "copyNode":
      callback = nodeCopy;
      break;
    case "updateConfig":
      callback = configUpdate;
      break;
    case "deleteConfig":
      callback = configDelete;
      break;
    case "copyConfig":
      callback = configCopy;
      break;
    case "moveConfig":
      callback = configMove;
      break;
    default:
  }
  if (!callback) {
    return;
  }

  cache.updateQuery(
    {
      query: ProjectTreeQry,
      variables: {
        id:
          args.projectId ||
          new URLSearchParams(document.location.search)?.get("projectId"),
      },
    },
    (data) => ({
      ...data,
      project: {
        ...data.project,
        projectTree: callback(data.project.projectTree || [], result, args),
      },
    }),
  );
};
