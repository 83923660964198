import React from "react";
import * as Scrivito from "scrivito";
import { translate } from "utils";
import cx from "classnames";
import { ProjectTree } from "components/ProjectTree/ProjectTree";
import s from "./ProjectAddingModal.module.scss";

export const ProjectStructure = Scrivito.connect(
  ({ projectId, requestParams, projectTitle, isModalView }) => (
    <div className="mb-3">
      <div className="mb-3">
        <h5 className="text-uppercase">
          {translate("ADD_PRODUCT_MODAL_PROJECT_STRUCTURE_TITLE")} |{" "}
          {projectTitle}
        </h5>
        <span className="text-gray-500">
          {translate("ADD_PRODUCT_MODAL_PROJECT_STRUCTURE_SUBTITLE")}
        </span>
      </div>
      <div
        className={cx(
          "d-grid w-100 border rounded-1 p-3 overflow-auto",
          s.projectHeight,
        )}
      >
        <ProjectTree
          projectId={projectId}
          pasteModeOnly
          isWizardObjsOmit
          pasteItem={requestParams}
          isModalView={isModalView}
        />
      </div>
    </div>
  ),
);
