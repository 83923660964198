import React, { useCallback, useMemo } from "react";
import { Field, Form, Formik } from "formik";
import * as Scrivito from "scrivito";
import NiceModal from "@ebay/nice-modal-react";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useMutation } from "urql";
import { Button } from "components/Button";
import { FormSelect } from "components/Inputs";
import { translate } from "utils";
import { ProjectStatusUpdateMut } from "api/mutations";
import { useProjectStatuses } from "hooks";
import { ModalWrapper } from "../ModalWrapper";

export const UpdateProjectStatusModal = NiceModal.create(
  Scrivito.connect(({ project = {} }) => {
    const projectStatuses = useProjectStatuses();
    const modal = NiceModal.useModal();

    const [projectStatusUpdateRes, exeProjectStatusUpdate] = useMutation(
      ProjectStatusUpdateMut,
    );

    const { fetching } = projectStatusUpdateRes;

    const handleSubmit = useCallback(
      (values) => {
        if (!fetching) {
          exeProjectStatusUpdate({
            id: project.id,
            input: {
              status: values.status?.value || projectStatuses[0]?.status,
            },
          }).then(({ error }) => {
            if (!error) {
              modal.resolve();
              modal.remove();
            }
          });
        }
      },
      [exeProjectStatusUpdate, projectStatuses, project.id, fetching, modal],
    );

    const optionsList = useMemo(
      () =>
        projectStatuses?.map((item) => ({
          value: item.status,
          label: translate(`PROJECT_STATUS_${item.status.toUpperCase()}`),
          color: item.color,
        })) || [],
      [projectStatuses],
    );

    const initialValues = useMemo(
      () => ({
        status: optionsList.find((item) => item.value === project?.status),
      }),
      [optionsList, project?.status],
    );

    return (
      <ModalWrapper
        isOpen={modal.visible}
        centered={true}
        size="md"
        toggle={modal.hide}
        isLoading={optionsList === undefined}
      >
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialValues}
          enableReinitialize
        >
          <Form>
            <ModalHeader
              toggle={modal.hide}
              tag="div"
              className="d-flex align-items-start"
            >
              <h3 className="text-uppercase">{translate("PROJECT_STATUS")}</h3>
              <h4 className="mb-0">{project.title}</h4>
            </ModalHeader>
            <ModalBody className="pt-2_5 d-flex row">
              <div className="col-12">
                <Field
                  component={FormSelect}
                  name="status"
                  wrapperClassName="mt-3"
                  autoFocus={true}
                  data-recording-disable={true}
                  label={translate("PROJECT_STATUS")}
                  tooltipText={translate("PROJECT_STATUS_SELECT_TOOLTIP_TEXT")}
                  options={optionsList}
                />
              </div>
            </ModalBody>
            <ModalFooter className="pt-0">
              <Button color="primary" type="submit">
                {translate("TEXT_SAVE_CHANGES")}
              </Button>
            </ModalFooter>
          </Form>
        </Formik>
      </ModalWrapper>
    );
  }),
);
