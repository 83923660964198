import React from "react";
import cx from "classnames";
import { TopologyItemPDF } from "./TopologyItemPDF";
import { FAMpdf } from "./FAMpdf";
import { SWpdf } from "./SWpdf";
import { VMpdf } from "./VMpdf";

import s from "./Preview.module.scss";

export const SCpdf = React.memo(
  ({
    kind,
    sameLayer,
    connectedByTopBracketChildren,
    bottomPlacedChildren,
    ...restProps
  }) => (
    <div className="d-flex gap-6">
      <div className="d-flex flex-column">
        <TopologyItemPDF {...restProps} kind={kind} />
        {sameLayer && (
          <div
            className={cx("position-relative d-flex mt-4", {
              [s.scLayer]: sameLayer,
            })}
          >
            {sameLayer.map((item) => (
              <VMpdf key={item.id} {...item} />
            ))}
          </div>
        )}
      </div>
      <div className={cx("position-relative", s.famLayer)}>
        {bottomPlacedChildren?.map((item, key) => (
          <SWpdf key={key} {...item} />
        ))}
        {connectedByTopBracketChildren && (
          <div className="d-flex">
            {connectedByTopBracketChildren.map((item, key) => (
              <FAMpdf key={key} {...item} />
            ))}
          </div>
        )}
      </div>
    </div>
  ),
);
