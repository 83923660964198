import { refreshCacheProjectTree } from "api/urqlCacheUtils";

export const projectSideEffects = {
  mutations: {
    createProjectNode(result, args, cache) {
      if (result.createProjectNode) {
        refreshCacheProjectTree(
          "createNode",
          result.createProjectNode,
          args,
          cache,
        );
      }
    }, // updates and/or moves node
    updateProjectNode(result, args, cache) {
      if (result.updateProjectNode) {
        refreshCacheProjectTree(
          "updateNode",
          result.updateProjectNode,
          args,
          cache,
        );
      }
    },
    deleteProjectNode(result, args, cache) {
      if (result.deleteProjectNode) {
        refreshCacheProjectTree(
          "deleteNode",
          result.deleteProjectNode,
          args,
          cache,
        );
      }
    },
    copyProjectNode(result, args, cache) {
      if (result.copyProjectNode) {
        refreshCacheProjectTree(
          "copyNode",
          result.copyProjectNode,
          args,
          cache,
        );
      }
    },
    editProjectNodeConfiguration(result, args, cache) {
      if (result.editProjectNodeConfiguration) {
        refreshCacheProjectTree(
          "updateConfig",
          result.editProjectNodeConfiguration,
          args,
          cache,
        );
      }
    },
    deleteProjectNodeConfiguration(result, args, cache) {
      if (result.deleteProjectNodeConfiguration) {
        refreshCacheProjectTree(
          "deleteConfig",
          result.deleteProjectNodeConfiguration,
          args,
          cache,
        );
      }
    },
    copyProjectNodeConfiguration(result, args, cache) {
      if (result.copyProjectNodeConfiguration) {
        refreshCacheProjectTree(
          "copyConfig",
          result.copyProjectNodeConfiguration,
          args,
          cache,
        );
      }
    },
    moveProjectNodeConfiguration(result, args, cache) {
      if (result.moveProjectNodeConfiguration) {
        refreshCacheProjectTree(
          "moveConfig",
          result.moveProjectNodeConfiguration,
          args,
          cache,
        );
      }
    },
  },
};
