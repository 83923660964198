import React, { useCallback, useMemo } from "react";
import {
  FormFeedback,
  FormGroup,
  Label,
  UncontrolledTooltip,
} from "reactstrap";
import { useFormikContext } from "formik";
import cx from "classnames";
import { Select } from "components/Select/Select";
import { Icon } from "components/Icon/Icon";
import { TranslateWrapper } from "components/TranslateWrapper";

export const FormSelect = ({
  field,
  form,
  wrapperClassName,
  label,
  tooltipText,
  options,
  ...restProps
}) => {
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const meta = form.getFieldMeta(field.name);
  const isInvalid = useMemo(
    () => meta.touched && !!meta.error,
    [meta.error, meta.touched],
  );

  const handleBlur = () => {
    setFieldTouched(field.name, true);
  };

  const handleChange = useCallback(
    (val) => {
      setFieldValue(field.name, val);
    },
    [field.name, setFieldValue],
  );

  return (
    <FormGroup className={wrapperClassName}>
      {label && (
        <Label
          className="label-small fw-bolder d-flex align-items-center justify-content-between mt-1 text-gray-700"
          for={field.name}
        >
          <div className="d-flex align-items-center">
            {label}
            {tooltipText && (
              <>
                <Icon
                  className="ms-2 text-gray-600 cursor-pointer"
                  id={field.name}
                  tabIndex={-1}
                  iconName="circle-info"
                  prefix="far"
                />
                <UncontrolledTooltip target={field.name} placement="bottom">
                  {tooltipText}
                </UncontrolledTooltip>
                {typeof tooltipText === "object" && (
                  <TranslateWrapper
                    tooltipId={field.name}
                    tooltipText={tooltipText.props.tooltipText}
                  />
                )}
              </>
            )}
          </div>
        </Label>
      )}
      <Select
        {...field}
        onChange={handleChange}
        onBlur={handleBlur}
        options={options}
        value={field.value || (options && options[0])}
        className={cx({ "is-invalid": isInvalid })}
        isInvalid={isInvalid}
        testId={field.name}
        {...restProps}
      />
      <FormFeedback valid={!isInvalid} className="mt-2">
        {meta.error}
      </FormFeedback>
    </FormGroup>
  );
};
