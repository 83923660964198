import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import cx from "classnames";
import { Collapse } from "reactstrap";
import { debounce, isEqual } from "lodash-es";
import {
  useProjectTreeDragDrop,
  useProjectTreeIntersectionObserver,
} from "hooks";
import { Product } from "components/ProjectTree/Product";
import { Folder } from "components/ProjectTree/Folder/Folder";
import { DropArea } from "components/ProjectTree/DropArea";
import styles from "components/ProjectTree/ProjectTree.module.scss";
import { useProjectTreeContext } from "providers";
import { REFRESH_DELAY, dropRelations } from "variables";

const TreeNode = React.memo(
  ({
    configurations,
    id,
    isChildOfWizard,
    isParentDragged,
    kind,
    localPath,
    nodes = [],
    parentId = null,
    referencePosition,
    title,
    siblingsNodes,
    projectName,
  }) => {
    const [isVisible, setVisible] = useState(false);
    const ulRef = useRef(null);
    const intersecionObzerverRef = useRef();

    const { exeGetProjectTree, copiedItem, isSummary } =
      useProjectTreeContext();
    const exeGetProjectTreeDebounced = useMemo(
      () => debounce(exeGetProjectTree, REFRESH_DELAY),
      [exeGetProjectTree],
    );

    const handleIntersection = (entries) => {
      if (entries[0].isIntersecting) {
        setVisible(true);
      }
    };
    useProjectTreeIntersectionObserver(
      intersecionObzerverRef,
      handleIntersection,
    );

    const isWizard = useMemo(
      () =>
        isChildOfWizard ||
        ["wizardFireprotection", "wizardRoomcontrol"].includes(kind),
      [isChildOfWizard, kind],
    );
    const [isOpen, setIsOpen] = useState(!isWizard);

    const {
      dragRef,
      dropRef,
      isDragging,
      isOverCurrent,
      clientOffset,
      isDropInside,
      setIsDropInside,
      positionToFolder,
      setPositionToFolder,
    } = useProjectTreeDragDrop({
      id,
      title,
      isOpen,
      nodes,
      configurations,
      parentId,
      referencePosition,
      isWizard,
      exeGetProjectTreeDebounced,
      isParentDragged,
      dropRelations,
    });

    const handleToggle = useCallback(() => setIsOpen((val) => !val), []);

    const dropArea = useMemo(
      () => (
        <DropArea
          isOverCurrent={isOverCurrent}
          isDropInside={isDropInside}
          setIsDropInside={setIsDropInside}
          isParentDragged={isParentDragged}
          isDragging={isDragging}
          isWizard={isWizard}
        />
      ),
      [
        isDragging,
        isDropInside,
        isOverCurrent,
        isParentDragged,
        isWizard,
        setIsDropInside,
      ],
    );

    const folderWrapperRef = useRef();

    useEffect(() => {
      const { current: folderWrapper } = folderWrapperRef;
      const { top, bottom } = folderWrapper.getBoundingClientRect();
      const middle = (top + bottom) / 2;
      if (isDropInside) {
        setPositionToFolder(dropRelations.directly);
      } else if (clientOffset < middle) {
        setPositionToFolder(dropRelations.before);
      } else if (clientOffset > middle) {
        setPositionToFolder(dropRelations.after);
      }
    }, [
      clientOffset,
      isOverCurrent,
      positionToFolder,
      isParentDragged,
      isDragging,
      isWizard,
      isDropInside,
      title,
      setPositionToFolder,
    ]);

    return (
      <li
        className={cx("position-relative", {
          [styles.summary]: isSummary,
        })}
        ref={dropRef}
      >
        <div
          className={cx({
            [styles.blur]: isDragging || (isWizard && !!copiedItem),
          })}
          ref={intersecionObzerverRef}
        >
          <div className="cursor-pointer w-100">
            {positionToFolder === dropRelations.before && (
              <div
                className={cx({
                  [`${styles.overItem} ${styles.overItemBefore}`]:
                    isOverCurrent &&
                    !isParentDragged &&
                    !isDragging &&
                    !isWizard &&
                    !isDropInside,
                })}
              />
            )}
            <div className="position-relative py-2" ref={dragRef}>
              <Folder
                handleToggle={handleToggle}
                hasChildren={nodes?.length || configurations || null}
                id={id}
                isDragging={isDragging}
                isOpen={isOpen}
                isWizard={isWizard}
                isWizardWithLink={!isChildOfWizard && isWizard}
                key={`${id}_folder`}
                localPath={localPath}
                parentId={parentId}
                referencePosition={referencePosition}
                title={title}
                dropArea={dropArea}
                nodes={nodes}
                siblingsNodes={siblingsNodes}
                folderWrapperRef={folderWrapperRef}
                projectName={projectName}
                ulRef={ulRef}
              />
            </div>
            {positionToFolder === dropRelations.after && (
              <div
                className={cx({
                  [`${styles.overItem} ${styles.overItemAfter}`]:
                    isOverCurrent &&
                    !isParentDragged &&
                    !isDragging &&
                    !isWizard &&
                    !isDropInside,
                })}
              />
            )}

            {isVisible && (nodes?.length || configurations) && (
              <Collapse isOpen={isOpen}>
                <ul ref={ulRef} className={`mt-0 ${isSummary ? "ps-0" : ""}`}>
                  {configurations?.map((configuration, key) => (
                    <Product
                      key={key}
                      foldedOrdercode={configuration.foldedOrdercode}
                      seriesId={configuration.orderCode[0].seriesId}
                      {...configuration}
                      isWizardProduct={isWizard}
                      parentId={id}
                    />
                  ))}

                  {isVisible &&
                    nodes?.map((node) => (
                      <TreeNode
                        key={node.id}
                        isParentDragged={isDragging || isParentDragged}
                        isChildOfWizard={isWizard}
                        siblingsNodes={nodes}
                        {...node}
                      />
                    ))}
                </ul>
              </Collapse>
            )}
          </div>
        </div>
      </li>
    );
  },
  (prev, nextProps) => isEqual(prev, nextProps),
);

export default TreeNode;
