import tenantsJSONfile from "config/tenants.json";

const validateLanguages = (langs) => {
  langs.forEach((lang) => {
    if (lang.length < 5 && lang.length > 6) {
      return console.error(
        `allowed_locales needs to be 5 or 6 characters long (i.e. de-DE or de-DE2). Got: ${lang}. Please check tenants.json file`,
      );
    }
    if (!lang.includes("-")) {
      return console.error(
        `allowed_locales needs to be separated by "-" (i.e. de-DE). Got: ${lang}. Please check tenants.json file`,
      );
    }

    const langAbbrev = lang.substring(0, 2);
    const categoryLangAbbrev = lang.substring(3);
    if (langAbbrev !== langAbbrev.toLowerCase()) {
      return console.error(
        `lang abbreviation (first part of: ${lang}) needs to be written using lowercase. Please check tenants.json file`,
      );
    }
    if (categoryLangAbbrev !== categoryLangAbbrev.toUpperCase()) {
      return console.error(
        `category lang abbreviation (second part of: ${lang}) needs to be written using uppercase. Please check tenants.json file`,
      );
    }
  });
};

export const getAllAvailableLanguages = (tenantsJSON = tenantsJSONfile) => {
  const troxEnv = process.env.TROX_ENVIRONMENT;

  if (!troxEnv) {
    return console.error(
      "TROX_ENVIRONMENT needs to be defined. Please check .env file",
    );
  }
  if (!tenantsJSON[troxEnv]?.tenants) {
    return console.error(
      "No tenants set for declared TROX_ENVIRONMENT. Please check tenants.json file",
    );
  }
  const tenantsData = tenantsJSON[troxEnv].tenants;
  const tenantsHostnames = Object.keys(tenantsData);
  const allAvailableLanguages = [
    ...new Set(
      tenantsHostnames
        .map((tenantHost) => tenantsData[tenantHost].allowed_locales || [])
        .flat(),
    ),
  ].sort();
  validateLanguages(allAvailableLanguages);
  return allAvailableLanguages;
};
