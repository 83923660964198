import { useEffect } from "react";

export const useCenterTopologyItem = ({
  transformRef,
  centerTopologyItemId,
}) => {
  useEffect(() => {
    if (!transformRef.current) {
      return;
    }

    const context = transformRef.current;

    if (!centerTopologyItemId) {
      return;
    }

    const elementToBeCentered = document.getElementById(centerTopologyItemId);

    if (elementToBeCentered) {
      const { instance, zoomToElement } = context;
      const {
        transformState: { scale },
      } = instance;

      zoomToElement(elementToBeCentered, scale);
    }
  }, [centerTopologyItemId, transformRef]);
};
