import React, { useState, useEffect } from "react";
import * as Scrivito from "scrivito";
import {
  getPresentObjSearch,
  getHomepage,
  getPageLink,
  translate,
} from "utils";
import { Icon } from "./Icon/Icon";

export const Breadcrumb = Scrivito.connect(({ page }) => {
  const [ancestors, setAncestors] = useState([]);
  useEffect(() => {
    let isCancelled = false;
    Scrivito.load(() => {
      let items = [];
      if (!page) {
        return [];
      }
      if (page.path()) {
        items = page.ancestors().concat([page]);
      } else if (
        !page.path() &&
        (page.objClass() === "JobItem" ||
          page.objClass() === "NewsItem" ||
          page.objClass() === "EventItem")
      ) {
        const homepagePath = getHomepage().path();
        const parentMap = {
          JobItem: "JobList",
          NewsItem: "NewsList",
          EventItem: "EventList",
        };
        const listPageParent = getPresentObjSearch()
          .and("_objClass", "equals", parentMap[page.objClass()])
          .and("_path", "startsWith", homepagePath)
          .toArray()[0];

        items = listPageParent
          ? listPageParent.ancestors().concat([listPageParent, page])
          : [];
      } else {
        items = [getHomepage(), page];
      }
      return items.filter(
        (item) => item.objClass() !== "Root" && item.objClass() !== "Website",
      );
    }).then((items) => {
      if (!isCancelled) {
        setAncestors(items);
      }
    });
    return () => {
      isCancelled = true;
    };
  }, [page]);

  return (
    <nav className="breadcrumb-nav">
      <ol className="breadcrumb-list container">
        {ancestors.map((anc, idx) => {
          const classNames = ["breadcrumb-item"];
          if (idx === 0) {
            classNames.push("home");
          }
          const title =
            idx === 0 ? (
              <Icon prefix="fa-regular" iconName="house" />
            ) : (
              anc.get("title") || translate("PAGE_NO_PAGE_TITLE")
            );
          const ancLink = getPageLink(anc);

          return (
            <li key={anc.id()}>
              <Scrivito.LinkTag to={ancLink} className={classNames.join(" ")}>
                {idx !== 0 && (
                  <Icon prefix="trox" iconName="chevron-right-regular" />
                )}
                <span>{title}</span>
              </Scrivito.LinkTag>
            </li>
          );
        })}
      </ol>
    </nav>
  );
});
