import { gql } from "@urql/core";

const UserDataQry = gql`
  query {
    currentUser {
      id
      fullName
      email
      tenant
    }
  }
`;

export { UserDataQry };
