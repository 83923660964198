const getFileExtensionIcon = (extension) => {
  switch (extension) {
    case "pdf":
      return "fa-file-pdf-o";
    case "xls":
      return "fa-file-excel-o";
    case "xlsx":
      return "fa-file-excel-o";
    case "doc":
      return "file-word-o";
    case "zip":
      return "fa-file-zip-o";
    default:
      return "fa-file";
  }
};

const getFileTypeNameTranslationKey = (extension) => {
  switch (extension) {
    case "pdf":
      return "FILE_PDF";
    case "xls":
      return "FILE_EXCELL";
    case "xlsx":
      return "FILE_EXCELL";
    case "doc":
      return "FILE_WORD";
    case "zip":
      return "FILE_ZIP";
    default:
      return "FILE_DEFAULT";
  }
};

const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) {
    return "0 Bytes";
  }

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};

export const getBlobInfo = (blob, blobLength) => {
  const extension = blob._id.split(".").pop().toLowerCase();
  return {
    icon: getFileExtensionIcon(extension),
    info: {
      size: formatBytes(blobLength),
      type: getFileTypeNameTranslationKey(extension),
    },
  };
};
