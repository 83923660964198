import React, { useCallback, useRef, useState } from "react";
import { Table, Tooltip } from "reactstrap";
import { formatDate, getCategoryLanguage, translate } from "utils";
import * as Scrivito from "scrivito";
import { ProductListTableRowSkeleton } from "./ProductListTableRowSkeleton";
import { ProductListTableRow } from "./ProductListTableRow";
import { SortButton } from "../../SortButton/SortButton";

export const ProductListTable = Scrivito.connect(
  ({
    products = [],
    isOrderDescending,
    setIsOrderDescending,
    skeletonCount,
    fetching,
    tableRef,
    commissionFetching,
    isLastPage,
    commissioningProduct,
  }) => {
    const priceColRef = useRef(null);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const nameOrderToggle = useCallback(() => {
      setIsOrderDescending((prev) => !prev);
    }, [setIsOrderDescending]);

    const toggleTooltip = useCallback(
      () => setTooltipOpen(!tooltipOpen),
      [tooltipOpen],
    );

    const currentLang = getCategoryLanguage();
    const currentData = formatDate(new Date(), "YYYY/MM");

    return (
      <div ref={tableRef}>
        <Table>
          <thead>
            <tr>
              <th className="px-2 py-1">
                <div className="d-flex justify-content-between">
                  {translate("NAME")}
                  <SortButton
                    toggleFunc={nameOrderToggle}
                    isOrderDescending={!isOrderDescending}
                    disabled
                  />
                </div>
              </th>
              <th className="px-2 py-1">
                <div>{translate("PRESENTATION_ORDER_CODE")}</div>
              </th>
              <th className="px-2 py-1">{translate("QUANTITY")}</th>
              <th className="px-2 py-1">{translate("UNIT_PRICE")}</th>
              <th className="px-2 py-1">{translate("TOTAL_PRICE")}</th>
              <th className="px-2 py-1">
                <span ref={priceColRef}>{translate("PRICE_GROUP")}</span>
                <Tooltip
                  target={() => priceColRef.current}
                  placement="top"
                  isOpen={tooltipOpen}
                  toggle={toggleTooltip}
                >
                  {translate("PRODUCT_PRICE_LIST")}{" "}
                  <span className="fw-normal text-gray-400">
                    <span className="text-uppercase">{currentLang}</span>{" "}
                    {currentData} {translate("PRODUCT_PRICE_WITHOUT_VAT")}
                  </span>
                </Tooltip>
              </th>
            </tr>
          </thead>
          <tbody>
            {fetching ? (
              Array(skeletonCount)
                .fill(null)
                .map((_i, key) => <ProductListTableRowSkeleton key={key} />)
            ) : (
              <>
                {products?.length ? (
                  products?.map((product, key) => (
                    <ProductListTableRow key={key} product={product} />
                  ))
                ) : (
                  <tr>
                    <td className="text-center" colSpan="6">
                      {translate("PROJECT_LIST_NO_DATA")}
                    </td>
                  </tr>
                )}
              </>
            )}
            {isLastPage && commissioningProduct && (
              <>
                {commissionFetching ? (
                  <ProductListTableRowSkeleton />
                ) : (
                  <ProductListTableRow product={commissioningProduct} />
                )}
              </>
            )}
          </tbody>
        </Table>
      </div>
    );
  },
);
