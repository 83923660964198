import React, { useCallback } from "react";
import * as Scrivito from "scrivito";
import { translate } from "utils";
import { useMutation } from "urql";
import { CreateProjectReportsMut } from "api/mutations";
import { downloadFileByURL } from "utils/downloadFileByURL";
import { useAppBaseContext } from "providers";

import { Icon } from "../../Icon/Icon";
import { Button } from "../../Button";

export const ProductListDownloadButton = Scrivito.connect(
  ({ projectId, downloadAllowed = false }) => {
    const { lang } = useAppBaseContext();

    const [{ fetching }, createProjectReports] = useMutation(
      CreateProjectReportsMut,
    );

    const handleDownload = useCallback(() => {
      if (downloadAllowed) {
        createProjectReports({
          input: {
            projectId,
            languageCode: lang,
            bomCompact: { include: true },
          },
        }).then(({ data }) => {
          downloadFileByURL(data?.createProjectReports?.bomCompact?.url);
        });
      }
    }, [createProjectReports, lang, projectId, downloadAllowed]);

    return (
      <Button
        data-testid="ProductListDownload_download-list-btn"
        color="light"
        className="border border-gray-300 text-gray-700 px-2 py-1"
        isLoading={fetching}
        onClick={handleDownload}
        disabled={!downloadAllowed}
      >
        <Icon
          prefix="trox"
          iconName="arrow-down-bracket-regular"
          className="me-1"
        />
        {translate("DOWNLOAD_LIST")}
      </Button>
    );
  },
);
