export const priceToString = ({
  price = {},
  noPriceText,
  commissioningPrice = 0,
}) => {
  const { centAmount = 0, currencyCode, fractionDigits = 0 } = price;
  if (
    !currencyCode ||
    (!centAmount && !commissioningPrice) ||
    (!Number(centAmount) && !commissioningPrice)
  ) {
    return noPriceText;
  }

  const formatter = new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: currencyCode,
  });

  const formattedPrice = formatter.format(
    centAmount / 10 ** fractionDigits + commissioningPrice,
  );

  return formattedPrice;
};
