export const searchPageTranslations = {
  SEARCH_BUTTON: "Suchen",
  SEARCH_FIELD_PLACEHOLDER: "Suchbegriff hier eingeben",
  SEARCH_LABEL: "Suche",
  SEARCH_PAGE_CATEGORIES_HEADER: "Suchergebnisse filtern",
  SEARCH_PAGE_CATEGORIES_LIST_TITLE: "Inhalt",
  SEARCH_PAGE_CATEGORY_ALL: "Alle",
  SEARCH_PAGE_CATEGORY_CONTACT: "Ansprechpartner",
  SEARCH_PAGE_CATEGORY_DOCUMENTS: "Dokumente",
  SEARCH_PAGE_CATEGORY_NEWS: "News",
  SEARCH_PAGE_CATEGORY_PAGES: "Seiten",
  SEARCH_PAGE_INPUT_PLACEHOLDER: "Suchbegriff",
  SEARCH_PAGE_NO_RESULTS: "Ihre Suche führte leider zu keinem Ergebnis.",
  SEARCH_PAGE_RESULTS: "Ihr suchergebnis",
};
