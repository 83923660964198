import React from "react";
import * as Scrivito from "scrivito";
import { ToastContainer } from "react-toastify";

export const ToastContainerWrapper = Scrivito.connect(() => (
  <ToastContainer
    position="top-center"
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick={false}
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
  />
));
