const addressFromAddressWidget = (addressWidget) => ({
  "@type": "PostalAddress",
  streetAddress: addressWidget.get("locationStreetAddress"),
  addressLocality: addressWidget.get("locationLocality"),
  addressRegion: addressWidget.get("locationRegion"),
  postalCode: addressWidget.get("locationPostalCode"),
  addressCountry: addressWidget.get("locationCountry"),
});

const dataFromAddressWidget = (addressWidget) => ({
  "@type": "Place",
  name: addressWidget.get("locationName"),
  address: addressFromAddressWidget(addressWidget),
  telephone: addressWidget.get("phone"),
  faxNumber: addressWidget.get("fax"),
});

export default dataFromAddressWidget;
