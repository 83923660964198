import * as React from "react";
import * as Scrivito from "scrivito";
import { getPageLink } from "utils";
import { ImageResized } from "components/ImageResized/ImageResized";

export const ContactImage = Scrivito.connect(({ widget }) => {
  const media = widget.get("media");
  const links = widget.get("links") || [];
  const variantName =
    widget.get("mediaSize") === "large" ? "mediaboxLarge" : "mediaboxMedium";

  return (
    <React.Fragment>
      <Headline media={media} />
      {links.length > 0 ? (
        <Scrivito.LinkTag to={getPageLink(links[0])}>
          <ImageResized
            obj={media}
            variant={variantName}
            className="img-fluid"
          />
        </Scrivito.LinkTag>
      ) : (
        <ImageResized obj={media} variant={variantName} className="img-fluid" />
      )}
      <Caption media={media} />
    </React.Fragment>
  );
});

const Headline = ({ media }) => {
  if (media && media.get("headline")) {
    return <strong className="image-headline">{media.get("headline")}</strong>;
  }
  return null;
};

const Caption = ({ media }) => {
  if (media && media.get("caption")) {
    return <div className="image-caption">{media.get("caption")}</div>;
  }
  return null;
};
