import * as Scrivito from "scrivito";
import { getHomepage } from "./getHomepage";
import { getObjSearchWithHomepage } from "./filterObjSearch";

export const getPageObj = (pageObjectClass) =>
  getObjSearchWithHomepage()
    .and("_objClass", "equals", pageObjectClass)
    .first() || getHomepage();

export const getPageObjAsync = async (pageObjectClass) =>
  Scrivito.load(() => getPageObj(pageObjectClass));
