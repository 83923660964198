import React from "react";
import * as Scrivito from "scrivito";

import { Select } from "components/Select/Select";

export const ProjectSelect = Scrivito.connect(
  ({ handleOptionChange, options, selectedOption }) => (
    <Select
      onChange={handleOptionChange}
      options={options}
      value={selectedOption}
      name="productSelect"
      testId="project-select"
    />
  ),
);
