import React, { useCallback, useState } from "react";
import { Input } from "reactstrap";
import cx from "classnames";
import { Icon } from "components/Icon/Icon";
import { TranslateWrapper } from "components/TranslateWrapper";

import s from "./Inputs.module.scss";

export const SearchInput = ({
  value,
  onChange,
  name,
  id,
  testId,
  placeHolder = "Search",
  classNames,
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const toggleIsFocused = useCallback(() => {
    setIsFocused((val) => !val);
  }, []);

  const placeholderText =
    typeof placeHolder === "string" ? placeHolder : placeHolder.props.text;

  return (
    <>
      <div
        id="description-wrapper"
        className={cx(s.inputSearchWrapper, classNames)}
      >
        <Input
          className={cx("lh-1 ", s.inputSearch)}
          type="text"
          placeholder={!isFocused ? placeholderText : ""}
          name={name}
          id={id}
          data-testid={testId}
          onChange={onChange}
          value={value}
          onFocus={toggleIsFocused}
          onBlur={toggleIsFocused}
        />
        <Icon
          prefix="trox"
          iconName="magnifying-glass-regular"
          data-testid="magnifying-glass-regular"
          className="icon-16"
        />
      </div>
      {typeof placeHolder === "object" && (
        <TranslateWrapper
          tooltipText={placeHolder.props.tooltipText}
          tooltipId="description-wrapper"
        />
      )}
    </>
  );
};
