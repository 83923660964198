import React, { useMemo, useState } from "react";
import { useDrop } from "react-dnd";
import { useMutation } from "urql";
import cx from "classnames";
import { debounce } from "lodash-es";

import { NodeUpdateMut } from "api/mutations";
import { Folder } from "components/ProjectTree/Folder/Folder";
import { DropArea } from "components/ProjectTree/DropArea";
import { useProjectTreeContext } from "providers";
import { REFRESH_DELAY } from "variables";

import styles from "./Folder.module.scss";

export const RootFolder = React.memo(
  ({ title, hasChildren, handleToggle, isOpen, nodes, ulRef }) => {
    const { exeGetProjectTree } = useProjectTreeContext();
    const [isDropInside, setIsDropInside] = useState(false);

    const [_data, exeUpdateNode] = useMutation(NodeUpdateMut);

    const exeGetProjectTreeDebounced = useMemo(
      () => debounce(exeGetProjectTree, REFRESH_DELAY),
      [exeGetProjectTree],
    );

    const [{ isOverCurrent }, dropRef] = useDrop(
      () => ({
        accept: "box",
        drop: (item) => {
          exeUpdateNode({
            id: item.id,
            referencePosition: 1,
            input: { parentId: null },
          }).then(() => {
            exeGetProjectTreeDebounced();
          });
          setIsDropInside(false);
        },
        collect: (monitor) => ({
          isOverCurrent: monitor.isOver({ shallow: true }),
        }),
      }),
      [isDropInside],
    );

    const dropArea = (
      <DropArea
        isOverCurrent={isOverCurrent}
        isDropInside={isDropInside}
        setIsDropInside={setIsDropInside}
      />
    );

    return (
      <div ref={dropRef} className="pb-2 mb--2">
        <Folder
          dropArea={dropArea}
          handleToggle={handleToggle}
          hasChildren={hasChildren}
          isIconsShown={false}
          isOpen={isOpen}
          isRootNode
          title={title}
          nodes={nodes}
          ulRef={ulRef}
        />
        <div
          className={cx({
            [styles.overRoot]: isOverCurrent && !isDropInside,
          })}
        />
      </div>
    );
  },
);
