import React, { useCallback, useMemo } from "react";
import cx from "classnames";
import { Input, InputGroup, InputGroupText } from "reactstrap";
import { useFormikContext } from "formik";

import { Icon } from "components/Icon/Icon";

import s from "../Inputs.module.scss";

export const FormikNumberInput = ({
  field,
  label,
  max,
  min,
  classNames,
  isMaxTitleShown = false,
  disabled,
  onChange,
  ...restProps
}) => {
  const { setFieldValue } = useFormikContext();

  const isDisabled = useMemo(
    () => disabled || (min !== null && max && min === max),
    [disabled, max, min],
  );
  const handlePlusClick = useCallback(() => {
    if (!isDisabled && +field.value !== +max) {
      const newValue = (+field.value || 0) + 1;
      if (onChange) {
        onChange({ target: { value: newValue, name: field.name } });
      } else {
        setFieldValue(field.name, newValue);
      }
    }
  }, [field.name, field.value, isDisabled, max, onChange, setFieldValue]);

  const handleMinusClick = useCallback(() => {
    if (field.value === "NaN") {
      return;
    }
    if (!isDisabled && +field.value !== (+min || 0)) {
      const newValue = (+field.value || 0) - 1;
      if (onChange) {
        onChange({ target: { value: newValue, name: field.name } });
      } else {
        setFieldValue(field.name, newValue);
      }
    }
  }, [field.name, field.value, isDisabled, min, onChange, setFieldValue]);

  const handleChange = useCallback(
    (e) => {
      const fieldValue = parseInt(e.target.value, 10).toString();

      if (fieldValue === "NaN" || fieldValue < min) {
        return;
      }

      if (onChange) {
        onChange({
          target: {
            value: fieldValue > max ? +max : fieldValue,
            name: field.name,
          },
        });
      } else {
        setFieldValue(field.name, fieldValue > max ? +max : fieldValue);
      }
    },
    [field.name, min, max, onChange, setFieldValue],
  );

  const inputStyle = {
    width: "40px",
    textAlign: "center",
    color: isDisabled && "#4f515533",
  };

  return (
    <div className={cx("d-flex flex-wrap align-items-center pe-3", classNames)}>
      <div className="d-flex flex-column flex-grow-1">
        <span className="fs-md">{label}</span>
        {isMaxTitleShown && max !== Infinity && (
          <span className="text-gray-400">{`max. ${max}`}</span>
        )}
      </div>
      <InputGroup
        className={`w-auto d-flex flex-nowrap ${s.less}`}
        aria-disabled={isDisabled}
      >
        <InputGroupText
          className={`${s.noTextSelection} bg-gray-100 p-1`}
          onClick={handleMinusClick}
        >
          <Icon
            iconName="minus"
            className={cx("icon-12", {
              "cursor-not-allowed opacity-20":
                isDisabled || +min === +field.value,
              "cursor-pointer": !isDisabled || !(+min === +field.value),
            })}
          />
        </InputGroupText>

        <Input
          {...field}
          className={`${s.inputFirefox} rounded-0 text-center appearance-none fs-md p-0 num-input`}
          style={inputStyle}
          bsSize="sm"
          type="number"
          min={min || 0}
          max={max}
          disabled={isDisabled}
          onChange={handleChange}
          value={field.value || 0}
          {...restProps}
        />

        <InputGroupText
          className={`${s.noTextSelection} bg-gray-100 p-1`}
          onClick={handlePlusClick}
        >
          <Icon
            iconName="plus"
            className={cx("icon-12", {
              "cursor-not-allowed opacity-20":
                isDisabled || +max === +field.value,
              "cursor-pointer": !isDisabled || !(+max === +field.value),
            })}
          />
        </InputGroupText>
      </InputGroup>
    </div>
  );
};
