import React from "react";
import * as Scrivito from "scrivito";
import cx from "classnames";

import { translate } from "utils";
import { Checkbox } from "components/Inputs/Checkbox/Checkbox";
import { Icon } from "components/Icon/Icon";

export const ProjectStructureButtons = Scrivito.connect(
  ({
    withFiltering,
    showProductPictures,
    setShowProductPictures,
    isSummary,
  }) => {
    const handleShowProductPictures = () => {
      setShowProductPictures(!showProductPictures);
    };

    if (isSummary) {
      return null;
    }

    return (
      <div
        className={cx("d-flex align-items-center my-4", {
          "justify-content-between": withFiltering,
          "justify-content-end": !withFiltering,
        })}
      >
        {withFiltering && (
          <div className="d-flex align-items-center justify-content-start gap-2">
            <div className="d-flex align-items-center border-end pe-2 gap-1">
              <Icon
                iconName="bars-filter-regular"
                prefix="trox"
                className="text-gray-700 cursor-pointer"
              />
              {translate("FILTER")}
            </div>
            <Icon
              iconName="magnifying-glass-regular"
              prefix="trox"
              className="text-gray-700 cursor-pointer"
            />
          </div>
        )}

        <div className="d-flex gap-2">
          <Checkbox
            checked={showProductPictures}
            onChange={handleShowProductPictures}
            wrapperClassName="cursor-pointer"
          />
          {translate("PROJECT_STRUCTURE_SHOW_PRODUCT_PICTURES")}
        </div>
      </div>
    );
  },
);
