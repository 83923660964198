import * as Scrivito from "scrivito";
import React, { useEffect } from "react";
import { getCategoryClient, isActiveObj, translate } from "utils";
import { useStateLoaderContext } from "providers";

export const ObjValidator = Scrivito.connect(({ page, children }) => {
  const isEditMode = Scrivito.isInPlaceEditingActive();
  const isDevEnv = process.env.TROX_ENVIRONMENT === "development";

  const { setIsLoaderXCircle } = useStateLoaderContext();
  useEffect(() => setIsLoaderXCircle(true), [setIsLoaderXCircle]);

  const active = () => {
    const categoryClient = getCategoryClient();
    const categoryOwner = page.get("categoryOwner");

    return (
      (isActiveObj(page) && categoryOwner.includes(categoryClient)) ||
      isEditMode ||
      isDevEnv
    );
  };

  if (!active()) {
    return (
      <div className="container py-4">
        <h1>{translate("ERROR_PAGE_NOT_FOUND_TITLE")}</h1>
        {translate("ERROR_PAGE_NOT_FOUND_TEXT")}
        <br />
        <br />
        {translate("ERROR_PAGE_NOT_FOUND")}
      </div>
    );
  }

  return children;
});
