import * as Scrivito from "scrivito";

export const isActiveObj = (obj) => {
  if (!obj) {
    return false;
  }
  if (Scrivito.isInPlaceEditingActive()) {
    return true;
  }

  const from = obj.get("publishFrom");
  const until = obj.get("publishUntil");

  if (from && Date.parse(from) > Date.now()) {
    return false;
  }

  return !(until && Date.parse(until) < Date.now());
};
