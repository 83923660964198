import * as React from "react";
import * as Scrivito from "scrivito";

export const InPlaceEditingPlaceholder = ({ children, center, block }) => {
  if (!Scrivito.isInPlaceEditingActive()) {
    return null;
  }

  const innerSpan = (
    <span className="text-gray-400" data-testid="inner-span">
      {children}
    </span>
  );

  if (center) {
    return (
      <div className="text-center" data-testid="centered-placeholder">
        {innerSpan}
      </div>
    );
  }

  if (block) {
    return <div data-testid="block-placeholder">{innerSpan}</div>;
  }

  return innerSpan;
};
