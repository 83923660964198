import React, { useCallback } from "react";
import * as Scrivito from "scrivito";
import { AccordionBody, AccordionHeader, AccordionItem } from "reactstrap";
import { translate } from "utils";

import { Icon } from "../../Icon/Icon";
import { Checkbox } from "../../Inputs";

export const ProjectDownloadAccordionItem = Scrivito.connect(
  ({ title, toggle, children, checked, setItemsData }) => {
    const handleCheckboxClick = useCallback(() => {
      setItemsData((prevData) =>
        prevData?.map((item) => {
          if (item.title === title) {
            return { ...item, checked: !checked };
          }
          if (item.sublist) {
            return {
              ...item,
              content: item.content?.map((subItem) => ({
                ...subItem,
                checked:
                  subItem.title === title ? !subItem.checked : subItem.checked,
                content: subItem.content?.map((nestedItem) => ({
                  ...nestedItem,
                  checked:
                    nestedItem.title === title
                      ? !nestedItem.checked
                      : nestedItem.checked,
                })),
              })),
            };
          }
          return item;
        }),
      );
    }, [title, checked, setItemsData]);

    return (
      <AccordionItem className="border-bottom border-bottom-1 border-bottom-solid">
        <AccordionHeader tag="h6" className="p-2_5 mb-0" targetId={title}>
          <div>
            <Checkbox
              id={title}
              checked={checked}
              data-testid="checkbox-on-project-download-accordion"
              onChange={handleCheckboxClick}
            />
            <label htmlFor={title} className="ms-2 font-md text-gray-700">
              {translate(title)}
            </label>
          </div>
          <div>
            <Icon
              prefix="trox"
              iconName="chevron-down-light"
              onClick={() => toggle(title)}
            />
          </div>
        </AccordionHeader>
        <AccordionBody accordionId={title}>
          <div className="px-5">{children}</div>
        </AccordionBody>
      </AccordionItem>
    );
  },
);
