import React from "react";

export const ProductListTableRowSkeleton = () => (
  <tr>
    <td>
      <div>
        <div className="shortloadingLine col-8 rounded"></div>
        <div className="shortloadingLine col-12 rounded"></div>
      </div>
    </td>
    <td>
      <div>
        <div className="shortloadingLine col-8 rounded"></div>
        <div className="shortloadingLine col-12 rounded"></div>
      </div>
    </td>
    <td>
      <div className="blockSkeleton d-flex justify-content-center align-items-center w-100">
        <div className="whiteLine w-50" />
      </div>
    </td>
    <td>
      <div className="blockSkeleton d-flex justify-content-center align-items-center w-100">
        <div className="whiteLine w-50" />
      </div>
    </td>
    <td>
      <div className="blockSkeleton d-flex justify-content-center align-items-center w-75">
        <div className="whiteLine w-50" />
      </div>
    </td>
    <td>
      <div className="blockSkeleton d-flex justify-content-center align-items-center w-75">
        <div className="whiteLine w-50" />
      </div>
    </td>
  </tr>
);
