import React from "react";
import * as Scrivito from "scrivito";
import { FirstSubnavItem } from "components/LeftSidebar/SubnavsFirst/FirstSubnavItem";

export const LeftSidebarSubnavFirst = Scrivito.connect(
  ({ references, setActiveButton }) => {
    // In this case Scrivito.currentPageParams doesn't work for some reason so we use URLSearchParams
    const params = new URLSearchParams(window.location.search);
    const projectId = params.get("projectId");
    const seriesId = params.get("seriesId");

    return (
      <>
        {references?.map((referenceObj, key) => (
          <FirstSubnavItem
            key={key}
            seriesId={seriesId}
            projectId={projectId}
            referenceObj={referenceObj}
            setActiveButton={setActiveButton}
          />
        ))}
      </>
    );
  },
);
