import React, { useCallback, useMemo } from "react";
import * as Scrivito from "scrivito";
import { ModalHeader } from "reactstrap";
import NiceModal from "@ebay/nice-modal-react";
import { useMutation } from "urql";
import * as Yup from "yup";

import { useProjectStatuses } from "hooks";
import { translate } from "utils";
import { MAX_PROJECT_TITLE_LENGTH, unitOptions } from "variables/projects";

import { useCountryList } from "hooks/useCountryList";
import { useAppBaseContext, useUserContext } from "providers";
import { ProjectDetailsForm } from "./ProjectDetailsForm";
import { ModalWrapper } from "../../ModalWrapper";

export const ProjectDetailsModal = Scrivito.connect(
  NiceModal.create(({ project = {}, mutation, resetCallback = () => {} }) => {
    const modal = NiceModal.useModal();
    const projectStatuses = useProjectStatuses();
    const { lang } = useAppBaseContext();
    const { tenant } = useUserContext();
    const countryList = useCountryList({ language: lang });

    const [{ fetching, stale }, execute] = useMutation(mutation);

    const optionsList = useMemo(
      () =>
        projectStatuses?.map((item) => ({
          value: item.status,
          label: translate(`PROJECT_STATUS_${item.status.toUpperCase()}`),
          color: item.color,
        })),
      [projectStatuses],
    );

    const unitOptionsList = unitOptions.map(({ value, label }) => ({
      value,
      label: translate(`PROJECT_UNITS_${label.toUpperCase()}`),
    }));

    const countriesOptions = useMemo(
      () =>
        countryList?.map(({ displayName, isoTwoLetterCode }) => ({
          value: isoTwoLetterCode,
          label: displayName,
        })),
      [countryList],
    );

    const country = useMemo(() => {
      if (project?.country) {
        return countriesOptions?.find((item) => item.value === project.country);
      }
      return countriesOptions?.find((item) => item.value === tenant);
    }, [countriesOptions, project.country, tenant]);

    const initialValues = useMemo(
      () => ({
        title: project.title || "",
        constructionTitle: project.constructionTitle || "",
        projectNumber: project.projectNumber || "",
        description: project.description || "",
        status: optionsList?.find(
          (item) => item.value === (project.status || "draft"),
        ),
        country: country || { value: "de", label: "Germany" },
        units:
          unitOptionsList.find((item) => item.value === project.units) ||
          unitOptionsList[0],
      }),
      [
        project.title,
        project.constructionTitle,
        project.projectNumber,
        project.description,
        project.status,
        project.units,
        optionsList,
        country,
        unitOptionsList,
      ],
    );

    const validationSchema = useMemo(
      () =>
        Yup.object({
          title: Yup.string()
            .trim("The contact name cannot include leading and trailing spaces")
            .min(4, translate("PROJECT_NAME_VALIDATION_SHORT"))
            .required(translate("PROJECT_NAME_VALIDATION_EMPTY"))
            .test("max-length", function validateTitleLength(value) {
              return (
                value.length <= MAX_PROJECT_TITLE_LENGTH ||
                this.createError({
                  message: `${translate("PROJECT_NAME_VALIDATION_LONG")} ${
                    value.length
                  }`,
                  path: "title",
                  value,
                })
              );
            }),
          country: Yup.object()
            .typeError(" ")
            .nullable()
            .required(translate("PROJECT_LOCATION_SELECT_VALIDATION_EMPTY")),
          units: Yup.object()
            .typeError(" ")
            .nullable()
            .required(translate("PROJECT_UNITS_VALIDATION_EMPTY")),
        }),
      [],
    );

    const handleSubmit = useCallback(
      (values) => {
        if (!fetching) {
          execute(
            project?.id
              ? {
                  id: project.id,
                  input: {
                    ...values,
                    status:
                      values.status?.value || projectStatuses?.[0]?.status,
                    country: values.country?.value,
                    units: values.units?.value,
                  },
                }
              : {
                  ...values,
                  status: values.status?.value || projectStatuses[0]?.status,
                  country: values.country?.value,
                  units: values.units?.value,
                  language: lang,
                },
          ).then(({ data, error }) => {
            if (!error) {
              if (!project.id) {
                modal.resolve({ ...data.createProject });
              }
              modal.remove();
            }
          });
          resetCallback();
        }
      },
      [
        fetching,
        execute,
        project.id,
        projectStatuses,
        lang,
        resetCallback,
        modal,
      ],
    );

    return (
      <ModalWrapper
        isOpen={modal.visible}
        centered={true}
        size="xl"
        toggle={modal.hide}
        isLoading={fetching || stale}
      >
        <ModalHeader
          toggle={modal.hide}
          tag="div"
          className="d-flex align-items-start"
        >
          <h3 className="text-uppercase mb-1">
            {translate("PROJECT_PROPERTIES")}
          </h3>
          <h4 className="mb-2_5">
            {translate("PROJECT_MODAL_CREATE_SUB_TITLE")}
          </h4>
          {project.id && (
            <h5 className="mb-0 font16 font-normal gray">
              Project ID: {project.id}
            </h5>
          )}
          {project.owner?.fullName && (
            <p className="mb-0 font16 font-normal gray">
              Project Owner: {project.owner?.fullName}
            </p>
          )}
        </ModalHeader>
        <ProjectDetailsForm
          fetching={fetching}
          handleSubmit={handleSubmit}
          initialValues={initialValues}
          isUpdate={!!project.id}
          countriesOptions={countriesOptions}
          optionsList={optionsList}
          projectStatuses={projectStatuses}
          unitOptions={unitOptionsList}
          validationSchema={validationSchema}
        />
      </ModalWrapper>
    );
  }),
);
