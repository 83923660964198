import React from "react";
import * as Scrivito from "scrivito";
import { ContactImage } from "components/ContactImage";
import { VideoComponent } from "components/VideoComponent";

export const MediaComponent = Scrivito.connect(({ widget }) => {
  const media = widget && widget.get("media");
  const mediaType = media && media.objClass();
  if (!media && Scrivito.isInPlaceEditingActive()) {
    return <ContactImage widget={widget} />;
  }
  if (media && (mediaType === "Image" || mediaType === "ContactImage")) {
    return <ContactImage widget={widget} />;
  }
  if (media && mediaType === "VideoVimeo") {
    const iframeWidth = widget.get("mediaSize") === "small" ? 430 : 610;
    return <VideoComponent obj={media} width={iframeWidth} />;
  }
  return null;
});
