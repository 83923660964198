import React, { useCallback, useEffect, useState, useMemo } from "react";
import * as Scrivito from "scrivito";

import { useDrag } from "react-dnd";
import { useResizeDetector } from "react-resize-detector";
import { useMutation } from "urql";
import NiceModal from "@ebay/nice-modal-react";
import cx from "classnames";

import { Icon } from "components/Icon/Icon";
import { SingleProductDownloadModal } from "components/_Modals/ProjectDownloadModal/SingleProductDownloadModal";
import { IconSet } from "components/IconSet";
import { NumberInput } from "components/Inputs/NumberInput";
import { ConfirmationModal } from "components/_Modals/ConfirmationModal";
import { ConfigDeleteMut, ConfigUpdateMut, NodeUpdateMut } from "api/mutations";
import styles from "components/ProjectTree/ProjectTree.module.scss";
import {
  useAppBaseContext,
  useProductAddingModalContext,
  useProjectTreeContext,
} from "providers";
import { useProductOverview } from "hooks";
import { Button } from "../Button";

export const Product = React.memo(
  ({
    productName,
    quantity,
    id,
    isWizardProduct,
    presentation = "1234567",
    pimId,
    parentId,
    orderCode,
    foldedOrdercode,
    seriesId,
  }) => {
    const {
      copiedItem,
      handleStartCopy,
      translations,
      withDnd,
      pasteModeOnly,
      isSummary,
      isModalView,
      showProductPictures,
    } = useProjectTreeContext();

    const {
      submitBtnText,
      modalTitle,
      alertText,
      alertTextCaution,
      productQuantity,
    } = translations.product;
    const { width, ref } = useResizeDetector();
    const [isEditMode, changeIsEditMode] = useState(false);
    const [productImage, setProductImage] = useState(null);
    const [, exeUpdateNode] = useMutation(NodeUpdateMut);
    const handleToggleEditMode = useCallback(() => {
      changeIsEditMode((val) => !val);
    }, []);

    const page = Scrivito.currentPage();
    const { lang } = useAppBaseContext();
    const { projectId } = Scrivito.currentPageParams();
    const { productConfiguratorObj } = useProductOverview({ page, lang });

    const navigateToProduct = () => {
      if (isEditMode || isModalView) {
        return;
      }
      productConfiguratorObj &&
        Scrivito.navigateTo(() => productConfiguratorObj, {
          params: {
            seriesId: seriesId?.toString(),
            projectId,
            foldedOrdercode,
            nodeId: parentId,
            // TODO: temporary solution to pass order code in url, wait for AB implementation
            orderCode: JSON.stringify(orderCode[0].state.optionStates),
            isWizardProduct: isWizardProduct ? "true" : "false",
            productId: id,
          },
        });
    };

    useEffect(() => {
      async function loadProductImage() {
        if (pimId) {
          try {
            // TODO: When AB will be ready and providing us img URLs (or Binaries) we may get rid of loadProductImage at all
            const obj = await Scrivito.load(() =>
              Scrivito.Obj.where("pimId", "equals", pimId)
                .and("_objClass", "equals", "Image")
                .first(),
            );
            obj && setProductImage(obj);
          } catch (error) {
            console.error("Error loading product image:", error);
          }
        }
      }

      loadProductImage();
    }, [pimId]);

    // ---------- UPDATE CONFIG ------------------------------------------------------------------
    const [, exeEditProduct] = useMutation(ConfigUpdateMut);
    const handleUpdate = useCallback(
      (val) => {
        exeEditProduct({
          id,
          quantity: val,
        });
        handleToggleEditMode();
      },
      [exeEditProduct, handleToggleEditMode, id],
    );

    // ---------- OPEN DOWNLOAD MODAL ------------------------------------------------------------------
    const openDownloadModal = useCallback(() => {
      NiceModal.show(SingleProductDownloadModal, {
        modalSubtitle: productName,
        modalType: "Product",
        orderCode: orderCode[0],
      });
    }, [orderCode, productName]);

    // ---------- DELETE CONFIG ------------------------------------------------------------------
    const handleDelete = useCallback(() => {
      NiceModal.show(ConfirmationModal, {
        submitBtnText,
        title: modalTitle,
        alertText: `${alertText} "${productName}?" ${alertTextCaution}`,
        mutation: ConfigDeleteMut,
        params: { id },
      }).then((res) => {
        const deletedConfig = res.deleteProjectNodeConfiguration;
        if (deletedConfig) {
          exeUpdateNode({
            id: deletedConfig.nodeId,
          });
        }
      });
    }, [
      alertText,
      alertTextCaution,
      exeUpdateNode,
      id,
      modalTitle,
      submitBtnText,
      productName,
    ]);

    // ---------- COPY CONFIG ------------------------------------------------------------------
    const handleCopyStart = useCallback(() => {
      handleStartCopy({
        id,
        productName,
        quantity,
        presentation,
        type: "configuration",
      });
    }, [id, handleStartCopy, presentation, quantity, productName]);

    const [{ isDragging }, dragRef, preview] = useDrag(
      () => ({
        type: "box",
        item: {
          product: true,
          id,
          productName,
          parentId,
        },
        collect: (monitor) => ({
          isDragging: monitor.isDragging(),
        }),
        canDrag: () => {
          if (document.activeElement.tagName.toLowerCase() === "input") {
            return false;
          }

          return true;
        },
      }),
      [width, id, productName],
    );

    const { addedProducts } = useProductAddingModalContext();
    const classNames = useMemo(
      () =>
        `
        position-relative
        ${
          isSummary
            ? `${styles.productItemMin} ${styles.summary} ${styles.product} pb-2`
            : ""
        }
        ${
          showProductPictures
            ? `${styles.productItem} ${!isSummary ? "pb-3" : ""}`
            : "py-2"
        }
        ${!!copiedItem && !pasteModeOnly ? styles.blur : ""}
      `
          .trim()
          .replace(/\s+/g, " "),
      [isSummary, showProductPictures, copiedItem, pasteModeOnly],
    );

    return (
      <li className={classNames} ref={ref}>
        <div
          className={cx(
            "d-flex border-box align-items-center justify-content-between px-1  border text-gray-700",
            {
              [styles.wizardProduct]: isWizardProduct,
              [styles.summary]: isWizardProduct && isSummary,
              "opacity-50": isDragging,
              "px-3 py-2": !isSummary,
              "px-2 py-1 w-100 ps-1": isSummary,
              "cursor-pointer": !isWizardProduct,
            },
          )}
          ref={!isWizardProduct ? dragRef : preview}
          onDoubleClick={navigateToProduct}
        >
          <div className="d-flex align-items-center gap-2">
            {!isWizardProduct && withDnd && !isSummary && (
              <div ref={dragRef}>
                <Icon
                  iconName="grip-dots-vertical"
                  className="icon-12 text-gray-400 cursor-pointer"
                />
              </div>
            )}
            {!isSummary && showProductPictures && (
              <div className="icon-64 d-flex align-items-center justify-content-center">
                {productImage ? (
                  <Scrivito.ImageTag
                    width={64}
                    content={productImage}
                    className="w-100 h-100 object-fit-contain m-0"
                  />
                ) : (
                  <Icon
                    className="icon-64 m-2"
                    prefix="trox"
                    iconName="squares-light"
                  />
                )}
              </div>
            )}
            <div
              className={cx("d-flex gap-2", {
                "flex-column": showProductPictures,
              })}
            >
              <div className="d-flex align-items-center gap-2">
                <span
                  className={cx("fw-bolder fs-md", {
                    [styles.trimmed]: isSummary,
                    "ms-1": isSummary && isWizardProduct,
                  })}
                >
                  {productName}
                </span>
                {!isSummary ? (
                  !isWizardProduct &&
                  !isModalView && (
                    <Icon
                      className="text-gray-300 cursor-pointer"
                      prefix="trox"
                      iconName="arrow-up-right-square-regular"
                      onClick={navigateToProduct}
                    />
                  )
                ) : (
                  <span
                    className={cx("text-gray-400 ms-1", {
                      [styles.trimmed]: isSummary,
                    })}
                  >
                    {presentation}
                  </span>
                )}
              </div>
              {!isSummary && (
                <span className="text-gray-400">{presentation}</span>
              )}
            </div>
          </div>
          <div className="d-flex flex-nowrap">
            <div className="d-flex align-items-center me-2">
              {!isSummary && (
                <span onClick={() => !isEditMode && handleToggleEditMode()}>
                  {productQuantity}:
                </span>
              )}
              <div data-testid="product-quantity-input">
                {!isSummary && isEditMode ? (
                  <NumberInput
                    initialValue={quantity}
                    onSave={handleUpdate}
                    min={1}
                  />
                ) : (
                  quantity
                )}
              </div>
            </div>
            {!isSummary && addedProducts && addedProducts.includes(id) && (
              <Button
                square={true}
                color="tertiary"
                iconPrefix="trox"
                icon="trash-can-light"
                iconClassName="icon-16"
                classNames="p-0 border-0 ps-2 cursor-pointer"
                title="Delete"
                data-testid="deleteIcon"
                onClick={handleDelete}
              />
            )}
            {!isSummary && !pasteModeOnly && (
              <IconSet
                isEditMode={isEditMode}
                isEditable={true}
                isDuplicatable={false}
                handleToggleEditMode={handleToggleEditMode}
                onStartCopying={handleCopyStart}
                onDelete={handleDelete}
                onDownload={openDownloadModal}
                isWizard={isWizardProduct}
                isConfiguredProduct={true}
                id={id}
              />
            )}
          </div>
        </div>
      </li>
    );
  },
);
