import React, { useContext } from "react";
import * as Scrivito from "scrivito";

const AppBaseContext = React.createContext({});

const isProd = process.env.TROX_ENVIRONMENT === "production";

export const AppBaseProvider = Scrivito.connect(({ children }) => (
  <AppBaseContext.Provider
    value={{
      lang: Scrivito.currentPage()?.get("categoryLanguages")?.[0] || "de",
      isSameProj: Scrivito.currentPage()?.path()?.startsWith("/same") || false,
      isProd,
    }}
  >
    {children}
  </AppBaseContext.Provider>
));

export const useAppBaseContext = () => useContext(AppBaseContext);
