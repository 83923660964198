import React from "react";
import * as Scrivito from "scrivito";
import cx from "classnames";
import { getHomepage, translate } from "utils";
import { useProjectSummarySidebarContext } from "providers/ProjectSummarySidebarProvider";
import { ProjectTree } from "../ProjectTree/ProjectTree";
import s from "./ProjectSummarySidebar.module.scss";
import { Icon } from "../Icon/Icon";

export const ProjectSummarySidebar = Scrivito.connect(({ projectId }) => {
  const { isExtended, toggleIsExtended } = useProjectSummarySidebarContext();

  const navigateToConfigurator = () => {
    const configurator = getHomepage()?.get("projectConfiguratorPage");

    if (configurator) {
      Scrivito.navigateTo(() => configurator, {
        params: {
          projectId,
        },
      });
    }
  };
  return (
    <div
      className={cx(
        s.rightSidebarWrapper,
        "d-flex flex-shrink-0 w-100 bg-white z-index-1",
        {
          [s.rightSidebarWrapperExtended]: isExtended,
        },
      )}
    >
      <div
        className={cx(
          s.rightSidebarToggle,
          "d-flex flex-column bg-gray-700 cursor-pointer position-absolute gap-2",
          { [s.rightSidebarToggleExtended]: isExtended },
        )}
        onClick={toggleIsExtended}
      >
        <Icon iconName="chevron-left-regular" prefix="trox" />
        <span className="mb-0 text-nowrap text-white">
          {translate("PROJECT_SUMMARY_TITLE")}
        </span>
      </div>
      <div className="d-flex flex-column flex-shrink-0 overflow-hidden w-100">
        <div className="p-3_5 overflow-y-auto overflow-x-hidden w-100 h-100">
          <div className="d-flex justify-content-between align-items-center mb-1">
            <h5 className="text-uppercase mb-0 text-nowrap">
              {translate("PROJECT_SUMMARY_TITLE")}
            </h5>
            <Icon
              className="text-gray-400 icon-24 cursor-pointer"
              iconName="arrow-up-right-square-regular"
              prefix="trox"
              onClick={navigateToConfigurator}
            />
          </div>
          <h6 className="text-nowrap">
            {translate("PROJECT_SUMMARY_SIDEBAR_MY_STRUCTURE")}
          </h6>
          <ProjectTree
            className="mt-3_5 w-100"
            projectId={projectId}
            isSummary
            withDnd
            withFiltering
          />
        </div>
      </div>
    </div>
  );
});
