import { useEffect, useMemo, useState } from "react";
import { useQuery } from "urql";
import { UserDataQry } from "api/queries";
import { authIndicatorFlag } from "api/auth";

export const useConfiguratorUserData = () => {
  const [isDataRequested, setIsDataRequested] = useState(false);
  const [{ data, fetching, error, stale }, fetchUserData] = useQuery({
    query: UserDataQry,
    context: useMemo(
      () => ({
        requestPolicy: "cache-first",
      }),
      [],
    ),
    pause: true,
  });

  const isUserLoggedIn = localStorage.getItem(authIndicatorFlag);

  useEffect(() => {
    if (!isDataRequested) {
      isUserLoggedIn && fetchUserData();
    }
  }, [isUserLoggedIn, fetchUserData, isDataRequested, setIsDataRequested]);

  useEffect(() => {
    if (!fetching && !stale && (data || error)) {
      setIsDataRequested(true);
    }
  }, [data, error, fetching, stale]);

  return {
    error,
    fetching,
    isDataRequested,
    stale,
    userFullName: data?.currentUser?.fullName || null,
    userEmail: data?.currentUser?.email || null,
    tenant: data?.currentUser?.tenant?.toLowerCase() || null,
  };
};
