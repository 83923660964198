import * as React from "react";
import * as Scrivito from "scrivito";
import { MultiselectComponent } from "components/MultiselectField";

let id = 0;

export const createMultiselectComponent = (attributes = []) => {
  const name = `ScrivitoMultiselectField${(id += 1)}`;
  Scrivito.registerComponent(name, (props) => (
    <MultiselectComponent attributes={attributes} {...props} />
  ));
  return name;
};
