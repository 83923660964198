import React, { useCallback } from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import * as Scrivito from "scrivito";
import cx from "classnames";
import { Icon } from "./Icon/Icon";

export const TablePagination = Scrivito.connect(
  ({ pagesCount, currentPage, pagesAround = 2, onChangePage }) => {
    const paginationItems = [];
    let iPage = currentPage - pagesAround;
    const lastShownPage = currentPage + pagesAround;
    for (iPage; iPage <= lastShownPage; iPage += 1) {
      if (iPage > 0 && iPage <= pagesCount) {
        paginationItems.push(iPage);
      }
    }

    const isDisabled = useCallback(
      (pageNum) => pageNum < 1 || pageNum > pagesCount,
      [pagesCount],
    );

    const handlePageChange = (pageNum) => {
      if (!isDisabled(pageNum)) {
        onChangePage(pageNum);
      }
    };

    const renderEllipsis = () => (
      <PaginationItem disabled>
        <PaginationLink>
          <Icon
            prefix="trox"
            iconName="ellipsis-vertical-regular"
            className="fa-rotate-90"
          />
        </PaginationLink>
      </PaginationItem>
    );

    return (
      <Pagination
        aria-label="My projects table navigation"
        className={cx({ invisible: pagesCount === 1 })}
      >
        <PaginationItem
          disabled={isDisabled(currentPage - 1)}
          onClick={() => handlePageChange(currentPage - 1)}
        >
          <PaginationLink>
            <Icon prefix="trox" iconName="chevron-left-regular" />
          </PaginationLink>
        </PaginationItem>

        {currentPage > pagesAround + 1 && renderEllipsis()}

        {paginationItems.map((pageNum, idx) => (
          <PaginationItem
            key={idx}
            active={pageNum === currentPage}
            onClick={() => handlePageChange(pageNum)}
          >
            <PaginationLink>{pageNum}</PaginationLink>
          </PaginationItem>
        ))}

        {currentPage < pagesCount - pagesAround && renderEllipsis()}

        <PaginationItem
          disabled={isDisabled(currentPage + 1)}
          onClick={() => handlePageChange(currentPage + 1)}
        >
          <PaginationLink>
            <Icon prefix="trox" iconName="chevron-right-regular" />
          </PaginationLink>
        </PaginationItem>
      </Pagination>
    );
  },
);
