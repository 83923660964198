import React from "react";
import * as Scrivito from "scrivito";
import defaultTranslations from "config/locals/defaultTranslations";
import { TranslateWrapper } from "components/TranslateWrapper";
import { getDefaultLocale } from "./filterByTenant";

const TRANSLATE_TEMPLATE_REGEXP = /\{{2}\w+}{2}/;

export const checkIfKeyValid = (key) => {
  if (!key || !/^[A-Z][_A-Z0-9]*$/.test(key)) {
    console.error(
      `key: ${key} needs to be type string and be written using capital letters, numbers and underscore separator, can start with a letter only i.e. DE_M3PH`,
    );
    return false;
  }
  return true;
};

let cachedLocalsObj = null;
export function getLocalsObj() {
  if (!cachedLocalsObj) {
    cachedLocalsObj = Scrivito.Obj.where(
      "_objClass",
      "equals",
      "Locals",
    ).first();
  }
  return cachedLocalsObj;
}

export const checkKey = ({
  availableLangs = [],
  callback = () => {},
  key,
  lang,
  locals = {},
  localsSetters = {},
  replacers = [],
  value,
} = {}) => {
  const localsObj = getLocalsObj();
  const { localsAE, localsFJ, localsKO, localsPT, localsUZ } = locals;
  const { setLocalsAE, setLocalsFJ, setLocalsKO, setLocalsPT, setLocalsUZ } =
    localsSetters;
  const common = { availableLangs, localsObj, key, lang, replacers, value };
  if (!checkIfKeyValid(key)) {
    return;
  }

  if (key[0] >= "A" && key[0] <= "E") {
    return callback({
      localsAttr: localsAE,
      localsSetter: setLocalsAE,
      localsAttrName: "localsAE",
      ...common,
    });
  }
  if (key[0] >= "F" && key[0] <= "J") {
    return callback({
      localsAttr: localsFJ,
      localsSetter: setLocalsFJ,
      localsAttrName: "localsFJ",
      ...common,
    });
  }
  if (key[0] >= "K" && key[0] <= "O") {
    return callback({
      localsAttr: localsKO,
      localsSetter: setLocalsKO,
      localsAttrName: "localsKO",
      ...common,
    });
  }
  if (key[0] >= "P" && key[0] <= "T") {
    return callback({
      localsAttr: localsPT,
      localsSetter: setLocalsPT,
      localsAttrName: "localsPT",
      ...common,
    });
  }
  return callback({
    localsAttr: localsUZ,
    localsSetter: setLocalsUZ,
    localsAttrName: "localsUZ",
    ...common,
  });
};

export const insertReplacers = (string, replacers = []) => {
  if (typeof replacers === "string") {
    return string.replace(TRANSLATE_TEMPLATE_REGEXP, replacers);
  }

  return replacers.reduce(
    (res, elem) => res.replace(TRANSLATE_TEMPLATE_REGEXP, elem),
    string,
  );
};

export const getTranslation = ({ locals, key, lang, replacers } = {}) => {
  if (!checkIfKeyValid(key)) {
    return;
  }
  if (!lang || !(typeof lang === "string")) {
    return console.error(`lang parameter is required in type of string`);
  }
  const callback = ({ localsAttr, localsAttrName }) => {
    if (localsAttr) {
      return localsAttr[lang]?.[key] || defaultTranslations[key] || key;
    }
    const localsObj = getLocalsObj();

    const localsData = JSON.parse(localsObj?.get(localsAttrName) || "{}");
    let result;
    if (localsData) {
      result = localsData[lang]?.[key] || defaultTranslations[key] || key;
    } else {
      result = defaultTranslations[key] || key;
    }

    if (replacers?.length) {
      result = insertReplacers(result, replacers);
    }

    return result;
  };

  return checkKey({
    locals,
    key,
    callback,
    lang,
  });
};

export const saveTranslation = ({
  locals,
  localsObj,
  localsSetters,
  key,
  lang,
  value,
} = {}) => {
  if (!checkIfKeyValid(key)) {
    return;
  }
  if (!lang || !(typeof lang === "string")) {
    return console.error(`lang parameter is required in type of string`);
  }
  if (
    !(
      localsObj &&
      localsObj instanceof Scrivito.Obj &&
      localsObj.objClass() === "Locals"
    )
  ) {
    return console.error(
      `localsObj needs to be specified and be type of Locals Obj`,
    );
  }
  const callback = ({ localsAttr, localsSetter, localsAttrName }) => {
    if (!localsAttr || typeof localsAttr !== "object") {
      return console.error(
        `localsAttr parameter is required and to be object type - check locals parameter`,
      );
    }
    localsObj.update({
      [localsAttrName]: JSON.stringify({
        ...localsAttr,
        [lang]: { ...localsAttr[lang], [key]: value },
      }),
    });
    localsSetter((prev) => ({
      ...prev,
      [lang]: { ...prev[lang], [key]: value },
    }));
  };
  checkKey({
    localsObj,
    locals,
    localsSetters,
    key,
    callback,
    lang,
    value,
  });
};

export const clearTranslations = ({
  locals,
  localsSetters,
  availableLangs = [],
  localsObj,
  key,
} = {}) => {
  if (!checkIfKeyValid(key)) {
    return;
  }
  if (
    !(
      localsObj &&
      localsObj instanceof Scrivito.Obj &&
      localsObj.objClass() === "Locals"
    )
  ) {
    return console.error(
      `localsObj needs to be specified and be type of Locals Obj`,
    );
  }
  const callback = ({ localsAttr, localsSetter, localsAttrName }) => {
    if (!localsAttr || typeof localsAttr !== "object") {
      return console.error(
        `localsAttr parameter is required and to be object type - check locals parameter`,
      );
    }
    const newLocals = { ...localsAttr };
    availableLangs.forEach((lang) => {
      if (newLocals[lang]?.[key]?.length >= 0) {
        delete newLocals[lang][key];
      }
    });
    localsObj.update({
      [localsAttrName]: JSON.stringify(newLocals),
    });
    localsSetter(newLocals);
  };
  checkKey({
    availableLangs,
    localsObj,
    locals,
    localsSetters,
    key,
    callback,
  });
};

export const prepareLocalsForImport = ({
  localsData = {},
  key,
  lang,
  value = "",
} = {}) => {
  if (!checkIfKeyValid(key)) {
    return;
  }
  if (!lang || !(typeof lang === "string")) {
    return console.error(`lang parameter is required in type of string`);
  }
  const callback = ({ localsAttrName }) => {
    const newLocalsData = {
      ...localsData,
      [localsAttrName]: {
        ...(localsData[localsAttrName] || {}),
        [lang]: {
          ...((localsData[localsAttrName] &&
            localsData[localsAttrName][lang]) ||
            {}),
          [key]: value,
        },
      },
    };
    return newLocalsData;
  };
  return checkKey({
    key,
    callback,
  });
};

export const saveImportedLocals = async ({
  localsObj,
  localsAttrName,
  newLocalsAttrData,
  localsSetters,
} = {}) => {
  const { setLocalsAE, setLocalsFJ, setLocalsKO, setLocalsPT, setLocalsUZ } =
    localsSetters || {};
  if (
    !(
      localsObj &&
      localsObj instanceof Scrivito.Obj &&
      localsObj.objClass() === "Locals"
    )
  ) {
    return console.error(
      `localsObj needs to be specified and be type of Locals Obj`,
    );
  }
  localsObj.update({
    [localsAttrName]: JSON.stringify(newLocalsAttrData),
  });
  try {
    await localsObj.finishSaving();
  } catch (err) {
    console.error(`Something went wrong: ${err}`);
  }
  if (localsAttrName === "localsAE") {
    setLocalsAE(newLocalsAttrData);
  }
  if (localsAttrName === "localsFJ") {
    setLocalsFJ(newLocalsAttrData);
  }
  if (localsAttrName === "localsKO") {
    setLocalsKO(newLocalsAttrData);
  }
  if (localsAttrName === "localsPT") {
    setLocalsPT(newLocalsAttrData);
  }
  if (localsAttrName === "localsUZ") {
    setLocalsUZ(newLocalsAttrData);
  }
};

export function filterAvailableLangsByTeam(availableLangs = [], _editor = {}) {
  // TODO: not all Editors should be able to change Locales configuration.
  // Trox wished us to assign, which languages the certain User might change
  // i.e. ["DE-de", "DE-en", "EN-en"], ["DE-de"] or no translation option: []
  // but for now (for ISH) all Editors have access to Locales configuration
  return availableLangs;
}

// replacers accepts string or array of strings
export const translate = (key, replacers = []) => {
  const correctedKey = key.replace(/ |-/, ""); // if key name has inconsistent chars like "-" or " " replace them by "_"
  const upperKey = correctedKey.toUpperCase();
  checkIfKeyValid(upperKey);
  const currentPage = Scrivito.currentPage();
  const langs = currentPage?.get?.("categoryLanguages") || [];
  const defaultLang = getDefaultLocale(); // for localhost defaultLang is always de-DE
  let pageLang = defaultLang;

  if (langs.length === 1) {
    pageLang = `${langs[0]}-${defaultLang.split("-")[1]}`;
  }

  const text = checkKey({
    key: upperKey,
    callback: getTranslation,
    lang: pageLang,
    replacers,
  });

  const isEditMode = Scrivito.isInPlaceEditingActive();

  return isEditMode ? (
    <TranslateWrapper text={text} tooltipText={upperKey} />
  ) : (
    text
  );
};

export const asyncTranslate = async (key) =>
  Scrivito.load(() => translate(key));

export const getTranslationTextForEditMode = (key) => {
  const translation = translate(key);
  return typeof translation === "string" ? translation : translation.props.text;
};
