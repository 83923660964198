import * as React from "react";
import * as Scrivito from "scrivito";
import isEmpty from "is-empty";
import escapeHtml from "escape-html";
import { isPlainObject } from "lodash-es";
import dataFromEvent from "./dataFromEvent";
import dataFromJob from "./dataFromJob";
import dataFromAddressWidget from "./dataFromAddressWidget";

export const SchemaDotOrg = Scrivito.connect(({ content }) => {
  const data = pruneEmptyValues(dataFromItem(content));

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(data, escapeHtmlReplacer),
      }}
    />
  );
});

function escapeHtmlReplacer(_key, value) {
  return typeof value === "string" ? escapeHtml(value) : value;
}

function dataFromItem(item) {
  switch (item.objClass()) {
    case "AddressWidget":
      return dataFromAddressWidget(item);
    case "Event":
      return dataFromEvent(item);
    case "Job":
      return dataFromJob(item);
    default:
      throw new Error(
        `SchemaDotOrg for objClass ${item.objClass()} not supported!`,
      );
  }
}

function pruneEmptyValues(data) {
  let prunedData = mapValues(data, (subData) => {
    if (isPlainObject(subData)) {
      return pruneEmptyValues(subData);
    }
    return subData;
  });

  prunedData = pickBy(prunedData, (v) => !isEmpty(v));

  const keysWithoutAt = Object.keys(prunedData).filter(
    (sd) => !sd.startsWith("@"),
  );
  if (keysWithoutAt.length) {
    return prunedData;
  }
  return {};
}

function arrayToObject(array) {
  return Object.assign(...array.map(([key, value]) => ({ [key]: value })));
}

function mapValues(data, fn) {
  return arrayToObject(
    Object.entries(data).map(([key, value]) => [key, fn(value)]),
  );
}

function pickBy(data, fn) {
  return arrayToObject(
    Object.entries(data).filter(([_key, value]) => fn(value)),
  );
}
