import React, { useMemo } from "react";
import cx from "classnames";
import { topologyItems } from "objs/_ConfiguratorObjs/Wizard/wizardHelpers";
import { IconPDF } from "./IconPDF";

import s from "./Preview.module.scss";

export const TopologyItemPDF = React.memo(
  ({
    title,
    presentation,
    isSBModule,
    kind,
    isSBItem = false,
    classNames,
    iconName,
    withWhiteTriangle = false,
    directlyConnected,
    previouslyAddedElementsCount,
  }) => {
    const offset = useMemo(
      () =>
        directlyConnected && previouslyAddedElementsCount
          ? previouslyAddedElementsCount - directlyConnected.length
          : previouslyAddedElementsCount,
      [directlyConnected, previouslyAddedElementsCount],
    );
    return (
      <div
        className={cx(
          "d-flex flex-column flex-shrink-0 position-relative",
          classNames,
        )}
        style={{
          "--additionalHeight": offset ? `${offset * 52}px` : "0px",
          "--top": directlyConnected ? "28px" : "",
        }}
      >
        <div className="position-relative w-100 h-100">
          <div className="d-flex rounded overflow-hidden border-2">
            <div
              className={cx(
                "d-flex align-items-center justify-content-center p-2",
                topologyItems[kind]?.bgColor || "bg-gray-300 text-gray-700",
              )}
            >
              <IconPDF
                className={cx(
                  "d-flex justify-content-center align-items-center icon-32",
                  s.svgIcon,
                  topologyItems[kind]?.bgColor ? "text-white" : "text-gray-700",
                )}
                name={iconName}
              />
            </div>
            <div
              style={{ width: "280px" }}
              className={cx(
                "d-flex justify-content-center py-2 ps-2_5 flex-column pe-3 flex-shrink-0 text-gray-700 lh-16 border border-2",
                topologyItems[kind]?.borderColor || "border-gray-300",
              )}
            >
              <div className="panzoomDisabled position-absolute w-100 h-100" />
              <div className="fw-bolder text-truncate" title={title}>
                {title}
              </div>
              <div className="text-truncate">{presentation}</div>
            </div>
          </div>

          {withWhiteTriangle && (
            <div
              className="position-absolute ratio ratio-1x1"
              style={{
                bottom: "0",
                right: "0",
                width: "1rem",
                background:
                  "linear-gradient(to bottom right, transparent 0%, transparent 50%, white 50%, white 100%)",
                borderRadius: "0 0 2px 0",
              }}
            />
          )}
          {isSBModule && (
            <div className="text-gray-700 w-100 d-flex justify-content-end p-1 rounded-bottom">
              CB
            </div>
          )}
          {isSBItem && (
            <div
              className="bg-blue-400 w-100 position-absolute rounded-bottom"
              style={{ height: "4px", bottom: "0" }}
            />
          )}
          {directlyConnected?.map((item, key) => (
            <TopologyItemPDF key={key} {...item} />
          ))}
        </div>
      </div>
    );
  },
);
