import * as Scrivito from "scrivito";
import React from "react";
import { getHomepage, translate } from "utils";

export const WizardLink = Scrivito.connect(({ params }) => (
  <Scrivito.LinkTag
    className="ms-2"
    to={getHomepage()?.get("configWizardObjRefs")?.[1]}
    params={params}
  >
    {translate("PROJECT_OPEN_WIZARD")}
  </Scrivito.LinkTag>
));
