import React, { useMemo } from "react";
import cx from "classnames";
import * as Scrivito from "scrivito";
import { translate } from "utils";
import { projectTranslations } from "config/locals/projectTranslations";
import { Icon } from "../Icon/Icon";
import s from "./FilterTag.module.scss";

export const FilterTag = Scrivito.connect(
  ({ name, color, icon, iconPrefix }) => {
    const translateProp = useMemo(
      () => (name ? `PROJECT_STATUS_${name}` : ""),
      [name],
    );

    return (
      <div className={s.filterTag}>
        {color && (
          <div
            className={cx("mr-2 rounded-circle icon-12")}
            style={{ background: color }}
          />
        )}
        {icon && <Icon iconName={icon} prefix={iconPrefix}></Icon>}
        {projectTranslations[translateProp.toUpperCase()]
          ? translate(translateProp)
          : name}
      </div>
    );
  },
);
