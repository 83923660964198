import React from "react";
import { useDragLayer } from "react-dnd";
import styles from "./CustomDragLayer.module.scss";

function getItemStyles(initialOffset, currentOffset, width) {
  if (!initialOffset || !currentOffset) {
    return {
      display: "none",
    };
  }
  const { x, y } = currentOffset;

  const transform = `translate(${x}px, ${y}px)`;
  return {
    transform,
    WebkitTransform: transform,
    width: width ? `${width}px` : "auto",
  };
}

export const CustomDragLayer = ({ render, canDrag = true }) => {
  const { isDragging, item, currentOffset, initialOffset } = useDragLayer(
    (monitor) => ({
      item: monitor.getItem(),
      itemType: monitor.getItemType(),
      initialOffset: monitor.getInitialSourceClientOffset(),
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging(),
      canDrag,
    }),
  );

  if (!isDragging || item.product) {
    return null;
  }

  return (
    <div className={styles.customDragLayer}>
      <div style={getItemStyles(initialOffset, currentOffset, item.width)}>
        {render({ isDragPreview: true, ...item })}
      </div>
    </div>
  );
};
