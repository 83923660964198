import { gql } from "@urql/core";

const editTopologyConfigurationMut = gql`
  mutation (
    $id: String!
    $nodeId: String!
    $pimId: String!
    $productName: String!
    $orderCode: OrderCodeInput!
    $presentation: String!
  ) {
    editTopologyConfiguration(
      id: $id
      nodeId: $nodeId
      pimId: $pimId
      productName: $productName
      orderCode: $orderCode
      presentation: $presentation
    ) {
      topologyConfiguration {
        id
        nodeId
        pimId
        presentation
        productName
      }
      topology
    }
  }
`;

const deleteTopologyItemMut = gql`
  mutation ($wizardNodeId: String!, $topologyNodeId: String!) {
    deleteTopologyNode(
      wizardNodeId: $wizardNodeId
      topologyNodeId: $topologyNodeId
    ) {
      topologyNodeId
    }
  }
`;
const copyTopologyNodeConfigurationMut = gql`
  mutation ($wizardNodeId: String!, $topologyNodeId: String!) {
    copyTopologyNodeConfiguration(
      topologyNodeId: $topologyNodeId
      wizardNodeId: $wizardNodeId
    )
  }
`;

const pasteTopologyNodeConfigurationMut = gql`
  mutation (
    $wizardNodeId: String!
    $topologyNodeId: String!
    $targetIds: [String!]!
  ) {
    pasteTopologyNodeConfiguration(
      topologyNodeId: $topologyNodeId
      wizardNodeId: $wizardNodeId
      targetIds: $targetIds
    )
  }
`;

const addTopologyComponent = gql`
  mutation (
    $wizardNodeId: String!
    $topologyNodeId: String!
    $action: String!
    $targetIds: [String]
    $parameters: [CustomJson]
  ) {
    invokeTopologyAction(
      wizardNodeId: $wizardNodeId
      topologyNodeId: $topologyNodeId
      action: $action
      parameters: $parameters
      targetIds: $targetIds
    )
  }
`;

export {
  editTopologyConfigurationMut,
  deleteTopologyItemMut,
  copyTopologyNodeConfigurationMut,
  pasteTopologyNodeConfigurationMut,
  addTopologyComponent,
};
