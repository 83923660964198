import * as Scrivito from "scrivito";

export function configureScrivitoContentBrowser() {
  Scrivito.configureContentBrowser({
    filters: ({ _validObjClasses }) => {
      if (_validObjClasses) {
        switch (_validObjClasses.length) {
          case 0:
            return defaultFilters();
          case 1:
            return filterForObjClass(_validObjClasses[0]);
          default:
            return filtersForObjClasses(_validObjClasses);
        }
      }

      return defaultFilters();
    },
  });
}

function filterForObjClass(objClass) {
  return {
    _objClass: {
      options: {
        [objClass]: {
          ...filterOptionsForObjClass(objClass),
          selected: true,
        },
      },
    },
  };
}

function filtersForObjClasses(objClasses) {
  return {
    _objClass: {
      options: {
        All: {
          title: "All",
          icon: "folder",
          field: "_objClass",
          value: objClasses,
          selected: true,
        },
        ...objClasses.reduce((result, value) => {
          result[value] = filterOptionsForObjClass(value);
          return result;
        }, {}),
      },
    },
  };
}

function defaultFilters() {
  return {
    _objClass: {
      options: {
        All: {
          title: "All",
          icon: "folder",
          query: Scrivito.Obj.all(),
          selected: true,
        },
        Image: filterOptionsForObjClass("Image"),
        ContactImage: filterOptionsForObjClass("ContactImage"),
        Document: filterOptionsForObjClass("Document"),
        VideoVimeo: filterOptionsForObjClass("VideoVimeo"),
        ConfiguratorPages: {
          title: "Configurator Pages",
          icon: "gears",
          field: "_objClass",
          value: CONFIGURATOR_PAGES,
          options: CONFIGURATOR_PAGES.reduce((result, value) => {
            result[value] = filterOptionsForObjClass(value);
            return result;
          }, {}),
        },
        Pages: {
          title: "Pages",
          icon: "sheet",
          field: "_objClass",
          value: PAGES,
          options: PAGES.reduce((result, value) => {
            result[value] = filterOptionsForObjClass(value);
            return result;
          }, {}),
        },
        Products: {
          title: "Products",
          icon: "thumbnails",
          field: "_objClass",
          value: PRODUCT_OBJS,
          options: PRODUCT_OBJS.reduce((result, value) => {
            result[value] = filterOptionsForObjClass(value);
            return result;
          }, {}),
        },
        Slides: {
          title: "Slides",
          icon: "image",
          field: "_objClass",
          value: SLIDES,
          options: SLIDES.reduce((result, value) => {
            result[value] = filterOptionsForObjClass(value);
            return result;
          }, {}),
        },
      },
    },
    _modification: {
      title: "Changed",
      type: "checkbox",
      expanded: true,
      field: "_modification",
      options: {
        New: {
          value: "new",
        },
        Edited: {
          value: "edited",
        },
      },
    },
  };
}

function filterOptionsForObjClass(objClass) {
  const filterPresentation = FILTER_PRESENTATIONS[objClass] || {
    title: objClass,
    icon: "question",
  };
  return { field: "_objClass", value: objClass, ...filterPresentation };
}

const FILTER_PRESENTATIONS = {
  Contact: { title: "Contact", icon: "user" },
  ContactImage: { title: "Contact images", icon: "image" },
  ContentPage: { title: "Content Pages", icon: "sheet" },
  Document: { title: "Documents", icon: "pdf" },
  EventItem: { title: "Event Items", icon: "cal" },
  EventList: { title: "Event Lists", icon: "cal" },
  Homepage: { title: "Homepage", icon: "globe" },
  HomepageSame: { title: "HomepageSame", icon: "globe" },
  Image: { title: "Images", icon: "image" },
  JobItem: { title: "Job Items", icon: "suitcase" },
  JobList: { title: "Job Lists", icon: "suitcase" },
  LandingPage: { title: "Landing pages", icon: "sheet" },
  LibraryCustom: { title: "Library Custom", icon: "database" },
  LibraryList: { title: "Library List", icon: "list" },
  LibraryTemplates: { title: "Library Templates", icon: "database" },
  LoginPage: { title: "Login pages", icon: "user" },
  NewsItem: { title: "News Item", icon: "globe" },
  NewsList: { title: "News List", icon: "globe" },
  PageProductSeries: { title: "Page product series", icon: "sheet" },
  ProductConfigurator: { title: "Product Configurator", icon: "gear" },
  ProductList: { title: "Product List", icon: "list" },
  ProductSeries: { title: "Product series", icon: "rounded_square" },
  ProjectConfigurator: { title: "Project Configurator", icon: "gear" },
  ProjectList: { title: "Project List", icon: "list" },
  ProjectItem: { title: "Project Item", icon: "sheet" },
  Redirect: { title: "Redirects", icon: "link" },
  SearchPage: { title: "Search page", icon: "lens" },
  VideoVimeo: { title: "Videos Vimeo", icon: "video" },
  Wizard: { title: "Wizard", icon: "wand" },
  WizardList: { title: "WizardList", icon: "list" },
  WizardItem: { title: "WizardItem", icon: "sheet" },
};

const CONFIGURATOR_PAGES = [
  "LibraryList",
  "LibraryCustom",
  "LibraryTemplates",
  "ProductList",
  "ProductConfigurator",
  "ProjectList",
  "ProjectConfigurator",
  "ProjectItem",
  "Wizard",
  "WizardList",
  "WizardItem",
];

const PAGES = [
  "Contact",
  "ContentPage",
  "EventItem",
  "EventList",
  "Homepage",
  "JobItem",
  "JobList",
  "LandingPage",
  "LoginPage",
  "NewsItem",
  "NewsList",
  "Redirect",
  "SearchPage",
  "HomepageSame",
];

const PRODUCT_OBJS = ["ProductSeries", "PageProductSeries"];

const SLIDES = ["TextImageSlide", "TextImage2dSlide"];
