import * as React from "react";
import * as Scrivito from "scrivito";
import { getHomepage, getPageLink, isSystemConfiguratorLayout } from "utils";
import { InPlaceEditingPlaceholder } from "components/InPlaceEditingPlaceholder";
import { Container } from "reactstrap";
import cx from "classnames";

import s from "./Footer.module.scss";
import { FooterNavigation } from "./FooterNavigation";

export const Footer = Scrivito.connect(() => {
  const homepage = getHomepage();
  if (!homepage) {
    return null;
  }
  const footerWidgets = homepage.get("footerWidgets") || [];
  const minimizedFooterLinks = homepage.get("minimizedFooterLinks") || [];

  const pageType = Scrivito.currentPage().objClass();
  const isMinimizedFooterVisible = isSystemConfiguratorLayout(pageType);

  return (
    <div className="px-7 mt-auto">
      <InPlaceEditingPlaceholder block center>
        {isMinimizedFooterVisible
          ? "The minimized footer starts here. There’s only one minimized footer. It belongs to the homepage but is displayed on all other pages as well."
          : "The footer starts here. There’s only one footer. It belongs to the homepage but is displayed on all other pages as well."}
      </InPlaceEditingPlaceholder>
      {isMinimizedFooterVisible ? (
        <footer
          className="d-flex justify-content-between py-3 border-top text-gray-400"
          data-testid="minimizedFooter"
        >
          <div>
            {minimizedFooterLinks.map((link, key) => (
              <Scrivito.LinkTag
                to={getPageLink(link)}
                key={key}
                className="me-4 text-gray-400"
              >
                {link.title()}
              </Scrivito.LinkTag>
            ))}
          </div>
          <div className="fw-bold">
            {new Date().getFullYear()} &copy; TROX GmbH
          </div>
        </footer>
      ) : (
        <footer data-testid="footer">
          <div className="py-5_5">
            <Container>
              {footerWidgets.length !== 0 && (
                <Scrivito.ContentTag
                  content={homepage}
                  attribute="footerWidgets"
                />
              )}
            </Container>
          </div>
          <div className={cx("py-4", s.footerNavWrapper)}>
            <Container>
              <FooterNavigation homepage={homepage} />
            </Container>
          </div>
        </footer>
      )}
    </div>
  );
});
