import React, { useEffect } from "react";
import cx from "classnames";
import { useStateLoaderContext } from "providers/PageLoadStateProvider";
import { Icon } from "../Icon/Icon";
import s from "./XCircleLoader.module.scss";

export const XCircleLoader = ({ classNames }) => {
  const { setIsLoaderXCircle } = useStateLoaderContext();

  useEffect(() => setIsLoaderXCircle(true), [setIsLoaderXCircle]);
  useEffect(() => () => setIsLoaderXCircle(false), [setIsLoaderXCircle]);

  return (
    <div
      className={cx(
        "d-flex justify-content-center align-items-center flex-grow-1 bg-gray-100",
        classNames,
      )}
    >
      <div
        className={cx(
          "d-flex justify-content-center align-items-center",
          s.loader,
        )}
      >
        <svg
          className={s.svg}
          viewBox="0 0 50 50"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle className={s.path} cx="25" cy="25" r="20" fill="none" />
        </svg>
        <Icon
          prefix="trox"
          iconName="spinner-trox-X-logo"
          className="position-absolute icon-64 text-gray-300 z-1"
        />
      </div>
    </div>
  );
};
