import React, { useState } from "react";
import * as Scrivito from "scrivito";
import { translate } from "utils";
import { Icon } from "../../Icon/Icon";
import { Dropdown } from "../../Dropdown/Dropdown";

export const ProjectDownloadSortDropdown = Scrivito.connect(
  ({ showProducts }) => {
    const items = [
      { id: "products_type", title: "Products" },
      {
        id: "document_type",
        title: translate("DOCUMENT_TYPE"),
      },
      {
        id: "data_format",
        title: translate("DATA_FORMAT"),
      },
    ];

    const filteredItems = showProducts
      ? items
      : items.filter((item) => item.id !== "products_type");

    const [activeItem, setActiveItem] = useState(filteredItems[0]);

    return (
      <div className="d-flex align-items-center justify-content-end">
        <div className="font16 text-gray-400">{translate("SORTED_BY")}</div>
        <Dropdown
          btnChildren={
            <>
              {activeItem.title}
              <Icon iconName="fa-chevron-down" className="icon-12 ms-2" />
            </>
          }
          options={filteredItems.map((item) => ({
            ...item,
            isActive: item.title === activeItem.title,
            onClick: () => setActiveItem(item),
          }))}
          /* TODO: forthcoming feature (hidden acc US-19953) button disabled */
          disabled
        />
      </div>
    );
  },
);
