import React, { useCallback, useState } from "react";
import { authIndicatorFlag, logoutReq } from "api/auth";
import * as Scrivito from "scrivito";
import { getHomepage, getPageLink, getPageObj, translate } from "utils";
import { Button } from "../Button";

export const LogoutButton = Scrivito.connect(({ isGray = false }) => {
  const [error, setError] = useState(null);

  const isLoggedIn = localStorage.getItem(authIndicatorFlag);
  const homepage = getHomepage();
  const homepageId = homepage.id();

  const handleLogOut = useCallback(async () => {
    try {
      homepageId && (await logoutReq());
    } catch (err) {
      console.error(err);
      setError(err);
    }
  }, [homepageId]);

  const parentObj = getPageObj("LoginPage");

  return (
    <>
      {error && <div className="text-danger">{translate("ERROR_LOGOUT")}</div>}
      {isLoggedIn ? (
        <Button
          data-testid="NavRightSidebar_logout-btn"
          color={isGray ? "secondary" : "primary"}
          size="lg"
          onClick={handleLogOut}
        >
          {translate("LEAVE_TROX_CONFIGURATOR")}
        </Button>
      ) : (
        <Scrivito.LinkTag
          data-testid="nav-right-sidebar_login-link"
          to={getPageLink(parentObj)}
          className="py-3 rounded-1 bg-blue-300 text-center text-uppercase text-decoration-none text-white"
        >
          {homepage.get("configuratorNavLogin")}
        </Scrivito.LinkTag>
      )}
    </>
  );
});
