import * as Scrivito from "scrivito";
import tableRowWidgetIcon from "../assets/images/table_row_widget.svg";

export function configureScrivitoExtendMenu() {
  Scrivito.extendMenu((menu) => {
    menu.insert({
      id: "localsConfiguration",
      title: "Edit locals configuration",
      icon: tableRowWidgetIcon,
      onClick: () => Scrivito.openDialog("LocalsComponent"),
      position: { after: "system.openPageDetails" },
      group: "system.details",
    });
  });
}
