import { WizardRunQry } from "api/queries";

const getWizardRunCacheUpdateArguments = ({ cache, args }) => {
  const filteredCache = cache
    .inspectFields({ __typename: "Query" })
    .filter((item) => item.fieldName === "wizardRun");

  if (filteredCache.length > 1) {
    const findNeededIdAndStep = filteredCache.filter(
      (item) =>
        item?.arguments?.step === args.step &&
        item?.arguments?.nodeId === args.nodeId,
    );
    return findNeededIdAndStep[0];
  }
  return filteredCache[0];
};

function insertItemAndUpdatePositions(arr, newItem, insertIndex) {
  const newArr = [...arr];
  newArr.splice(insertIndex, 0, newItem);
  for (let i = insertIndex + 1; i < newArr.length; i += 1) {
    const position = newArr[i].position + 1;
    newArr[i].position = position;
  }

  return newArr;
}
export const wizardSideEffects = {
  queries: {
    wizardRun(_, variables, cache) {
      const wizardRunQueries = cache
        .inspectFields({ __typename: "Query" })
        .filter((item) => item.fieldName === "wizardRun");
      if (wizardRunQueries.length > 1) {
        const entitiesToInvalidate = wizardRunQueries.filter(
          (entity) => entity.arguments?.step !== variables.step,
        );
        entitiesToInvalidate.forEach((entity) =>
          cache.invalidate("Query", "wizardRun", entity.arguments),
        );
      }
    },
    wizardStepQuestionnaire(responseData, _, cache) {
      const wizardRunQueries = cache
        .inspectFields({ __typename: "Query" })
        .filter((item) => item.fieldName === "wizardRun");

      if (wizardRunQueries.length === 1) {
        cache.updateQuery(
          { query: WizardRunQry, variables: wizardRunQueries[0].arguments },
          ({ wizardRun }) => ({
            wizardRun: {
              ...wizardRun,
              currentQuestionnaire: responseData.wizardStepQuestionnaire,
            },
          }),
        );
      } else {
        console.warn(
          "It shouldn't appear, but if it does some additional filtering should be added for wizardStepQuestionnaire query handler",
        );
      }
    },
  },
  mutations: {
    wizardStep(result, args, cache) {
      if (result.wizardStep) {
        const cacheUpdateArgs = getWizardRunCacheUpdateArguments({
          cache,
          args,
        });
        cache.updateQuery(
          {
            query: WizardRunQry,
            variables: cacheUpdateArgs.arguments,
          },
          () => ({
            wizardRun: {
              ...result.wizardStep,
            },
          }),
        );
      }
    },
    updateWizardStepQuestionnaire(result, args, cache) {
      if (result.updateWizardStepQuestionnaire) {
        const { updateWizardStepQuestionnaire } = result;

        const updateEntry = (entity) => {
          cache.updateQuery(
            {
              query: WizardRunQry,
              variables: entity.arguments,
            },
            (data) => {
              if (
                !data ||
                data.wizardRun?.nodeId !== args.nodeId ||
                data.wizardRun?.step !== args.step
              ) {
                return null;
              }
              const { wizardRun } = data;
              const updatedQuestionnaireList = wizardRun.questionnaireLists.map(
                (q) =>
                  q.id === updateWizardStepQuestionnaire.id
                    ? { ...q, title: updateWizardStepQuestionnaire.title }
                    : q,
              );

              return {
                wizardRun: {
                  ...wizardRun,
                  availableSteps: wizardRun.step,
                  currentQuestionnaire: updateWizardStepQuestionnaire,
                  questionnaireLists: updatedQuestionnaireList,
                },
              };
            },
          );
        };

        updateEntry(getWizardRunCacheUpdateArguments({ cache, args }));
      }
    },
    createWizardStepQuestionnaire(result, args, cache) {
      if (result.createWizardStepQuestionnaire) {
        const { createWizardStepQuestionnaire } = result;

        const updateEntry = (entity) => {
          cache.updateQuery(
            {
              query: WizardRunQry,
              variables: entity.arguments,
            },
            (data) => {
              if (
                !data ||
                data.wizardRun?.nodeId !== args.nodeId ||
                data.wizardRun?.step !== args.step
              ) {
                return null;
              }
              const { wizardRun } = data;

              return {
                wizardRun: {
                  ...wizardRun,
                  availableSteps: wizardRun.step,
                  currentQuestionnaire: createWizardStepQuestionnaire,
                  questionnaireLists: [
                    ...wizardRun.questionnaireLists,
                    createWizardStepQuestionnaire,
                  ],
                },
              };
            },
          );
        };

        updateEntry(getWizardRunCacheUpdateArguments({ cache, args }));
      }
    },
    // deleteWizardStepQuestionnaire - removed sideEffect for zone delete action, since we don't have enough data in the storage and result to update it properly (when set "currentQuestionnaire" we don't have its questions)
    // TODO: this side-effect likely will be replaced. Please take a look at link that Tosia posted https://dev.azure.com/DEVLY-IT-Software-Development/TROX-EB-Website_v2/_workitems/edit/15567
    editTopologyConfiguration(result, _args, cache) {
      if (result.editTopologyConfiguration) {
        const wizardRunQueries = cache
          .inspectFields({ __typename: "Query" })
          .filter((item) => item.fieldName === "wizardRun");
        if (wizardRunQueries.length === 1) {
          cache.updateQuery(
            { query: WizardRunQry, variables: wizardRunQueries[0].arguments },
            ({ wizardRun }) => ({
              wizardRun: {
                ...wizardRun,
                topology: result.editTopologyConfiguration.topology,
              },
            }),
          );
        } else {
          console.warn(
            "It shouldn't appear, but if it does some additional filtering should be added for wizardStepQuestionnaire query handler",
          );
        }
      }
    },

    copyTopologyNodeConfiguration(result, _args, cache) {
      if (result.copyTopologyNodeConfiguration) {
        const wizardRunQueries = cache
          .inspectFields({ __typename: "Query" })
          .filter((item) => item.fieldName === "wizardRun");
        if (wizardRunQueries.length === 1) {
          cache.updateQuery(
            {
              query: WizardRunQry,
              variables: wizardRunQueries[0].arguments,
            },
            ({ wizardRun }) => ({
              wizardRun: {
                ...wizardRun,
                topology: result.copyTopologyNodeConfiguration.topology,
              },
            }),
          );
        } else {
          console.warn(
            "It shouldn't appear, but if it does some additional filtering should be added for wizardStepQuestionnaire query handler",
          );
        }
      }
    },
    pasteTopologyNodeConfiguration(result, _args, cache) {
      if (result.pasteTopologyNodeConfiguration) {
        const wizardRunQueries = cache
          .inspectFields({ __typename: "Query" })
          .filter((item) => item.fieldName === "wizardRun");
        if (wizardRunQueries.length === 1) {
          cache.updateQuery(
            {
              query: WizardRunQry,
              variables: wizardRunQueries[0].arguments,
            },
            ({ wizardRun }) => ({
              wizardRun: {
                ...wizardRun,
                topology: result.pasteTopologyNodeConfiguration.topology,
              },
            }),
          );
        } else {
          console.warn(
            "It shouldn't appear, but if it does some additional filtering should be added for wizardStepQuestionnaire query handler",
          );
        }
      }
    },
    duplicateWizardStepQuestionnaire(result, args, cache) {
      if (result.duplicateWizardStepQuestionnaire) {
        const { duplicateWizardStepQuestionnaire } = result;
        const updateEntry = (entity) => {
          cache.updateQuery(
            {
              query: WizardRunQry,
              variables: entity.arguments,
            },
            (data) => {
              if (
                !data ||
                data.wizardRun?.nodeId !== args.nodeId ||
                data.wizardRun?.step !== args.step
              ) {
                return null;
              }
              const { wizardRun } = data;
              const duplicatedIdx = wizardRun.questionnaireLists.findIndex(
                (item) => item.id === args.id,
              );
              const newQuestionaires = insertItemAndUpdatePositions(
                wizardRun.questionnaireLists,
                duplicateWizardStepQuestionnaire,
                duplicatedIdx + 1,
              );

              return {
                wizardRun: {
                  ...wizardRun,
                  questionnaireLists: newQuestionaires,
                  currentQuestionnaire: {
                    ...wizardRun.currentQuestionnaire,
                    id: duplicateWizardStepQuestionnaire.id,
                    position: duplicateWizardStepQuestionnaire.position,
                  },
                },
              };
            },
          );
        };
        updateEntry(getWizardRunCacheUpdateArguments({ cache, args }));
      }
    },
    invokeTopologyAction(result, _args, cache) {
      if (result.invokeTopologyAction) {
        const wizardRunQueries = cache
          .inspectFields({ __typename: "Query" })
          .filter((item) => item.fieldName === "wizardRun");
        if (wizardRunQueries.length === 1) {
          cache.updateQuery(
            {
              query: WizardRunQry,
              variables: wizardRunQueries[0].arguments,
            },
            ({ wizardRun }) => ({
              wizardRun: {
                ...wizardRun,
                topology: result.invokeTopologyAction.topology,
              },
            }),
          );
        } else {
          console.warn(
            "It shouldn't appear, but if it does some additional filtering should be added for wizardStepQuestionnaire query handler",
          );
        }
      }
    },
  },
};
