import React from "react";
import * as Scrivito from "scrivito";
import { getHomepage, isConfiguratorNavVisible } from "utils";
import { NavStandard, NavConfigurator } from "./Navigation/index";

export const Navigation = Scrivito.connect(() => {
  const homepage = getHomepage();
  if (!homepage) {
    return null;
  }

  const currentPage = Scrivito.currentPage();

  return (
    <>
      {isConfiguratorNavVisible(currentPage) ? (
        <NavConfigurator homepage={homepage} />
      ) : (
        <NavStandard homepage={homepage} />
      )}
    </>
  );
});
