import React, { useCallback } from "react";
import { Multiselect } from "./Multiselect";

export const MultiselectField = React.memo(
  ({ title, options, attribute, optionsSelected, handleChange }) => {
    const handleChangeWithAttribute = useCallback(
      (values) => {
        handleChange(values, attribute);
      },
      [attribute, handleChange],
    );

    return (
      <div key={`multiselect-${attribute}`} data-testid="multiselect-fields">
        <div className="scrivito_detail_label">
          <span>{title}</span>
        </div>
        <div data-testid={attribute}>
          <Multiselect
            data-options-selected={optionsSelected}
            options={options}
            optionsSelected={optionsSelected}
            handleChange={handleChangeWithAttribute}
            attribute={attribute}
          />
        </div>
      </div>
    );
  },
);
