import { useEffect } from "react";
import { toast } from "react-toastify";

export const useToastCleanup = (toastRef) => {
  useEffect(
    () => () => {
      toast.dismiss(toastRef.current);
    },
    [toastRef],
  );
};
