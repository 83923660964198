import React, { useEffect, useState } from "react";
import * as Scrivito from "scrivito";
import { prepareNavigationItems } from "utils";
import { UpperNavigation } from "./UpperNavigation";
import { MainNavigation } from "./MainNavigation";
import { Logo } from "../Logo";
import { LoginButton } from "../LoginButton";
import { SearchField } from "./SearchField";

export const NavStandard = Scrivito.connect(({ homepage }) => {
  useEffect(() => {
    const fetchData = async () => {
      try {
        const navSerializedParsed = await Scrivito.load(() =>
          prepareNavigationItems(),
        );
        setIsNavItemsFetched(true);
        setMainNavItems(navSerializedParsed);
      } catch (err) {
        setIsFetchNavItemsErr(true);
      }
    };
    fetchData();
  }, []);

  const [mainNavItems, setMainNavItems] = useState([]);
  const [isNavItemsFetched, setIsNavItemsFetched] = useState(false);
  const [isFetchNavItemsErr, setIsFetchNavItemsErr] = useState(false);

  if (!homepage) {
    return null;
  }

  return (
    <header
      data-testid="nav-standard"
      className="position-relative d-flex align-items-lg-center px-4 py-3 navigation container-fluid"
    >
      <Logo
        homepage={homepage}
        linkClass="d-lg-flex flex-shrink-lg-0 p-0 me-3 navbar-brand nav-logo-link"
        imgClass="w-100 m-0 nav-logo"
      />
      <div className="navs">
        <UpperNavigation homepage={homepage} />
        <MainNavigation
          homepage={homepage}
          mainNavItems={mainNavItems}
          isNavItemsFetched={isNavItemsFetched}
          isFetchNavItemsErr={isFetchNavItemsErr}
        />
      </div>
      <div className="flex-column justify-content-lg-between nav-search-wrapper">
        <div className="me-6 me-lg-0 mb-lg-2">
          <LoginButton />
        </div>
        <div className="d-none d-lg-flex">
          <SearchField />
        </div>
      </div>
    </header>
  );
});
