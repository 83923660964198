import React from "react";
import {
  StyleSheet,
  Document,
  Page,
  Text,
  Image,
  Svg,
  Path,
} from "@react-pdf/renderer";
// import helveticaNeue from "assets/fonts/helvetica/Helvetica Neue LT Com 77 Bold Condensed.ttf";

// Font.register({
//   family: "HelveticaNeueW01-77BdCn 692722",
//   fonts: [
//     {
//       src: helveticaNeue,
//     },
//   ],
// });

const s = StyleSheet.create({
  page: {
    display: "flex",
    flexDirection: "column",
    padding: 12,
  },
  header: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 12,
    borderBottom: "1px solid #8c8e91",
    color: "#8c8e91",
  },
  headerTop: {
    display: "flex",
    flexDirection: "row",
    alignItems: "end",
    paddingBottom: 2,
  },
  logo: {
    width: 49,
    height: 13,
    objectFit: "contain",
    marginRight: 5,
  },
  textConfigurator: {
    marginTop: 4,
    // fontFamily: "HelveticaNeueW01-77BdCn 692722",
    fontSize: 12,
    fontWeight: "semibold",
    lineHeight: 0,
  },
  headerBottom: {
    display: "flex",
    flexDirection: "row",
    alignItems: "end",
    paddingBottom: 8,
  },
  folderIcon: {
    width: 10,
    height: 10,
    marginRight: 5,
  },
  title: {
    fontWeight: "bold",
    fontSize: 8,
    margin: 0,
    padding: 0,
  },
  topologyWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    alignItems: "start",
  },
  topology: {
    maxHeight: 510,
    objectFit: "contain",
  },
});

export const TemplatePDF = ({ title, textConfigurator, topologyImg }) => (
  <Document>
    <Page orientation="landscape" style={s.page}>
      <div style={s.header}>
        <div style={s.headerTop}>
          <Svg
            style={s.logo}
            width="126"
            height="32"
            viewBox="0 0 126 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <Path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M68.775 16.002c0-4.98 3.891-9.015 8.69-9.015 4.8 0 8.69 4.035 8.69 9.014 0 4.98-3.906 9.015-8.69 9.015-4.784 0-8.69-4.035-8.69-9.014zm-7.69 0C61.085 7.164 68.417 0 77.465 0c9.048 0 16.38 7.164 16.38 16.002 0 8.837-7.332 15.998-16.38 15.998-9.048 0-16.38-7.164-16.38-15.998z"
              fill="#004395"
            />
            <Path
              d="M25.362 1.139v6.118h-8.714s0 23.97-.021 24.476h-7.75s-.03-22.21 0-24.473H0V1.139h25.362zM102.578 31.52h-7.933L116.037.925h7.933l-21.392 30.593zM117.425 31.522h7.94l-.869-1.22-7.936.003.865 1.217zM96.616 2.314l7.924-.003-.949-1.34h-7.934l.959 1.343zM98.765 5.322l7.927.012-.947-1.35-7.933-.002.953 1.34zM101 8.462h7.93l-.958-1.35-7.93.01.958 1.34zM103.23 11.6h3.864l.98-1.395-5.848-.01 1.004 1.404zM115.151 28.33l7.948-.002-.863-1.208h-7.951l.866 1.21zM113.035 25.35h7.918l-.883-1.211h-7.9l.865 1.211zM110.839 22.303l7.948.003-.875-1.235-6.22.006-.853 1.226zM112.822 19.352h3.861l-.835-1.196h-2.173l-.853 1.196zM105.183 14.337l.745-1.076h-1.517l.772 1.076zM57.428 31.802l-8.681-.021-9.526-12.82-.018 12.772h-7.66V1.013h12.603c1.605 0 11.076.25 11.269 9.95.15 7.737-5.41 9.128-6.57 9.315l8.583 11.524zM39.203 15.827h3.951s4.77.3 4.616-4.351c-.141-4.261-4.186-4.03-4.874-4.03h-3.693v8.381z"
              fill="#004395"
            />
          </Svg>
          <Text style={s.textConfigurator}>{textConfigurator}</Text>
        </div>
        <div style={s.headerBottom}>
          <Svg
            style={s.folderIcon}
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <Path
              d="M13.29 3H7.31V1.75c0-.41-.34-.75-.75-.75H1.75c-.41 0-.75.34-.75.75V13.3c0 .94.76 1.7 1.7 1.7h10.6c.94 0 1.7-.76 1.7-1.7V4.75C15 3.79 14.23 3 13.29 3zM2.5 2.5h3.31v1.25c0 .41.33.75.75.75h6.73c.12 0 .21.11.21.25v.5h-11V2.5zm11 10.8a.2.2 0 0 1-.2.2H2.7a.2.2 0 0 1-.2-.2V6.75h11v6.55z"
              fill="#8c8e91"
            />
          </Svg>
          <Text style={s.title}>{title}</Text>
        </div>
      </div>
      {topologyImg && <Image style={s.topology} src={topologyImg} />}
    </Page>
  </Document>
);
