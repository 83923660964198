import * as Scrivito from "scrivito";
import { getRubyTenantVersion } from "./filterByTenant";
import { linkHostname } from "./getTenantSettings";

function isBlobType(obj) {
  const blobTypes = ["ContactImage", "Document", "Image", "VideoVimeo"];
  return blobTypes.includes(obj.objClass());
}

// if needed, it replaces Scrivito link and navigates to its Ruby site equivalent
export const getPageLink = (link) => {
  if (!link) {
    return console.error("link parameter is required");
  }
  if (
    !(link instanceof Scrivito.Link) &&
    !link?.get("isJSSiteReady") &&
    !isBlobType(link)
  ) {
    return new Scrivito.Link({
      url: `https://${getRubyTenantVersion()}/${link.id()}`,
    });
  }

  if (
    link instanceof Scrivito.Link &&
    link.url()?.includes(linkHostname()) &&
    !link.isInternal()
  ) {
    return new Scrivito.Link({
      url: link.url()?.replace(linkHostname(), getRubyTenantVersion()),
    });
  }

  if (
    link instanceof Scrivito.Link &&
    link.isInternal() &&
    link.obj() &&
    !link.obj().get("isJSSiteReady") &&
    !isBlobType(link.obj())
  ) {
    return new Scrivito.Link({
      url: `https://${getRubyTenantVersion()}/${link.obj().id()}`,
    });
  }
  return link;
};
