import React, { useState, useCallback } from "react";
import { Input } from "reactstrap";

import { Button } from "components/Button";

export const EditableTitle = ({ field }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const handleToggleEditMode = useCallback(() => {
    setIsEditMode((val) => !val);
  }, []);

  return (
    <div className="d-flex gap-2 align-items-center">
      {isEditMode ? (
        <Input
          {...field}
          type="text"
          bsSize="sm"
          className="h5 mb-0"
          onBlur={handleToggleEditMode}
        />
      ) : (
        <span onClick={handleToggleEditMode} className="h5 mb-0">
          {field.value}
        </span>
      )}
      <Button
        color="tertiary"
        type="button"
        iconPrefix="trox"
        icon="pencil-light"
        iconClassName="icon-16"
        classNames="p-0 cursor-pointer mb-1 border-0"
        onClick={handleToggleEditMode}
      />
    </div>
  );
};
