import React, { useCallback } from "react";
import * as Scrivito from "scrivito";
import cx from "classnames";
import { Icon } from "./Icon/Icon";

export const LinkButtonWithChevron = Scrivito.connect(
  ({ children, disabled, className, ...rest }) => {
    const handleClick = useCallback(() => {
      !disabled && rest.onClick && rest.onClick();
    }, [disabled, rest]);

    return (
      <Scrivito.LinkTag
        className={cx(
          "px-4 py-3 d-flex justify-content-between align-items-center gap-2 text-nowrap text-decoration-none",
          className,
        )}
        onClick={handleClick}
        {...rest}
      >
        {children}
        <Icon
          className="d-flex align-items-center"
          prefix="trox"
          iconName="chevron-right-regular"
        />
      </Scrivito.LinkTag>
    );
  },
);
