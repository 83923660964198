import React from "react";
import * as Scrivito from "scrivito";
import { Field, Form, Formik } from "formik";
import { ModalBody, ModalFooter } from "reactstrap";
import { translate, getTranslationTextForEditMode } from "utils";
import { Button } from "components/Button";
import { TranslateWrapper } from "components/TranslateWrapper";
import { FormSelect, TextInput } from "components/Inputs";
import cx from "classnames";
import s from "./ProjectDetailsModal.module.scss";

export const ProjectDetailsForm = Scrivito.connect(
  ({
    handleSubmit,
    initialValues,
    isUpdate,
    countriesOptions,
    optionsList,
    unitOptions,
    validationSchema,
  }) => (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
      validateOnBlur={false}
    >
      <Form>
        <ModalBody className="pt-2_5 d-flex row">
          <div className="col-12 col-lg-6">
            <Field
              component={TextInput}
              name="title"
              autoFocus={true}
              data-recording-disable={true}
              label={translate("PROJECT_NAME_WITH_ASTERISK")}
              data-testid="project-name-field"
            />
            <Field
              component={TextInput}
              name="constructionTitle"
              wrapperClassName="mt-3"
              autoFocus={true}
              data-recording-disable={true}
              label={translate("PROJECT_CONSTRUCTION_TITLE_INPUT_LABEL")}
              tooltipText={translate("PROJECT_CONSTRUCTION_TITLE_TOOLTIP_TEXT")}
            />
            <Field
              component={FormSelect}
              name="country"
              wrapperClassName={cx(s.fieldWithoutMarginBottom, "mt-3")}
              autoFocus={true}
              data-recording-disable={true}
              label={translate("PROJECT_LOCATION_WITH_ASTERISK")}
              tooltipText={translate("PROJECT_LOCATION_SELECT_TOOLTIP_TEXT")}
              options={countriesOptions}
            />
          </div>
          <div className="col-12 col-lg-6 flex-grow-1">
            <Field
              id="project-description-input"
              component={TextInput}
              type="textarea"
              name="description"
              autoFocus={true}
              data-recording-disable={true}
              placeholder={getTranslationTextForEditMode(
                "PROJECT_DESCRIPTION_INPUT_PLACEHOLDER",
              )}
              label={translate("PROJECT_DESCRIPTION_INPUT_LABEL")}
              wrapperClassName={cx(
                s.fieldWithoutMarginBottom,
                "h-100 mb-3 pb-2 d-flex flex-column",
              )}
              maxLength={500}
              className="d-flex flex-grow-1"
            />
          </div>
          {typeof translate("PROJECT_DESCRIPTION_INPUT_PLACEHOLDER") ===
            "object" && (
            <TranslateWrapper
              tooltipId="project-description-input"
              tooltipText={
                translate("PROJECT_DESCRIPTION_INPUT_PLACEHOLDER").props
                  .tooltipText
              }
            />
          )}
          <div className="col-12 col-lg-6 flex-grow-1">
            <Field
              component={FormSelect}
              name="units"
              wrapperClassName="mt-3"
              autoFocus={true}
              data-recording-disable={true}
              label={translate("PROJECT_UNITS_WITH_ASTERISK")}
              tooltipText={translate("PROJECT_UNITS_SELECT_TOOLTIP_TEXT")}
              options={unitOptions}
            />
            <Field
              component={TextInput}
              name="projectNumber"
              wrapperClassName="mt-3"
              autoFocus={true}
              data-recording-disable={true}
              label={translate("PROJECT_NUMBER")}
              tooltipText={translate("PROJECT_NUMBER_TOOLTIP_TEXT")}
            />
          </div>
          <div className="col-12 col-lg-6 flex-grow-1">
            <Field
              component={FormSelect}
              name="status"
              wrapperClassName="mt-3"
              autoFocus={true}
              data-recording-disable={true}
              label={translate("PROJECT_STATUS")}
              tooltipText={translate("PROJECT_STATUS_SELECT_TOOLTIP_TEXT")}
              options={optionsList}
            />
            <div className="d-flex justify-content-end mt-4">
              {translate("REQUIRED_FIELD_WITH_ASTERISK")}
            </div>
          </div>
        </ModalBody>
        <ModalFooter className="pt-0">
          <Button
            data-testid="ProjectDetailsForm_submit-btn"
            color="primary"
            type="submit"
          >
            {isUpdate
              ? translate("TEXT_SAVE_CHANGES")
              : translate("PROJECT_MODAL_CREATE_BUTTON_TEXT")}
          </Button>
        </ModalFooter>
      </Form>
    </Formik>
  ),
);
