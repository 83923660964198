import React, { useCallback, useMemo } from "react";
import { Field, Form, Formik } from "formik";
import * as Scrivito from "scrivito";
import NiceModal from "@ebay/nice-modal-react";
import { Modal, ModalBody, ModalFooter, ModalHeader, Alert } from "reactstrap";
import { Button } from "components/Button";
import { CheckboxInput } from "components/Inputs/FormikInputs/CheckboxInput";
import { TextInput, FormSelect } from "components/Inputs";
import { translate, getHomepage } from "utils";

const initialValues = {
  region: "",
  email: "",
  dataCheck: false,
};

export const OfferProjectModal = NiceModal.create(
  Scrivito.connect(({ project }) => {
    const modal = NiceModal.useModal();

    const emailOptions = useMemo(() => {
      const emails = getHomepage().get("commercialOfferLinks") || [];
      return emails.map((email) => ({
        label: email.title(),
        value: email.url(),
      }));
    }, []);

    const handleSubmit = useCallback((values) => {
      // eslint-disable-next-line no-console
      console.log("Submitted", { values });
    }, []);

    return (
      <Modal
        isOpen={modal.visible}
        centered={true}
        size="md"
        toggle={modal.hide}
        className="px-4"
      >
        <Formik
          initialValues={initialValues}
          enableReinitialize
          onSubmit={handleSubmit}
        >
          <Form>
            <ModalHeader
              toggle={modal.hide}
              tag="div"
              className="pb-2_5 px-3_5"
            >
              <h3 className="ms-0 text-uppercase mb-0">
                {translate("PROJECT_REQUEST_OFFER")}
              </h3>
              <h4 className="ms-0 mb-0">{project.title}</h4>
            </ModalHeader>
            <ModalBody className="d-flex row w-100 ms-0 py-2 px-1">
              <Alert color="warning" className="mt-3 w-100">
                {translate("PROJECT_OFFER_HINT")}
              </Alert>
              <div className="col-12 mt-2">
                <Field
                  component={FormSelect}
                  name="region"
                  wrapperClassName="mt-3"
                  label={translate("PROJECT_BRANCH_LABEL")}
                  options={emailOptions}
                  placeholder="Chose region"
                />
              </div>
              <div className="col-12 mt-2">
                <Field
                  component={TextInput}
                  name="email"
                  type="textarea"
                  autoFocus={true}
                  label={translate("PROJECT_OFFER_EMAIL_LABEL")}
                  data-recording-disable={true}
                  placeholder=""
                  wrapperClassName="h-100 mb-3 pb-2 d-flex flex-column"
                  maxLength={900}
                />
              </div>
              <div className="d-flex col-12 mt-2 align-items-center gap-2">
                <Field
                  component={CheckboxInput}
                  type="checkbox"
                  name="dataCheck"
                  label={translate("PROJECT_DATA_CHECK_LABEL")}
                  classNames="h-100  pb-2 d-flex w-100 align-items-center"
                />
              </div>
            </ModalBody>
            <ModalFooter className="py-1">
              <Button color="outline-dark" type="button" onClick={modal.hide}>
                {translate("CANCEL")}
              </Button>
              <Button color="primary" type="submit">
                {translate("PROJECT_SEND_REQUEST")}
              </Button>
            </ModalFooter>
          </Form>
        </Formik>
      </Modal>
    );
  }),
);
