import { useMemo } from "react";

export const useVariables = (projectId, nodeIdVar, stepVar, lang, subType) =>
  useMemo(
    () =>
      Object.fromEntries(
        Object.entries({
          projectId: nodeIdVar ? null : projectId || null,
          nodeId: nodeIdVar,
          step: nodeIdVar ? stepVar : null,
          subType: subType || null,
          language: lang,
        }).filter(([_, v]) => v != null),
      ),
    [nodeIdVar, stepVar, lang, subType, projectId],
  );
