import React from "react";
import cx from "classnames";
import { TopologyItemPDF } from "./TopologyItemPDF";

import s from "./Preview.module.scss";

export const CMpdf = ({
  kind,
  wrapperClassNames,
  rightPlacedChildren,
  isCBModule,
  withConnectionToBottomPlacedItem,
  ...restProps
}) => (
  <div
    className={cx("d-flex flex-column position-relative", wrapperClassNames)}
  >
    <div
      className={cx("d-flex align-items-start position-relative pt-4", {
        [s.moduleWithNeighbor]: withConnectionToBottomPlacedItem,
      })}
    >
      <TopologyItemPDF kind={kind} isSBModule={isCBModule} {...restProps} />
      <div className="position-relative">
        <div
          className={cx("d-flex flex-column ms-6", {
            [s.lessThanFourRightChildren]: rightPlacedChildren?.length <= 4,
            [s.moreThanFourRightChildren]: rightPlacedChildren?.length > 4,
          })}
        >
          {rightPlacedChildren?.map((item) => (
            <TopologyItemPDF key={item.id} {...item} />
          ))}
        </div>
      </div>
    </div>
  </div>
);
