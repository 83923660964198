export const projectTranslations = {
  ADDITIONAL_NOTES: "Additional Notes",
  ADD_TO_PROJECT: "Add to Project",
  EXISTING_PROJECTS: "Existing Projects",
  CREATE_A_NEW_PROJECT: "Create a new project",
  PLEASE_CHOOSE_PROJECT: "Please choose a Project for using the WIZARD",
  PLEASE_CHOOSE_PROJECT_TEXT:
    "Please choose an existing project or create a new one to associate with the wizard. Selecting a project determines where the wizard will be added and configured.",
  CLEAR_SELECTION: "Clear Selection",
  DATA_FORMAT: "Data Format",
  DOCUMENT_TYPE: "Document Type",
  DOWNLOAD_DATA: "Download data",
  DOWNLOAD_LIST: "Download list",
  DOWNLOAD_OPTIONS: "Download options",
  EDIT_PROPERTIES: "Edit Properties",
  MODAL_EDIT_PROPERTIES: "Modal Edit Properties",
  PRESENTATION_ORDER_CODE: "Order Code",
  ORDER_CODE: "Order Code",
  PRICE_GROUP: "Price Group",
  PRODUCT_NAME: "Product Name",
  PROJECT_CONSTRUCTION_TITLE_INPUT_LABEL: "Name of Construction Project",
  PROJECT_CONSTRUCTION_TITLE_TOOLTIP_TEXT:
    "Lorem ipsum dolor sit amet consetetur sadipscin diamon nonumy eirmod tempo.",
  PROJECT_DESCRIPTION_INPUT_LABEL: "Additional Notes",
  PROJECT_DESCRIPTION_INPUT_PLACEHOLDER: "Additional Notes",
  PROJECT_DETAILS: "Project Details",
  PROJECT_EDITED_BY: "Edited by {{name}}",
  PROJECT_ID: "ID",
  PROJECT_ITEM_ADD_TO_CART: "Add to Cart",
  PROJECT_ITEM_DELETE_MODAL_BODY_TEXT: "Are you sure you want to delete",
  PROJECT_ITEM_DELETE_MODAL_BODY_PLURAL_WITH_TITLE_PARAM:
    "Are you sure you want to delete {{text}} element with all attached elements?",
  PROJECT_ITEM_DELETE_MODAL_BODY_SINGULAR_WITH_TITLE_PARAM:
    "Are you sure you want to delete {{text}}?",
  PROJECT_ITEM_DELETE_MODAL_BODY_TEXT_CAUTION: "This action cannot be undone.",
  PROJECT_ITEM_DELETE_MODAL_BODY_TEXT_PLURAL: "and all attached elements?",
  PROJECT_ITEM_DELETE_MODAL_SUBMIT_BTN_TEXT: "Delete Element",
  PROJECT_ITEM_DELETE_MODAL_SUBMIT_BTN_TEXT_PLURAL: "Delete Elements",
  PROJECT_ITEM_DELETE_MODAL_TITLE: "Delete this Element?",
  PROJECT_ITEM_DELETE_MODAL_TITLE_PLURAL: "Delete these Elements?",
  PROJECT_ITEM_EDIT_CONFIGURATION: "Edit configuration",
  PROJECT_ITEM_ID: "Project ID",
  PROJECT_ITEM_REQUEST_OFFER: "Request Offer",
  PROJECT_ITEM_UPLOADS_ADDED_ON: "Added on",
  PROJECT_ITEM_UPLOADS_FILE_TYPE: "File Type",
  PROJECT_ITEM_UPLOADS_NAME: "Name",
  PROJECT_UPLOADS_EMPTY: "No Uploads available",
  PROJECT_LIST_LAST_MODIFICATION: "Last Modification",
  PROJECT_LIST_NO_DATA: "No data",
  PROJECT_LIST_OF: "of",
  PROJECT_LIST_PROJECT: "Project",
  PROJECT_LIST_SHOWING: "Showing",
  PROJECT_LIST_STATUS: "Status",
  PROJECT_LIST_DELETE_PROJECTS: "Delete Project(s)",
  PROJECT_LOCATION: "Project Location",
  PROJECT_LOCATION_WITH_ASTERISK: "Project Location*",
  PROJECT_LOCATION_SELECT_TOOLTIP_TEXT:
    "Lorem ipsum dolor sit amet consetetur sadipscin diamon nonumy eirmod tempo.",
  PROJECT_LOCATION_SELECT_VALIDATION_EMPTY: "Project Location is required",
  PROJECT_MODAL_CREATE_BUTTON_TEXT: "Create Project",
  PROJECT_MODAL_CREATE_SUB_TITLE: "Please define the Project Properties",
  PROJECT_MODAL_DELETE_TEXT: "Delete Project",
  PROJECT_MODAL_DELETE_TEXT_WITH_TITLE: "Delete Project: {{projectTitle}}?",
  PROJECT_MODAL_DELETE_WARNING_TEXT_WITH_TITLE:
    "Are you sure you want to delete Project: {{projectTitle}}?",
  MULPIPLE_PROJECTS_MODAL_DELETE_WARNING_TEXT:
    "Are you sure you want to delete selected Project(s)?",
  PROJECT_NAME: "Project Name",
  PROJECT_NAME_WITH_ASTERISK: "Project Name*",
  PROJECT_NAME_VALIDATION_EMPTY: "Project name is required",
  PROJECT_NAME_VALIDATION_SHORT:
    "Project name should have more then 3 characters",
  PROJECT_NAME_VALIDATION_LONG:
    "Project name should have less then 100 characters. Current length is",
  PROJECT_NODE_ADD_LAYER: "Add layer",
  PROJECT_NODE_PASTE: "Paste here",
  PROJECT_NUMBER: "Project Number",
  PROJECT_NUMBER_TOOLTIP_TEXT:
    "Lorem ipsum dolor sit amet consetetur sadipscin diamon nonumy eirmod tempo.",
  PROJECT_OPEN_WIZARD: "Open Wizard",
  PROJECT_PRODUCT_COMPACT_LIST: "Compact List",
  PROJECT_PRODUCT_EXTENDED_LIST: "Extended list to download",
  PROJECT_PRODUCT_LIST: "Product List",
  PROJECT_PROPERTIES: "Project Properties",
  PROJECT_SHARING_SETTINGS: "Sharing settings",
  PROJECT_STATUS: "Project Status",
  PROJECT_STATUS_ARCHIVED: "Archived",
  PROJECT_STATUS_COMPLETED: "Completed",
  PROJECT_STATUS_DETAILED_PLANNING: "Detailed Planning",
  PROJECT_STATUS_DRAFT: "Draft",
  PROJECT_STATUS_PLANNING: "Planning",
  PROJECT_STATUS_SELECT_TOOLTIP_TEXT:
    "Lorem ipsum dolor sit amet consetetur sadipscin diamon nonumy eirmod tempo.",
  PROJECT_STATUS_UPDATE: "Update status",
  PROJECT_SUMMARY: "Project Summary",
  PROJECT_SUMMARY_TITLE: "Project Summary",
  PROJECT_SUMMARY_SIDEBAR_MY_STRUCTURE: "My Structure",
  PROJECT_UNITS: "Units",
  PROJECT_UNITS_WITH_ASTERISK: "Units*",
  PROJECT_UNITS_SELECT_TOOLTIP_TEXT:
    "Lorem ipsum dolor sit amet consetetur sadipscin diamon nonumy eirmod tempo.",
  PROJECT_UNITS_VALIDATION_EMPTY: "Units is required",
  PROJECT_INFORMATION: "Project Information",
  REQUIRED_FIELD_WITH_ASTERISK: "Required Field*",
  PROJECT_UPLOADS_TITLE: "Uploads",
  PROJECT_UPLOAD_DATA: "Upload Data",
  QUANTITY: "Quantity",
  SELECT_ALL: "Select All",
  SHOW_DETAILS: "Show Details",
  SORTED_BY: "Sorted by",
  TOTAL_PRICE: "Total Price",
  UNIT_PRICE: "Unit Price",
  NO_PRICE: "On request",
  TOTAL_PRICE_ATTENTION:
    'Attention: Total price does not include items with missing prices - see "On Request"',
  PROJECT_UNITS_DE_M3PH: "SI units with m³/h",
  PROJECT_UNITS_DE_LPS: "SI units with l/s",
  PROJECT_UNITS_DE_US: "Imperial/USA units",
  ALL_PROJECTS: "All Projects",
  WELCOME_PAGE_BODY_TEXT_PART1:
    "Welcome to your web-based configurator for the comprehensive planning and sizing of projects with TROX components and systems. The continuous development of this planning environment enables you to plan and implement your projects easily, clearly and collaboratively. All in a uniform project environment.",
  WELCOME_PAGE_BODY_TEXT_PART2:
    "Start now - and discover the TROX CONFIGURATOR.",
  WELCOME_PAGE_TITLE: "Better planning with TROX",
  NEW_PROJECT_BUTTON_TEXT: "New Project",
  LATEST_UPDATED_PROJECTS: "Latest updated Projects",
  PROJECT_COUNT_PLURAL: "saved Projects",
  PROJECT_COUNT_SINGLE: "saved Project",
  PROJECT_SHARE_TITLE: "Share this project",
  PROJECT_COPY_LABEL: "Share as a copy",
  PROJECT_COPY_TEXT:
    "The project will be shared as a copy.Changes in the project copy by the recipient does not have any effect to the original project.",
  PROJECT_SHARE_EMAIL:
    "E-mail address that matches the recipient's myTROX account",
  PROJECT_NOTES_LABEL: "Add Notes to the Recipient",
  PROJECT_EMAIL_LABEL: "Invite by E-Mail",
  PROJECT_REQUEST_OFFER: "Request Offer",
  PROJECT_REPORT: "Project Report",
  PRODUCT_LIST: "Product List",
  PROJECT_OFFER_HINT:
    "This action triggers the creation of an official offer. After Information from TROX sales will process the request. The requested offer refers to the project status at the time of the request. Subsequent adjustments to the project cannot therefore be taken into account. Creation and transmission may take some time. If you have any questions, please contact your responsible sales representative.",
  PROJECT_BRANCH_LABEL: "Contacted Branch Office",
  PROJECT_OFFER_EMAIL_LABEL: "Additional message",
  PROJECT_DATA_CHECK_LABEL:
    "I agree to the processing of my data in accordance with the TROX data protection guidelines",
  PROJECT_SEND_REQUEST: "Send request",
  PROJECT_STRUCTURE_SHOW_PRODUCT_PICTURES: "Show Product Pictures",
  PROJECT_SHARE_RECIPIENT_EMPTY: "Recipient email is required",
  PROJECT_NEW_NODE: "New Sub Level",
  PROJECT_SUBTITLE_RENAME: "Rename",
  PROJECT_SHARE_ERROR: "The account with the given id was not found",
};
