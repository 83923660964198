import { translate } from "utils";

export const projectTreeContentTranlations = () => ({
  folder: {
    submitBtnTextNoChild: translate(
      "PROJECT_ITEM_DELETE_MODAL_SUBMIT_BTN_TEXT",
    ),
    submitBtnTextWithChild: translate(
      "PROJECT_ITEM_DELETE_MODAL_SUBMIT_BTN_TEXT_PLURAL",
    ),
    addLayer: translate("PROJECT_NODE_ADD_LAYER"),
    nodePaste: translate("PROJECT_NODE_PASTE"),
    titleWithChild: translate("PROJECT_ITEM_DELETE_MODAL_TITLE_PLURAL"),
    titleNoChild: translate("PROJECT_ITEM_DELETE_MODAL_TITLE"),
    getDeleteModalBodyPluralWithTitleParam: (params) =>
      translate(
        "PROJECT_ITEM_DELETE_MODAL_BODY_PLURAL_WITH_TITLE_PARAM",
        params,
      ),
    getDeleteModalBodySingularWithTitleParam: (params) =>
      translate(
        "PROJECT_ITEM_DELETE_MODAL_BODY_SINGULAR_WITH_TITLE_PARAM",
        params,
      ),
    alertTextWithChild: translate("PROJECT_ITEM_DELETE_MODAL_BODY_TEXT_PLURAL"),
    alertTextCaution: translate("PROJECT_ITEM_DELETE_MODAL_BODY_TEXT_CAUTION"),
  },
  product: {
    submitBtnText: translate("PROJECT_ITEM_DELETE_MODAL_SUBMIT_BTN_TEXT"),
    modalTitle: translate("PROJECT_ITEM_DELETE_MODAL_TITLE"),
    alertText: translate("PROJECT_ITEM_DELETE_MODAL_BODY_TEXT"),
    alertTextCaution: translate("PROJECT_ITEM_DELETE_MODAL_BODY_TEXT_CAUTION"),
    presentationTitle: translate("PRESENTATION_ORDER_CODE"),
    productQuantity: translate("QUANTITY"),
  },
});
