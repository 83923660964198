import React, { useMemo, useRef, useState } from "react";
import * as Scrivito from "scrivito";
import cx from "classnames";
import { useQuery } from "urql";
import { UncontrolledTooltip } from "reactstrap";

import { priceToString } from "utils/priceToString";
import {
  ProductListCalculationMarker,
  ProductListDownloadButton,
  ProductListFilterButton,
  ProductListSearchControl,
  ProductListSizeSwitcher,
} from "components/ProductListOfProject/SubComponents";
import { Icon } from "components/Icon/Icon";
import { TranslateWrapper } from "components/TranslateWrapper";
import { ProjectCommissioningCalcQry, UserProductListQry } from "api/queries";
import { PRODUCTS_PER_PAGE } from "variables";
import { translate, getTranslationTextForEditMode } from "utils";
import { ProductListOfProject } from "./ProductListOfProject";

const getCommissionAsProduct = ({ title, centAmount, currency }) => ({
  title,
  totalPrice: {
    centAmount,
    fractionDigits: 0,
    currencyCode: currency,
  },
  unitPrice: {
    centAmount,
    fractionDigits: 0,
    currencyCode: currency,
  },
});

export const ProductListWithControls = Scrivito.connect(
  ({
    projectId = null,
    nodeId,
    configurationOption = null,
    hasFilter = false,
    hasSearch = false,
    hasSizeSwitcher = false,
    isInModal = false,
  }) => {
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [isOrderDescending, setIsOrderDescending] = useState(true);
    const tableRef = useRef();
    const isCommissionIncluded = useMemo(
      () =>
        configurationOption && configurationOption !== "commissionNotIncluded",
      [configurationOption],
    );

    const [{ data: commissioningData, fetching: commissionFetching }] =
      useQuery({
        query: ProjectCommissioningCalcQry,
        variables: {
          projectId,
          projectNodeId: nodeId,
          configurationOption,
        },
        pause: !projectId || !nodeId || !isCommissionIncluded,
      });

    const [{ data: productListData, fetching: productListFetching }] = useQuery(
      {
        query: UserProductListQry,
        variables: {
          id: projectId,
          productsLimit: PRODUCTS_PER_PAGE,
          projectNodeId: nodeId,
          productsPage: currentPageNo,
          order: isOrderDescending ? "title desc" : "title asc",
        },
        pause: !projectId,
      },
    );

    const { productList, price } = productListData?.project ?? {};
    const {
      products = [],
      productsCount = 0,
      pages: pagesCount = 0,
      productsPrice = null,
    } = productList ?? {}; // "?? {}" because sometimes productList is null

    const commissioningCalculationData =
      commissioningData?.commissioningCalculation?.data || {};
    const {
      price: commissioningPrice = null,
      currency: commissioningCurrency = "",
    } = commissioningCalculationData;

    const commissioningProduct = useMemo(
      () =>
        nodeId && isCommissionIncluded
          ? getCommissionAsProduct({
              title: `${getTranslationTextForEditMode("WIZARD_COMMISSIONING")} 
              ${getTranslationTextForEditMode(
                configurationOption === "RoomControl_PreConf"
                  ? "WIZARD_COMMISSIONING_PRE_CONF"
                  : "WIZARD_COMMISSIONING_ON_SITE_CONF",
              )}
              `,
              centAmount: commissioningPrice,
              currency: commissioningCurrency,
            })
          : null,
      [
        commissioningCurrency,
        commissioningPrice,
        configurationOption,
        isCommissionIncluded,
        nodeId,
      ],
    );

    const totalPrice = useMemo(() => {
      if (price || productsPrice) {
        return priceToString({
          price: nodeId
            ? {
                currencyCode:
                  productsPrice?.currencyCode || price?.currencyCode,
                fractionDigits: productsPrice?.fractionDigits,
                centAmount: productsPrice?.centAmount,
              }
            : {
                currencyCode: price?.currencyCode,
                fractionDigits: price?.fractionDigits,
                centAmount: price?.centAmount,
              },
          noPriceText: null,
          commissioningPrice,
        });
      }
    }, [commissioningPrice, nodeId, price, productsPrice]);

    return (
      <>
        {isInModal && productList?.includesDummyProduct && (
          <ProductListCalculationMarker />
        )}
        <div
          className={cx({ "pb-6 mt-3": !isInModal, "pb-0 mt-1": isInModal })}
        >
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center font-bold">
              {hasFilter && <ProductListFilterButton />}
              {hasSearch && <ProductListSearchControl />}
              {hasSizeSwitcher && <ProductListSizeSwitcher />}
            </div>
            <div className="d-flex align-items-center">
              <div className="d-flex flex-column">
                <div className="me-3 d-flex align-items-center">
                  {translate("TOTAL_PRICE")}:
                  {totalPrice ? (
                    <div className="d-flex align-items-center flex-nowrap font-bold ms-1">
                      {totalPrice}
                      <Icon
                        className="ms-2 text-gray-600 cursor-pointer focus-ring focus-ring-light"
                        id="total_price"
                        tabIndex={-1}
                        iconName="circle-info"
                        prefix="far"
                      />
                      <UncontrolledTooltip target="total_price" placement="top">
                        {translate("TOTAL_PRICE_ATTENTION")}
                      </UncontrolledTooltip>
                      {typeof translate("TOTAL_PRICE_ATTENTION") ===
                        "object" && (
                        <TranslateWrapper
                          tooltipText="TOTAL_PRICE_ATTENTION"
                          tooltipId="total_price"
                          placement="bottom"
                        />
                      )}
                    </div>
                  ) : (
                    <>{translate("NO_PRICE")}</>
                  )}
                </div>
              </div>
              <ProductListDownloadButton
                projectId={projectId}
                downloadAllowed={products.length > 0}
              />
            </div>
          </div>
          <div className="pt-2_5 bg-white">
            <ProductListOfProject
              currentPageNo={currentPageNo}
              fetching={productListFetching}
              commissionFetching={commissionFetching}
              isOrderDescending={isOrderDescending}
              pagesCount={pagesCount}
              products={products}
              commissioningProduct={commissioningProduct}
              productsCount={productsCount}
              setCurrentPageNo={setCurrentPageNo}
              setIsOrderDescending={setIsOrderDescending}
              tableRef={tableRef}
            />
          </div>
        </div>
      </>
    );
  },
);
