import React, {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import * as Scrivito from "scrivito";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import NiceModal from "@ebay/nice-modal-react";
import cx from "classnames";
import { downloadLink, handlePdfReportDownload, translate } from "utils";
import { items } from "variables";
import { useAppBaseContext } from "providers";
import { useMutation } from "urql";
import { createProductMut, createProductReport } from "api/mutations";
import { Button } from "../../Button";
import { Icon } from "../../Icon/Icon";
import { ModalCheckboxesBlock } from "./ModalCheckboxesBlock";
import s from "./ProjectDownloadModal.module.scss";

const BlobRender = React.lazy(() => import("./BlobRender"));

export const SingleProductDownloadModal = NiceModal.create(
  Scrivito.connect(({ modalSubtitle, orderCode }) => {
    const modal = NiceModal.useModal();
    if (!orderCode) {
      return null;
    }
    const { lang } = useAppBaseContext();
    const [productData, setProductData] = useState(null);
    const [createPdfActive, setCreatePdfActive] = useState(false);
    const [itemsData, setItemsData] = useState(items);

    const [, exeCreateProduct] = useMutation(createProductMut);
    const [, execute] = useMutation(createProductReport);

    useEffect(() => {
      exeCreateProduct({
        seriesId: orderCode.seriesId,
        languageIso2L: lang,
        requestUnitsSystem: "de_m3ph",
        resultUnitsSystem: "de_m3ph",
        state: { ordercodestate: orderCode.state },
        epfVersion: null,
      }).then(({ data }) => {
        setProductData(data?.createProduct.result);
      });
    }, [exeCreateProduct, lang, orderCode.seriesId, orderCode.state]);

    const pdfBlobRef = useRef();

    const handleUpdateAll = useCallback((checked) => {
      setItemsData((prevData) =>
        prevData?.map((item) => ({
          ...item,
          checked,
        })),
      );
    }, []);

    const handleSelectAll = useCallback(
      () => handleUpdateAll(true),
      [handleUpdateAll],
    );

    const handleClearAll = useCallback(
      () => handleUpdateAll(false),
      [handleUpdateAll],
    );

    const handleSubmit = useCallback(async () => {
      const getReport = (isZipped) =>
        handlePdfReportDownload({
          productData,
          setCreatePdfActive,
          isZipped,
          getReportCallback: execute,
          lang,
        });
      const selected = itemsData
        .filter((item) => item.checked)
        .map((item) => item.title);
      if (selected.length === 1) {
        if (selected[0] === "PRODUCT_INFORMATION") {
          await getReport(false);
        } else {
          downloadLink(
            pdfBlobRef.current,
            `${productData.product.ordercode.presentation}_specText.pdf`,
          );
        }
      } else {
        const JSZip = await import("./LazyZip");
        const Zip = JSZip.default;
        const zip = new Zip();
        const productInfoBlob = await getReport(true);
        const blobArray = [productInfoBlob, pdfBlobRef.current];
        blobArray.forEach((pdfBlob, index) => {
          zip.file(
            `${productData.product.ordercode.presentation}_${index + 1}.pdf`,
            pdfBlob,
          );
        });

        zip.generateAsync({ type: "blob" }).then((zipBlob) => {
          downloadLink(zipBlob, "reports.zip");
        });
      }
      setItemsData(items);
    }, [execute, itemsData, lang, productData]);

    const checked = useMemo(
      () => itemsData.filter((item) => item.checked),
      [itemsData],
    );

    return (
      <Modal
        isOpen={modal.visible}
        centered={true}
        size="xl"
        toggle={modal.hide}
        scrollable={true}
      >
        <ModalHeader
          toggle={modal.hide}
          tag="div"
          className="d-flex align-items-start pb-0"
        >
          <h3 className="text-uppercase my-xxl-1 lh-32">
            {translate("DOWNLOAD_OPTIONS")}
          </h3>
          <h4 className="mb-0 lh-24">{modalSubtitle}</h4>
        </ModalHeader>
        <ModalBody
          className={cx("d-flex row pb-8 pt-xs-6 ", s.modalBodyScrollbar)}
        >
          <ModalCheckboxesBlock
            data={itemsData}
            setData={setItemsData}
            blockTitle=""
            showProducts={false}
          />
          <Suspense fallback={<p>Loading..</p>}>
            {productData && (
              <BlobRender
                productData={productData}
                modalSubtitle={modalSubtitle}
                checked={checked}
                pdfBlobRef={pdfBlobRef}
              />
            )}
          </Suspense>
        </ModalBody>
        <ModalFooter className="pt-0">
          <Button
            data-testid="DownloadModal_select-all-btn"
            onClick={handleSelectAll}
            className="me-3"
            color="secondary"
            outline
            /* TODO: forthcoming feature (hidden acc US-19953) */
            // disabled={checkedItems.length === itemsData.length}
          >
            {translate("SELECT_ALL")}
          </Button>
          <Button
            data-testid="DownloadModal_clear-selection-btn"
            onClick={handleClearAll}
            className="me-3"
            color="secondary"
            outline
            disabled={!checked.length}
          >
            {translate("CLEAR_SELECTION")}
          </Button>
          <Button
            data-testid="DownloadModal_download-btn"
            className="d-inline-block text-white"
            onClick={handleSubmit}
            color="primary"
            disabled={checked.length === 0 || createPdfActive}
          >
            <Icon
              prefix="trox"
              iconName="arrow-down-bracket-regular"
              className="me-1"
            />
            {translate("DOWNLOAD_DATA")}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }),
);
