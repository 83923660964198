export const topologyItems = {
  system_controller: {
    sameLayer: ["system_controller", "control_panel", "component"],
    connectedByTopBracketChildren: ["fam"],
    iconName: "tech-symbol-cm",
    bgColor: "bg-gray-700",
    borderColor: "border-gray-700",
  },
  control_panel: {
    iconName: "tech-symbol-panel",
    bgColor: "bg-gray-700",
    borderColor: "border-gray-700",
  },
  switch: {
    sameLayer: ["component"],
    connectedByTopBracketChildren: ["fam"],
    iconName: "tech-symbol-switch",
  },
  fam: {
    connectedByTopBracketChildren: ["fam"],
    rightPlacedChildren: ["component"],
    bottomPlacedChildren: ["splitter", "distributor"],
    chainedChildren: ["module"],
    iconName: "tech-symbol-fam",
    bgColor: "bg-gray-500",
    borderColor: "border-gray-500",
    width: "208px",
  },
  module: {
    sameLayer: ["module"],
    iconName: "tech-symbol-cm",
    bgColor: "bg-gray-400",
    borderColor: "border-gray-400",
  },
  splitter: {
    sameLayer: [],
    width: "144px",
    iconName: "tech-symbol-cm",
  },
  distributor: {
    sameLayer: [],
    width: "144px",
    iconName: "tech-symbol-cm",
  },
  component: {
    iconName: "tech-symbol-fd",
    directlyConnected: ["component"],
    sameLayer: [],
  },
};

const splitByLayerItems = ({ kind, configurations = [] }) => {
  const sameLayer = [];
  const rightPlacedChildren = [];
  const bottomPlacedChildren = [];
  const chainedChildren = [];
  const connectedByTopBracketChildren = [];
  const directlyConnected = [];
  let isCBModule = false;
  let isLastFAM = false;

  switch (kind) {
    case "module":
      configurations?.forEach((item) => {
        if (item.title === "CB") {
          isCBModule = true;
          item.configurations?.forEach((cbItem) => {
            rightPlacedChildren.push({ ...cbItem, isSBItem: true });
          });
          return;
        }
        topologyItems[kind].sameLayer.includes(item.kind)
          ? sameLayer.push(item)
          : rightPlacedChildren.push(item);
      });
      break;
    case "fam":
      configurations?.forEach((item) => {
        if (
          topologyItems[kind].connectedByTopBracketChildren.includes(item.kind)
        ) {
          connectedByTopBracketChildren.push(item);
          return;
        }
        if (topologyItems[kind].rightPlacedChildren.includes(item.kind)) {
          rightPlacedChildren.push(item);
          return;
        }
        if (topologyItems[kind].bottomPlacedChildren.includes(item.kind)) {
          bottomPlacedChildren.push(item);
          return;
        }
        if (topologyItems[kind].chainedChildren.includes(item.kind)) {
          chainedChildren.push(item);
        }
      });
      break;
    case "system_controller":
    case "switch":
      configurations?.forEach((item) => {
        if (topologyItems[kind].sameLayer.includes(item.kind)) {
          sameLayer.push(item);
          return;
        }
        if (
          topologyItems[kind].connectedByTopBracketChildren.includes(item.kind)
        ) {
          connectedByTopBracketChildren.push(item);
          return;
        }
        bottomPlacedChildren.push(item);
      });
      break;
    case "component":
      configurations?.forEach((item) => {
        if (topologyItems[kind].directlyConnected.includes(item.kind)) {
          directlyConnected.push(item);
        }
      });
      break;
    default:
      configurations?.forEach((item) => {
        topologyItems[kind].sameLayer.includes(item.kind)
          ? sameLayer.push(item)
          : rightPlacedChildren.push(item);
      });
      break;
  }

  if (kind === "fam" && connectedByTopBracketChildren.length === 0) {
    isLastFAM = true;
  }

  return {
    sameLayer,
    connectedByTopBracketChildren,
    isCBModule,
    rightPlacedChildren,
    bottomPlacedChildren,
    chainedChildren,
    isLastFAM,
    directlyConnected,
  };
};

function convertObj({ configurations, kind, ...rest } = {}) {
  const result = { kind, ...rest };

  const {
    sameLayer,
    isCBModule,
    rightPlacedChildren,
    bottomPlacedChildren,
    connectedByTopBracketChildren,
    chainedChildren,
    isLastFAM,
    directlyConnected,
  } = splitByLayerItems({ kind, configurations });

  if (sameLayer.length > 0) {
    result.sameLayer = sameLayer.map((o) => convertObj(o));
  }
  if (isCBModule) {
    result.isCBModule = true;
  }
  if (rightPlacedChildren.length > 0) {
    result.rightPlacedChildren = rightPlacedChildren.map((o) => convertObj(o));
  }
  if (bottomPlacedChildren.length > 0) {
    result.bottomPlacedChildren = bottomPlacedChildren.map((o) =>
      convertObj(o),
    );
  }
  if (directlyConnected.length > 0) {
    result.directlyConnected = getFlatFromChild(
      directlyConnected.map((o) => convertObj(o)),
      "directlyConnected",
    );
  }
  if (chainedChildren.length > 0) {
    result.chainedChildren = getFlatFromChild(
      chainedChildren.map((o) => convertObj(o)),
      "sameLayer",
    );
  }
  if (connectedByTopBracketChildren.length > 0) {
    result.connectedByTopBracketChildren = getFlatFromChild(
      connectedByTopBracketChildren.map((o) => convertObj(o)),
      "connectedByTopBracketChildren",
    );
  }
  if (isLastFAM) {
    result.isLastFAM = isLastFAM;
  }

  return result;
}

function getFlatFromChild(arr, prop) {
  const result = [];

  arr.forEach((obj) => {
    result.push(obj);
    if (obj[prop]) {
      result.push(...getFlatFromChild(obj[prop], prop));
      delete obj[prop];
    }
  });

  return result;
}

export const getTopology = (data) => convertObj(data);

export const convertFAMs = (arr) => {
  const result = [];
  let group = [];
  for (let i = 0; i < arr.length; i += 1) {
    group.push(arr[i]);
    if (Object.prototype.hasOwnProperty.call(arr[i], "isLastFAM")) {
      result.push(group);
      group = [];
    }
  }
  if (group.length > 0) {
    result.push(group);
  }
  return result;
};

export const actionItemsList = [
  {
    title: "WIZARD_EDIT_TOPOLOGY_CONFIG",
    iconName: "pencil-light",
    key: "configure",
  },

  {
    title: "WIZARD_COPY_TOPOLOGY_CONFIG",
    iconName: "duplicate-light",
    key: "copy",
  },
  // TODO: forthcoming feature (hidden acc US-20779)
  // {
  //   title: "WIZARD_RESET_TOPOLOGY_CONFIG",
  //   iconName: "arrow-rotate-left-light",
  //   key: "reset",
  // },
  // {
  //   title: "WIZARD_TOPOLOGY_DELETE",
  //   iconName: "trash-can-light",
  //   key: "delete",
  // },
];
