import { translate } from "./localsUtils";

export const getSubnavItemTitle = (objClass) => {
  switch (objClass) {
    case "ProjectList":
      return translate("MY_PROJECTS");
    case "ProjectConfigurator":
      return translate("PROJECT_STRUCTURE");
    case "ProjectItem":
      return translate("PROJECT_DETAILS");
    case "ProductList":
      return translate("PROJECT_PRODUCT_LIST");
    case "LibraryList":
      return translate("LEFT_SIDEBAR_LIBRARY");
    default:
      return "";
  }
};
