import { useEffect, useState } from "react";
import { getHomepage } from "utils";
import * as Scrivito from "scrivito";

export const useWizardData = (subType) => {
  const [wizardSubTypeObj, setWizardSubTypeObj] = useState();
  const [configWizardObjRefs, setConfigWizardObjRefs] = useState();
  const [wizardItemsObjArrRefs, setWizardItemsObjArrRefs] = useState();
  const [wizardItemStepsTitles, setWizardItemStepsTitles] = useState();
  const [wizardItemTitle, setWizardItemTitle] = useState();

  useEffect(() => {
    const handleGetWizardBySubType = async () => {
      const result = await Scrivito.load(() => {
        const configWizardObjRefs_ = getHomepage().get("configWizardObjRefs");
        const wizardListObjRef = configWizardObjRefs_[0];
        const wizardItemsObjArrRefs_ = wizardListObjRef.get("wizardItem") || [];
        const wizardSubTypeObj_ = wizardItemsObjArrRefs_.find(
          (wizard) => wizard.get("subType") === subType,
        );
        const wizardItemStepsTitles_ = wizardSubTypeObj_?.get("menuStepTitles");
        const wizardItemTitle_ = wizardSubTypeObj_?.get("menuTitle");
        return {
          configWizardObjRefs_,
          wizardItemsObjArrRefs_,
          wizardSubTypeObj_,
          wizardItemStepsTitles_,
          wizardItemTitle_,
        };
      });

      setConfigWizardObjRefs(result.configWizardObjRefs_);
      setWizardItemsObjArrRefs(result.wizardItemsObjArrRefs_);
      setWizardSubTypeObj(result.wizardSubTypeObj_);
      setWizardItemStepsTitles(result.wizardItemStepsTitles_);
      setWizardItemTitle(result.wizardItemTitle_);
    };

    subType && handleGetWizardBySubType();
  }, [subType]);

  return {
    wizardSubTypeObj,
    configWizardObjRefs,
    wizardItemsObjArrRefs,
    wizardItemStepsTitles,
    wizardItemTitle,
  };
};
