import { useMemo } from "react";
import { useQuery } from "urql";
import { CountryListQry } from "api/queries";

export const useCountryList = ({ pause = false, language = "en" }) => {
  const [{ data }] = useQuery({
    query: CountryListQry,
    variables: {
      language,
    },
    context: useMemo(() => ({ requestPolicy: "cache-first" }), []),
    pause,
  });

  return data?.getCountryList?.items;
};
