import React, { useCallback, useEffect } from "react";
import cx from "classnames";
import { Modal } from "reactstrap";
import { XCircleLoader } from "./XCircleLoader/XCircleLoader";
import { useStateLoaderContext } from "../providers";

export const ModalWrapper = ({
  isOpen,
  onClosed,
  toggle,
  size,
  isLoading = true,
  children,
  ...rest
}) => {
  const { setIsModalOpen } = useStateLoaderContext();
  useEffect(() => setIsModalOpen(true), [setIsModalOpen]);
  useEffect(() => () => setIsModalOpen(false), [setIsModalOpen]);

  const handleClose = useCallback(() => {
    setIsModalOpen(false);
    onClosed && onClosed();
  }, [onClosed, setIsModalOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onClosed={handleClose}
      toggle={toggle}
      size={size}
      {...rest}
    >
      {isLoading && (
        <XCircleLoader classNames="position-absolute top-0 left-0 w-100 h-100 z-1" />
      )}
      <div className={cx("bg-white", { "d-none": isLoading })}>{children}</div>
    </Modal>
  );
};
