import { useEffect, useState } from "react";
import { getPageObjAsync } from "utils";

export const useLoginPage = () => {
  const [loginPage, setLoginPage] = useState(null);

  useEffect(() => {
    const getLoginPage = async () => {
      const loginPageObj = await getPageObjAsync("LoginPage");

      setLoginPage(loginPageObj);
    };

    getLoginPage();
  }, []);
  return loginPage;
};
