export const wizardsTranslations = {
  PRICE_CALC_MODAL_DUMMIES_INFO:
    "There are dummy products in your product list. No prices are calculated for these products",
  PRICE_CALC_MODAL_TITLE: "Price calculation",

  SERVICE_MODAL_DESCRIPTION: "Services modal description",
  SERVICE_MODAL_OPT_ON_SITE: "On Site Configuration",
  SERVICE_MODAL_OPT_PRE_CONFIGURED: "Pre-Configured",
  SERVICE_MODAL_WITHOUT_COMMISSIONING: "Without commissioning",
  SERVICE_MODAL_QUESTION_INFO:
    "Services modal commissioning question tooltip info",
  SERVICE_MODAL_QUESTION_TITLE: "Commissioning",
  SERVICE_MODAL_SUBTITLE: "Please select the desired Services",
  SERVICE_MODAL_TITLE: "Add services",

  WIZARD_ADD_PROJECT: "Add to project",
  WIZARD_SUMMARY: "SUMMARY",
  WIZARD_TOPOLOGY_VIEW: "Topology view",
  WIZARD_ADD_ZONE: "Add Zone",
  WIZARD_AUTO_SAVE_DATA: "The system saves your changes automatically.",
  WIZARD_CHANGE_NAME: "Change name",
  WIZARD_CONFIRM_ZONE: "Confirm Zone",
  WIZARD_INTRO_TITLE_FIREPROTECTION: "Introduction",
  WIZARD_INTRO_TITLE_ROOMCONTROL: "Introduction",
  WIZARD_NUMBER_OF_ZONES_WITH_VALUES:
    "Number of zones: {{current}} (max. {{max}})",
  WIZARD_NUMBER_OF_ZONES_LIMIT_WITH_VALUE:
    "Limited to amount of zones: {{number}}",
  WIZARD_RESET_DATA_CONFIRMATION: "Confirm Changes",
  WIZARD_RESET_DATA_CONFIRMATION_ACCEPT:
    "Confirm Changes & Reset following steps",
  WIZARD_RESET_DATA_CONFIRMATION_ALERT:
    "Are you sure you want to save your changes? It will reset the following steps to default and delete their current data. This action cannot be undone.",
  WIZARD_DELETE_ZONE_CONFIRMATION_ALERT:
    "Do you really want to delete this zone with all settings?",
  WIZARD_DELETE_ZONE_CONFIRMATION_ACCEPT: "Delete",
  WIZARD_RESET_NAME: "Reset name to default",
  WIZARD_RESET_TO_DEFAULT: "Reset To Default",
  WIZARD_RESULT: "Result",
  WIZARD_ROOM_CONTROL_SYSTEM: "Room Control System",
  WIZARD_SAVE_AS_TEMPLATE: "Save as Template",
  WIZARD_SUMMARY_OF_MODULES: "Summary of Modules",
  WIZARD_FILLING_ORDER_INFO:
    "Note: The selection of control strategies is required and impacts the selectable sensors, volume flow controllers, valves and fire dampers. The selection generates a recommended set of defaults, which can be manually adjusted afterwards.",
  // TODO: when BE/AB ready use BE translations insead
  WIZARD_EDIT_TOPOLOGY_CONFIG: "Configure Component",
  WIZARD_COPY_TOPOLOGY_CONFIG: "Copy Configuration",
  WIZARD_RESET_TOPOLOGY_CONFIG: "Reset Configuration",
  WIZARD_TOPOLOGY_DELETE: "Delete",

  WIZARD_COMMISSIONING: "Commissioning",
  WIZARD_COMMISSIONING_PRE_CONF: "Pre Configured",
  WIZARD_COMMISSIONING_ON_SITE_CONF: "On Site Configuration",
  WIZARD_PRODUCT_SERIES: "Select product series for further configurations",

  WIZARD_CREATE_PDF_MODAL_TITLE: "Create PDF",
  WIZARD_CREATE_PDF_MODAL_SUBTITLE: "Please select the PDF properties",
  WIZARD_CREATE_PDF_MODAL_PDF_TYPE: "PDF Type",
  WIZARD_CREATE_PDF_MODAL_PDF_TYPE_DIGITAL: "Digital",
  WIZARD_CREATE_PDF_MODAL_PDF_TYPE_PRINT: "Print",
  WIZARD_CREATE_PDF_MODAL_PDF_NAME: "PDF Name",
  WIZARD_CREATE_PDF_MODAL_PREVIEW: "Preview",
  WIZARD_CREATE_PDF_MODAL_EXPORT_BTN: "Export PDF",
  WIZARD_CREATE_PDF_MODAL_TOOLTIP_VERSION:
    "The PDF can be created either single-sided (select Digital) or multi-page (select Print)",
  WIZARD_CREATE_PDF_MODAL_TOOLTIP_NAME: "Customised file naming possible",
  WIZARDS_OVERVIEW_TITLE: "Overview",
  WIZARDS_OVERVIEW_SUBTITLE: "Available Wizards",
  WIZARDS_OVERVIEW_FIREPROTECTION_TITLE: "TROX Ox FIRE PROTECTION",
  WIZARDS_OVERVIEW_ROOMCONTROL_TITLE: "TROX Ox ROOM CONTROL",
  WIZARDS_OVERVIEW_XCUBE_TITLE: "X-Cube",
  WIZARDS_OVERVIEW_XFANS_TITLE: "X-Fans",
  WIZARDS_OVERVIEW_XCUBE_SUBTITLE: "Link to AHU Online Configurator",
  WIZARDS_OVERVIEW_XFANS_SUBTITLE: "Link to X-FANS Online Configurator",
  WIZARDS_OVERVIEW_FIREPROTECTION_SUBTITLE: "System Design Wizard",
  WIZARDS_OVERVIEW_ROOMCONTROL_SUBTITLE: "System Design Wizard",
  WIZARDS_OVERVIEW_FIREPROTECTION_DESCRIPTION:
    "Easy planning of fire protection systems with TROX Ox",
  WIZARDS_OVERVIEW_ROOMCONTROL_DESCRIPTION:
    "Easy planning of room and zone control with TROX Ox",
  WIZARDS_OVERVIEW_XCUBE_DESCRIPTION:
    "Configuration of AHU systems with the AHU Online Configurator",
  WIZARDS_OVERVIEW_XFANS_DESCRIPTION:
    "Configuration of X-FANS systems with the X-FANS Online Configurator",
  WIZARDS_LEFTSIDEBAR_MENU_FIREPROTECTION_SUBTITLE:
    "TROX Ox System Design Wizard",
  WIZARDS_LEFTSIDEBAR_MENU_ROOMCONTROL_SUBTITLE: "TROX Ox System Design Wizard",
  WIZARDS_LEFTSIDEBAR_MENU_XCUBE_SUBTITLE: "Link to AHU Online Configurator",
  WIZARDS_LEFTSIDEBAR_MENU_XFANS_SUBTITLE: "Link to X-FANS Online Configurator",
  WIZARD_NODE_DELETE_CHILDREN:
    "{{params}} and all attached elements will be deleted. This action cannot be undone",
  WIZARD_NODE_DELETE:
    "{{params}} will be deleted. This action cannot be undone",
  WIZARD_ATTENTION: "Attention!",
  WIZARD_INTRO_SUBTITLE_FIREPROTECTION:
    "Your Wizard for TROX Ox FIRE PROTECITON",
  WIZARD_INTRO_SUBTITLE_ROOMCONTROL: "Your Wizard for TROX Ox ROOM CONTROL",
  WIZARD_INTRO_TEXT_FIREPROTECTION:
    "Easy planning, efficient cost control - all this already in early planning phases. This wizard supports you in planning an innovative fire protection control system. Everything is based on the TROX Ox system platform. For a rough calculation of the system components in the project planning phase or for detailed offer. Get started right away. You can always save your project – and continue working at any time. The TROX Ox system wizard for beginners and advanced users. Just try it out.",
  WIZARD_INTRO_TEXT_ROOMCONTROL:
    "Easy planning, efficient cost control - all this already in early planning phases. This wizard supports you in planning an innovative room and zone control. All based on the TROX Ox system platform. For a rough calculation of the system components in the project planning phase or for a detailed offer. Get started right away. You can always save your project – and continue working at any time. The TROX Ox system wizard for beginners and advanced users. Just try it out.",
  WIZARD_NODE_PASE_CONFIRMATION: "Confirm",
  WIZARD_ZONE_NAME_LENGTH_HINT: "The zone name should have more than 3 letters",
};
