import React, { useEffect, useMemo, useState } from "react";
import * as Scrivito from "scrivito";
import cx from "classnames";
import {
  getHomepage,
  getLeftSidebarButtonByPageType,
  isSystemConfiguratorLayout,
} from "utils";
import {
  LEFT_SIDEBAR_BUTTONS_ARRAY_CONF,
  LEFT_SIDEBAR_BUTTONS_ARRAY_SAME,
} from "variables";
import { usePageType } from "hooks";

import { useAppBaseContext } from "providers";
import { LeftSidebarButton } from "./LeftSidebarButton";
import { LeftSidebarSubnav } from "./LeftSidebarSubnav";
import s from "./LeftSidebar.module.scss";

export const LeftSidebar = Scrivito.connect(
  ({ activeButton, setActiveButton, style }) => {
    const refsObj = {};
    const { isSameProj } = useAppBaseContext();
    Scrivito.currentPage()?.path();

    const linkList = useMemo(
      () =>
        isSameProj
          ? LEFT_SIDEBAR_BUTTONS_ARRAY_SAME
          : LEFT_SIDEBAR_BUTTONS_ARRAY_CONF,
      [isSameProj],
    );

    linkList.forEach((btn) => {
      refsObj[`${btn.title}_refs`] = [];
    });
    const [currentPageButton, setCurrentPageButton] = useState(null);
    const [refs, setRefs] = useState(refsObj);
    const pageType = usePageType();

    useEffect(() => {
      const getNavRefs = async () => {
        const btnRefs = await Scrivito.load(() =>
          linkList.map((btn) => ({
            btnTitle: btn.title,
            btnRefs: getHomepage().get(btn.refProp),
          })),
        );
        const newRefsObj = {};
        btnRefs.forEach((btn) => {
          newRefsObj[`${btn.btnTitle}_refs`] = btn.btnRefs;
        });
        setRefs(newRefsObj);
      };
      getNavRefs();

      // to reset active button when we are leaving pages with LeftSidebar
      return () => setActiveButton(null);
    }, [linkList, setActiveButton]);

    useEffect(() => {
      if (!pageType) {
        return;
      }
      const button = getLeftSidebarButtonByPageType(pageType);
      if (!button) {
        return;
      }

      setCurrentPageButton(button);
    }, [pageType]);

    const isLeftSidebarVisible = isSystemConfiguratorLayout(pageType);
    if (!isLeftSidebarVisible) {
      return null;
    }
    return (
      <div
        className={cx(
          s.leftSidebarWrapper,
          "d-flex flex-shrink-0 w-100 bg-gray-700 z-index-1 position-relative",
          {
            [s.leftSidebarWrapperExtended]: activeButton?.subNavLevel.value > 0,
          },
        )}
        style={style}
      >
        <div
          className={cx(
            s.leftSidebar,
            "d-flex flex-column flex-shrink-0 justify-content-between h-100",
          )}
        >
          <div>
            {linkList.map((item) => (
              <LeftSidebarButton
                key={item.title}
                item={item}
                currentPageButton={currentPageButton}
                activeButton={activeButton}
                setActiveButton={setActiveButton}
              />
            ))}
          </div>
          {/* TODO: forthcoming feature (hidden acc US-19953) */}
          {/* <LeftSidebarButton
            key="help_button"
            item={HELP_BTN}
            currentPageButton={currentPageButton}
            activeButton={activeButton}
            setActiveButton={setActiveButton}
          /> */}
        </div>
        {linkList.map((btn) => (
          <LeftSidebarSubnav
            key={btn.title}
            activeButton={activeButton}
            btn={btn}
            references={refs[`${btn.title}_refs`]}
            setActiveButton={setActiveButton}
          />
        ))}
      </div>
    );
  },
);
