import React, { useCallback, useState } from "react";
import { Accordion } from "reactstrap";

import { ProjectDownloadAccordionItem } from "./ProjectDownloadAccordionItem";
import { ProjectDownloadSortDropdown } from "./ProjectDownloadSortDropdown";
import { ProjectDownloadAccordionItemSublist } from "./ProjectDownloadAccordionItemSublist";

export const ModalCheckboxesBlock = ({
  data,
  blockTitle,
  setData,
  showProducts,
}) => {
  const [open, setOpen] = useState("");
  const [sublistItemOpen, setSublistItemOpen] = useState("");

  const toggle = useCallback((id) => {
    setOpen((prevValue) => (prevValue === id ? "" : id));
  }, []);

  const toggleSublist = useCallback((id) => {
    setSublistItemOpen((prevValue) => (prevValue === id ? "" : id));
    /* TODO: forthcoming feature (hidden acc US-19953) */
    setOpen("");
  }, []);

  return (
    <div className="flex-xs-column d-flex gap-2">
      <div className="d-flex justify-content-between align-items-center">
        <h3 className="text-uppercase mb-0 font20">{blockTitle}</h3>
        <ProjectDownloadSortDropdown showProducts={showProducts} />
      </div>
      <Accordion open={open} toggle={() => {}}>
        {data &&
          data.map((item) => (
            <ProjectDownloadAccordionItem
              open={open}
              title={item.title}
              key={item.title}
              toggle={toggle}
              checked={item.checked}
              setItemsData={setData}
              hasSublist={item.sublist}
            >
              {item.sublist ? (
                <Accordion open={sublistItemOpen} toggle={() => {}}>
                  <ul className="list-unstyled">
                    {item.content.map((contentItem) => (
                      <ProjectDownloadAccordionItem
                        open={sublistItemOpen}
                        title={contentItem.title}
                        key={contentItem.title}
                        toggle={toggleSublist}
                        checked={contentItem.checked}
                        setItemsData={setData}
                        hasSublist={contentItem.sublist}
                      >
                        <ProjectDownloadAccordionItemSublist
                          listOfDownloads={contentItem.content}
                        />
                      </ProjectDownloadAccordionItem>
                    ))}
                  </ul>
                </Accordion>
              ) : (
                <ProjectDownloadAccordionItemSublist
                  listOfDownloads={item.content}
                />
              )}
            </ProjectDownloadAccordionItem>
          ))}
      </Accordion>
    </div>
  );
};
