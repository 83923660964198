export const login = async (email, password) =>
  fetch(`${process.env.ASSISTIVE_BACKEND_URL}/login`, {
    method: "POST",
    body: JSON.stringify({ email, password }),
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  });

export const logout = () =>
  fetch(`${process.env.ASSISTIVE_BACKEND_URL}/logout`, {
    method: "POST",
    credentials: "include",
  });

export const refresh = async () =>
  fetch(`${process.env.ASSISTIVE_BACKEND_URL}/refresh`, {
    method: "POST",
    credentials: "include",
  });
