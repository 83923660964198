import React, { useCallback } from "react";
import cx from "classnames";

export const DropArea = ({
  isDragging,
  isDropInside,
  isOverCurrent,
  isParentDragged,
  isWizard,
  setIsDropInside,
}) => {
  const toggleDropInsideOff = useCallback(() => {
    setIsDropInside(false);
  }, [setIsDropInside]);

  const toggleDropInsideOn = useCallback(() => {
    setIsDropInside(true);
  }, [setIsDropInside]);

  return (
    isOverCurrent && (
      <div
        data-testid="drop-area"
        className={cx("position-absolute w-100 h-100 ms-n1", {
          "border border-info":
            !isParentDragged && !isDragging && !isWizard && isDropInside,
        })}
        onDragEnter={toggleDropInsideOn}
        onDragLeave={toggleDropInsideOff}
      />
    )
  );
};
