import React, { useCallback, useState } from "react";
import { Table } from "reactstrap";
import { Icon } from "../Icon/Icon";

const HeadCell = ({ title, isSortable = false, onSort, colSpan }) => {
  const [isOrderDescending, setIsOrderDescending] = useState(true);

  const handleClick = useCallback(
    (...args) => {
      onSort?.(...args);
      setIsOrderDescending((prev) => !prev);
    },
    [onSort],
  );

  if (!isSortable) {
    return <th colSpan={colSpan}>{title}</th>;
  }

  return (
    <th colSpan={colSpan}>
      <div className="d-flex justify-content-between">
        {title}
        <button
          onClick={handleClick}
          className="mx-1 p-0 border-0 bg-transparent text-gray-700"
        >
          <Icon
            iconName={isOrderDescending ? "angle-down" : "angle-up"}
            prefix="fa-regular"
          />
        </button>
      </div>
    </th>
  );
};

const BodyRow = ({ item, columns }) => (
  <tr key={item.id}>
    {columns.map((column) => {
      if (column.render) {
        return (
          <td align={column.align} key={column.id}>
            {column.render(item)}
          </td>
        );
      }

      if (typeof column.value === "string" && item[column.value]) {
        return (
          <td align={column.align} key={column.id}>
            {item[column.value]}
          </td>
        );
      }

      return <td align={column.align} key={column.id} />;
    })}
  </tr>
);

export const TableCustom = ({ columns, data }) => (
  <Table>
    <thead>
      <tr>
        {columns
          .filter((column) => !column.isWithoutHeaderTitle)
          .map(({ title, isSortable, onSort, colSpan }, index) => (
            <HeadCell
              title={title}
              isSortable={isSortable}
              onSort={onSort}
              key={index}
              colSpan={colSpan}
            />
          ))}
      </tr>
    </thead>
    <tbody>
      {data.map((item) => (
        <BodyRow key={item.id} item={item} columns={columns} />
      ))}
    </tbody>
  </Table>
);
