import * as Scrivito from "scrivito";
import { getHomepage } from "./getHomepage";

export const prepareNavigationItems = () => {
  const homepage = getHomepage();
  if (homepage) {
    const navSerialized = homepage.get("mainNavigationSerialized") || "[]";
    let navSerializedParsed = JSON.parse(navSerialized);
    navSerializedParsed = navSerializedParsed.map((item) => {
      const navChildren = item.children || [];
      const navigationItemTitle = Scrivito.Obj.get(item.id).get(
        "navigationTitle",
      );
      if (navigationItemTitle) {
        item.title = navigationItemTitle;
      }
      navChildren.map((child) => {
        const navChild = Scrivito.Obj.get(child.id);
        const navigationChildImage = navChild.get("navigationImage");
        const navigationChildTitle = navChild.get("navigationTitle");
        if (navigationChildImage) {
          child.img = navigationChildImage.id();
        }
        if (navigationChildTitle) {
          child.title = navigationChildTitle;
        }
        return child;
      });
      return item;
    });
    return navSerializedParsed;
  }
  return [];
};
