import * as Scrivito from "scrivito";
import { formatDate, urlFromBinary } from "utils";

const locationFromEvent = (event) => ({
  "@type": "Place",
  name: event.get("locationName"),
  address: addressFromEvent(event),
});

const addressFromEvent = (event) => ({
  "@type": "PostalAddress",
  streetAddress: event.get("locationStreetAddress"),
  addressLocality: event.get("locationLocality"),
  addressRegion: event.get("locationRegion"),
  postalCode: event.get("locationPostalCode"),
  addressCountry: event.get("locationCountry"),
});

const dataFromEvent = (event) => ({
  "@context": "http://schema.org",
  "@type": "Event",
  name: event.get("title"),
  url: Scrivito.urlFor(event),
  startDate: formatDate(event.get("date"), "YYYY-MM-DD"),
  location: locationFromEvent(event),
  image: urlFromBinary(event.get("image")),
  description: event.get("metaDataDescription"),
});

export default dataFromEvent;
