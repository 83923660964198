import React, { useState } from "react";
import { openDialog } from "scrivito";
import { UncontrolledTooltip } from "reactstrap";

export const TranslateWrapper = ({
  text,
  tooltipText,
  tooltipId = null,
  placement,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const tooltipStyles = {
    backgroundColor: "#4f5155",
    color: isHovered ? "#25ace7" : "#fff",
    cursor: "pointer",
    border: "1px solid #fff",
  };

  const handleClick = (event) => {
    event.stopPropagation();
    localStorage.setItem("translationText", tooltipText);
    openDialog("LocalsComponent");
  };

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  return (
    <div onMouseLeave={handleMouseLeave} id={tooltipText} className="d-inline">
      {text}
      <UncontrolledTooltip
        placement={placement}
        onClick={handleClick}
        autohide={false}
        style={tooltipStyles}
        onMouseEnter={handleMouseEnter}
        hideArrow
        target={tooltipId || tooltipText}
      >
        key: {tooltipText}
      </UncontrolledTooltip>
    </div>
  );
};
