import React from "react";
import { FormGroup, Input, Label } from "reactstrap";

export const CheckboxInput = ({
  field,
  label,
  classNames,
  onChange,

  ...restProps
}) => (
  <FormGroup check className={classNames}>
    <Input {...field} {...restProps} onChange={onChange || field.onChange} />
    <Label
      check
      style={{
        minWidth: "9rem",
      }}
    >
      {label}
    </Label>
  </FormGroup>
);
