import React from "react";
import * as Scrivito from "scrivito";
import { translate } from "utils";

import { Icon } from "../../Icon/Icon";

export const ProductListFilterButton = Scrivito.connect(() => (
  <div className="d-flex align-items-center me-2 border-right-1 border-gray-300">
    <Icon prefix="trox" iconName="bars-filter-regular" className="me-1" />
    {translate("FILTER")}
  </div>
));
