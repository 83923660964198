import React, { useRef } from "react";
import cx from "classnames";
import {
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledTooltip,
} from "reactstrap";
import NiceModal from "@ebay/nice-modal-react";
import { translate } from "utils";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import html2canvas from "html2canvas";
import { pdf } from "@react-pdf/renderer";
import { Button } from "../../Button";
import { Icon } from "../../Icon/Icon";
import { TextInput } from "../../Inputs";
import { RadioInput } from "../../Inputs/FormikInputs/RadioInput";
import { PreviewTopology } from "./PreviewTopology/PreviewTopology";
import { TemplatePDF } from "./PreviewTopology/TemplatePDF";

import s from "./CreatePDFModal.module.scss";

export const CreatePDFModal = NiceModal.create(
  ({ wizardTitle, structuredTopology }) => {
    const modal = NiceModal.useModal();

    const initialValues = {
      type: "digital",
      name: wizardTitle,
    };

    const targetRef = useRef(null);

    const convertTopologyToImg = async () => {
      // reset zoom
      targetRef.current.parentNode.style.transform = "translate(0, 0) scale(1)";
      const canvas = await html2canvas(targetRef.current);
      return canvas
        .toDataURL("image/png", 1.0)
        .replace("image/png", "image/octet-stream");
    };

    const downloadPDF = (url, filename) => {
      const link = window.document.createElement("a");
      link.style = "display:none;";
      link.download = filename;
      link.href = url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      link.remove();
    };

    const handleSubmit = async ({ name }) => {
      try {
        toast.loading(translate("IS_LOADING_TEXT"));
        const topologyImg = await convertTopologyToImg();
        const blob = await pdf(
          <TemplatePDF
            textConfigurator={translate("CONFIGURATOR")}
            title={wizardTitle}
            topologyImg={topologyImg}
          />,
        ).toBlob();
        const url = URL.createObjectURL(blob);

        await downloadPDF(url, name);
      } catch (err) {
        toast.dismiss();
        toast.error(translate("ERROR_OCCURED"), {
          type: "error",
          theme: "colored",
        });
        console.error(err);
      } finally {
        toast.dismiss();
      }
    };

    return (
      <Modal
        isOpen={modal.visible}
        centered={true}
        size="xl"
        toggle={modal.hide}
        scrollable={true}
      >
        <ModalHeader
          toggle={modal.hide}
          tag="div"
          className="d-flex align-items-start pb-0"
        >
          <h3 className="text-uppercase mb-1 lh-32">
            {translate("WIZARD_CREATE_PDF_MODAL_TITLE")}
          </h3>
          <h4 className="mb-0 lh-24">
            {translate("WIZARD_CREATE_PDF_MODAL_SUBTITLE")}
          </h4>
        </ModalHeader>
        <Formik onSubmit={handleSubmit} initialValues={initialValues}>
          <Form>
            <ModalBody className="d-flex pb-4 gap-4">
              <div className="d-flex flex-column w-50">
                <legend className="d-flex align-items-center mb-3 fs-md text-gray-700 fw-bold">
                  {translate("WIZARD_CREATE_PDF_MODAL_PDF_TYPE")}
                  <Icon
                    className="ms-2 text-gray-600 cursor-pointer"
                    tabIndex={-1}
                    iconName="circle-info"
                    prefix="far"
                    id="type"
                  />
                  <UncontrolledTooltip target="type" placement="bottom">
                    {translate("WIZARD_CREATE_PDF_MODAL_TOOLTIP_VERSION")}
                  </UncontrolledTooltip>
                </legend>
                <div className="d-flex gap-4 mb-4">
                  <div className="d-flex align-items-center">
                    <Field
                      component={RadioInput}
                      name="type"
                      type="radio"
                      autoFocus={true}
                      data-recording-disable={true}
                      value="digital"
                      id="digital"
                      htmlFor="digital"
                      data-testid="CreatePDFModal_type-digital-radio"
                    />
                    <Label
                      className="d-flex align-items-center fs-md text-gray-700"
                      for="digital"
                    >
                      {translate("WIZARD_CREATE_PDF_MODAL_PDF_TYPE_DIGITAL")}
                    </Label>
                  </div>
                  <div className="d-flex align-items-center">
                    <Field
                      component={RadioInput}
                      name="type"
                      type="radio"
                      autoFocus={true}
                      data-recording-disable={true}
                      value="print"
                      id="print"
                      htmlFor="print"
                      data-testid="CreatePDFModal_type-print-radio"
                    />
                    <Label
                      className="d-flex align-items-center fs-md text-gray-700"
                      for="print"
                    >
                      {translate("WIZARD_CREATE_PDF_MODAL_PDF_TYPE_PRINT")}
                    </Label>
                  </div>
                </div>
                <div>
                  <Label
                    className="d-flex align-items-center fs-md text-gray-700 fw-bold"
                    for="pdfName"
                  >
                    {translate("WIZARD_CREATE_PDF_MODAL_PDF_NAME")}
                    <Icon
                      className="ms-2 text-gray-600 cursor-pointer"
                      tabIndex={-1}
                      iconName="circle-info"
                      prefix="far"
                      id="pdfName"
                    />
                    <UncontrolledTooltip target="pdfName" placement="bottom">
                      {translate("WIZARD_CREATE_PDF_MODAL_TOOLTIP_NAME")}
                    </UncontrolledTooltip>
                  </Label>
                  <Field
                    component={TextInput}
                    name="name"
                    autoFocus={true}
                    data-recording-disable={true}
                    data-testid="CreatePDFModal_pdf-name-input"
                  />
                </div>
              </div>
              <div className="d-flex flex-column w-50">
                <label className="d-flex align-items-center mb-3 fs-md text-gray-700 fw-bold">
                  {translate("WIZARD_CREATE_PDF_MODAL_PREVIEW")}
                </label>
                <div className="border border-gray-400">
                  <div className={cx("position-relative", s.previewWindow)}>
                    {structuredTopology && (
                      <PreviewTopology
                        innerRef={targetRef}
                        wizardTitle={wizardTitle}
                        structuredTopology={structuredTopology}
                      />
                    )}
                  </div>
                </div>
              </div>
            </ModalBody>
            <ModalFooter className="pt-0">
              <Button
                id="pdfButton"
                type="submit"
                color="primary"
                className="d-inline-block text-white"
                data-testid="DownloadModal_download-btn"
              >
                {translate("WIZARD_CREATE_PDF_MODAL_EXPORT_BTN")}
              </Button>
            </ModalFooter>
          </Form>
        </Formik>
      </Modal>
    );
  },
);
