import React from "react";

export const HighlightText = ({
  highlightTag = "b",
  text,
  symbolsToHighlight,
}) => {
  const regExSearchedString = new RegExp(symbolsToHighlight, "ig");
  const highlightedStr =
    typeof text === "object"
      ? text.props.text.replace(
          regExSearchedString,
          (overlap) => `<${highlightTag}>${overlap}</${highlightTag}>`,
        )
      : text.replace(
          regExSearchedString,
          (overlap) => `<${highlightTag}>${overlap}</${highlightTag}>`,
        );

  return (
    <span
      dangerouslySetInnerHTML={{
        __html: highlightedStr,
      }}
    />
  );
};
