import React from "react";
import { FormGroup, Input, Label } from "reactstrap";

export const RadioInput = ({
  field,
  label,
  classNames,
  htmlFor,
  onChange,
  ...restProps
}) => (
  <FormGroup check className={classNames}>
    <Input {...field} {...restProps} onChange={onChange || field.onChange} />
    <Label check for={htmlFor} className={restProps.labelclass}>
      {label}
    </Label>
  </FormGroup>
);
