import React, { useCallback, useEffect, useState } from "react";
import * as Scrivito from "scrivito";
import { MultiselectField } from "./MultiselectField";

export const MultiselectComponent = Scrivito.connect(({ attributes, obj }) => {
  const [allOptions, setAllOptions] = useState({});
  const [optionsSelected, setOptionsSelected] = useState({});

  useEffect(() => {
    if (attributes && obj) {
      // set all possible options
      const allOptionsSet = {};
      attributes.forEach((attr) => {
        allOptionsSet[attr.attribute] = attr.values.sort().map((val) => ({
          value: val,
          label: val,
        }));
      });
      setAllOptions(allOptionsSet);
      // set selected options
      Scrivito.load(() => {
        const selectedOptionsSet = {};
        attributes.forEach((attr) => {
          const valuesArr = obj.get(attr.attribute) || [];

          selectedOptionsSet[attr.attribute] = valuesArr.sort().map((val) => ({
            value: val,
            label: val,
          }));
        });
        return selectedOptionsSet;
      }).then((res) => setOptionsSelected(res));
    }
  }, [attributes, obj]);

  const handleChange = useCallback(
    (newSelectValues, attributeName) => {
      Scrivito.load(() => {}).then(() => {
        obj.update({
          [attributeName]: newSelectValues.map((item) => item.value),
        });
      });
      setOptionsSelected((values) => ({
        ...values,
        [attributeName]: newSelectValues,
      }));
    },
    [obj],
  );

  return (
    <div className="scrivito_detail_content" style={{ minHeight: "400px" }}>
      {attributes &&
        attributes.map((attr) => (
          <MultiselectField
            key={attr.attribute}
            title={attr.title}
            options={allOptions[attr.attribute]}
            handleChange={handleChange}
            attribute={attr.attribute}
            optionsSelected={optionsSelected[attr.attribute]}
          />
        ))}
    </div>
  );
});
