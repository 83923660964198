import React from "react";
import * as Scrivito from "scrivito";
import { useSwiperPagination } from "hooks";
import { InPlaceEditingPlaceholder } from "components/InPlaceEditingPlaceholder";
import "./GalleryWidget.scss";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

const settings = {
  spaceBetween: 50,
  autoplay: true,
  speed: 500,
  loop: true,
  slidesPerView: 1,
  modules: [Navigation, Autoplay, Pagination],
  pagination: {
    clickable: true,
  },
};

const CustomPagination = ({ image, handleSlideChange, className, index }) => {
  if (image && image.objClass() === "Image") {
    return (
      <button
        className={`tab custom-pagination-dot ${className}`}
        onClick={() => handleSlideChange(index)}
      >
        <Scrivito.BackgroundImageTag
          className="image"
          style={{
            background: { image },
            height: "100%",
          }}
        />
      </button>
    );
  }
  return null;
};

export const SliderWithGallery = Scrivito.connect(({ images = [] }) => {
  const { swiperRef, handleSlideChange, handleDotClick } =
    useSwiperPagination();

  if (!images.length) {
    return (
      <InPlaceEditingPlaceholder center>
        Select images in the properties.
      </InPlaceEditingPlaceholder>
    );
  }

  return (
    <div className="gallery-widget">
      <Swiper
        className="mySwiper"
        {...settings}
        ref={swiperRef}
        onSlideChange={handleSlideChange}
      >
        {images.map((image, index) =>
          image.objClass() === "Image" ? (
            <SwiperSlide
              key={`${image.id()}${index}`}
              index={index}
              className="slide"
              tag="li"
            >
              <Scrivito.ImageTag
                className="media-stage-slide"
                content={image}
                key={`${image.id()}${index}`}
                alt={image.get("alternativeText")}
                width={"100%"}
              />
            </SwiperSlide>
          ) : (
            <p key={`no-image-${index}`}>
              Slides needs to be type of Image Obj
            </p>
          ),
        )}

        <div className="custom-pagination">
          {images.map((image, index) => (
            <CustomPagination
              key={index}
              image={image}
              className={`custom-pagination-dot`}
              handleSlideChange={handleDotClick}
              index={index}
            />
          ))}
        </div>
      </Swiper>
    </div>
  );
});
