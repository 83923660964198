import React, { useCallback } from "react";
import * as Scrivito from "scrivito";
import ReactSelect from "react-select";

export const Multiselect = Scrivito.connect(
  ({ options = [], optionsSelected = [], handleChange }) => {
    const onChange = useCallback(
      (values) => {
        handleChange(values);
      },
      [handleChange],
    );

    return (
      <ReactSelect
        placeholder="Select"
        isMulti
        value={optionsSelected}
        options={options}
        onChange={onChange}
      />
    );
  },
);
