import React from "react";
import cx from "classnames";
import { Icon } from "components/Icon/Icon";
import { TranslateWrapper } from "components/TranslateWrapper";
import s from "./LeftSidebar.module.scss";

export const LeftSidebarSubNavHeader = ({
  isFinalSubNav,
  title,
  referenceObj,
  subtitle,
  ...rest
}) => {
  const TitleType = isFinalSubNav ? "h3" : "h4";
  const SubtitleType = isFinalSubNav ? "h6" : "span";
  const translatedTitle = typeof title === "object" ? title.props.text : title;
  const titleMenu =
    translatedTitle ||
    referenceObj?.get("menuTitle") ||
    referenceObj?.get("title");
  const subTitle =
    subtitle ||
    referenceObj?.get("menuSubtitle") ||
    referenceObj?.get("subtitle") ||
    "";

  const subnavTitle = (
    <TitleType
      className={cx("title text-uppercase mb-0", s.leftSidebarSubNavLinkTitle)}
    >
      {titleMenu}
    </TitleType>
  );
  const toolTip =
    typeof title === "object" ? (
      <TranslateWrapper
        text={subnavTitle}
        tooltipText={title.props.tooltipText}
      />
    ) : null;

  return (
    <div className="d-flex flex-column gap-1" {...rest}>
      <div className="d-flex gap-2">
        {isFinalSubNav && (
          <Icon
            iconName="TROX-Ox_Logo-onlyOX"
            prefix="trox"
            className="icon-32"
          />
        )}
        {typeof title === "object" ? toolTip : subnavTitle}
      </div>
      {subTitle && (
        <SubtitleType
          className={cx("fw-normal mb-0", s.leftSidebarSubNavLinkSubtitle, {
            font16: !isFinalSubNav,
            "text-gray-500 uppercase": isFinalSubNav,
          })}
        >
          {subTitle}
        </SubtitleType>
      )}
    </div>
  );
};
