export const legacyAppLocals = {
  DATE_PERIOD_EVENT_RANGE: "Zeitraum",
  DATE_PERIOD_EVENT_START: "Datum",

  EVENT_CONTINENT_AFRICA: "Afrika",
  EVENT_CONTINENT_ASIA: "Asien",
  EVENT_CONTINENT_EURASIA: "Eurasien",
  EVENT_CONTINENT_EUROPE: "Europa",
  EVENT_CONTINENT_NORTH_AMERICA: "Nordamerika",
  EVENT_CONTINENT_OCEANIA: "Ozeanien",
  EVENT_CONTINENT_SOUTH_AMERICA: "Südamerika",

  EVENT_COUNTRY_ALGERIA: "Algerien",
  EVENT_COUNTRY_ARGENTINA: "Argentina",
  EVENT_COUNTRY_AUSTRALIA: "Australien",
  EVENT_COUNTRY_AUSTRIA: "Österreich",
  EVENT_COUNTRY_BAHRAINCHINA: "Bahrain",
  EVENT_COUNTRY_BELGIUM: "Belgien",
  EVENT_COUNTRY_BOSNIA_AND_HERCEGOVINA: "Bosnien und Herzegowina",
  EVENT_COUNTRY_BRAZIL: "Brazil",
  EVENT_COUNTRY_BULGARIA: "Bulgarien",
  EVENT_COUNTRY_CANADA: "Kanada",
  EVENT_COUNTRY_CHILE: "Chile",
  EVENT_COUNTRY_CHINA: "China",
  EVENT_COUNTRY_COLOMBIA: "Kolumbien",
  EVENT_COUNTRY_CROATIA: "Kroatien",
  EVENT_COUNTRY_CYPRUS: "Zypern",
  EVENT_COUNTRY_CZECH_REPUBLIC: "Tschechien",
  EVENT_COUNTRY_DENMARK: "Dänemark",
  EVENT_COUNTRY_EGYPT: "Ägypten",
  EVENT_COUNTRY_ESTONIA: "Estland",
  EVENT_COUNTRY_FINLAND: "Finnland",
  EVENT_COUNTRY_FRANCE: "Frankreich",
  EVENT_COUNTRY_GERMANY: "Deutschland",
  EVENT_COUNTRY_GREAT_BRITAIN: "Großbritannien",
  EVENT_COUNTRY_GREECE: "Griechenland",
  EVENT_COUNTRY_HUNGARY: "Ungarn",
  EVENT_COUNTRY_ICELAND: "Island",
  EVENT_COUNTRY_INDIA: "Indiana",
  EVENT_COUNTRY_INDONESIA: "Indonesien",
  EVENT_COUNTRY_IRAN: "Iran",
  EVENT_COUNTRY_IRAQ: "Iraq",
  EVENT_COUNTRY_IRELAND: "Irland",
  EVENT_COUNTRY_ISRAEL: "Israel",
  EVENT_COUNTRY_ITALY: "Italien",
  EVENT_COUNTRY_JORDAN: "Jordanien",
  EVENT_COUNTRY_KUWAIT: "Kuwait",
  EVENT_COUNTRY_LATVIA: "Lettland",
  EVENT_COUNTRY_LEBANON: "Libanon",
  EVENT_COUNTRY_LITHUANIA: "Litauen",
  EVENT_COUNTRY_MALAUSIA: "Malaysia",
  EVENT_COUNTRY_MALTA: "Malta",
  EVENT_COUNTRY_MAROCCO: "Marokko",
  EVENT_COUNTRY_MEXICO: "Mexiko",
  EVENT_COUNTRY_NEGARA_BRUNEI_DARUSSALAM: "Negara Brunei Darussalam",
  EVENT_COUNTRY_NETHERLANDS: "Niederlande",
  EVENT_COUNTRY_NEW_ZEALAND: "Neuseeland",
  EVENT_COUNTRY_NIGERIA: "Nigeria",
  EVENT_COUNTRY_NORWAY: "Norwegen",
  EVENT_COUNTRY_OMAN: "Oman",
  EVENT_COUNTRY_PAKISTAN: "Pakistan",
  EVENT_COUNTRY_PANAMA: "Panama",
  EVENT_COUNTRY_PHILIPPINES: "Phillipinien",
  EVENT_COUNTRY_POLAND: "Polen",
  EVENT_COUNTRY_PORTUGAL: "Portugal",
  EVENT_COUNTRY_QATAR: "Katar",
  EVENT_COUNTRY_ROMANIA: "Rumänien",
  EVENT_COUNTRY_RUSSIA: "Russland",
  EVENT_COUNTRY_SAUDI_ARABIA: "Saudi-Arabien",
  EVENT_COUNTRY_SERBIA: "Serbien",
  EVENT_COUNTRY_SINGAPORE: "Singapur",
  EVENT_COUNTRY_SLOVAK_REPUBLIC: "Slowakei",
  EVENT_COUNTRY_SLOVENIA: "Slovenien",
  EVENT_COUNTRY_SOUTH_AFRICA: "Südafrika",
  EVENT_COUNTRY_SOUTH_KOREA: "Südkorea",
  EVENT_COUNTRY_SPAIN: "Spanien",
  EVENT_COUNTRY_SWEDEN: "Schweden",
  EVENT_COUNTRY_SWITZERLAND: "Schweiz",
  EVENT_COUNTRY_TAIWAN: "Taiwan",
  EVENT_COUNTRY_THAILAND: "Thailand",
  EVENT_COUNTRY_TUNISIA: "Tunesien",
  EVENT_COUNTRY_TURKEY: "Türkei",
  EVENT_COUNTRY_UKRAINE: "Ukraine",
  EVENT_COUNTRY_UNITED_ARAB_EMIRATES: "Vereinigte Arabische Emirate",
  EVENT_COUNTRY_URUGUAY: "Uruguay",
  EVENT_COUNTRY_USA: "USA",
  EVENT_COUNTRY_VENEZUELA: "Venezuela",
  EVENT_COUNTRY_VIETNAM: "Vietnam",
  EVENT_COUNTRY_ZIMBABWE: "Zimbabwe",

  EVENT_TYPE_EVENT: "Event",
  EVENT_TYPE_EXPO: "Expo",

  JOB_LOCATION_ANHOLT: "Anholt",
  JOB_LOCATION_BAD_HERSFELD: "Bad Hersfeld",
  JOB_LOCATION_BRANCH_OFFICE_CENTRAL_GERMANY: "Niederlassung Mitte",
  JOB_LOCATION_BRANCH_OFFICE_EAST_BERLIN: "Niederlassung Ost(Berlin)",
  JOB_LOCATION_BRANCH_OFFICE_EAST_DRESDEN: "Niederlassung Ost (Dresden)",
  JOB_LOCATION_BRANCH_OFFICE_NORTH: "Niederlassung Nord",
  JOB_LOCATION_BRANCH_OFFICE_SOUTH: "Niederlassung Süd",
  JOB_LOCATION_BRANCH_OFFICE_SOUTH_WEST: "Niederlassung Süd-West",
  JOB_LOCATION_FRIBOURG: "Fribourg",
  JOB_LOCATION_GERMANY: "Bundesweit",
  JOB_LOCATION_GOCH: "Goch",
  JOB_LOCATION_GRANGAS: "Granges-Paccot",
  JOB_LOCATION_GRANGAS_FRIBOURG: "Granges-Paccot (Fribourg)",
  JOB_LOCATION_HOERSTEL: "Hauptverwaltung Hörstel",
  JOB_LOCATION_LONDON: "London",
  JOB_LOCATION_NEUKIRCHEN_VLUYN: "Neukirchen-Vluyn",
  JOB_LOCATION_RUETI: "Rüti",
  JOB_LOCATION_SAO_PAULO: "Sao Paulo",
  JOB_LOCATION_THETFORD: "Thetford",
  JOB_LOCATION_UK: "UK",
  JOB_LOCATION_WIEN: "Wien",

  JOB_OCCUPATIONAL_AREA_COMMERCIAL: "Kaufmännisch",
  JOB_OCCUPATIONAL_AREA_CONSTRUCTION: "Bauwesen",
  JOB_OCCUPATIONAL_AREA_FINANCE: "Finanzen",
  JOB_OCCUPATIONAL_AREA_HR: "Personal / Recht",
  JOB_OCCUPATIONAL_AREA_IT: "IT",
  JOB_OCCUPATIONAL_AREA_MARKETING: "Marketing",
  JOB_OCCUPATIONAL_AREA_PRACTICUM: "Praktikum",
  JOB_OCCUPATIONAL_AREA_PRODUCTION: "Produktion",
  JOB_OCCUPATIONAL_AREA_PURCHASING: "Einkauf",
  JOB_OCCUPATIONAL_AREA_QM: "Qualitätsmanagement",
  JOB_OCCUPATIONAL_AREA_SALES: "Vertrieb",
  JOB_OCCUPATIONAL_AREA_TECHNICAL: "Technisch",
  JOB_OCCUPATIONAL_AREA_TECHNIK: "Technik",
  JOB_OCCUPATIONAL_AREA_TRADE: "Gewerblich",

  JOB_TARGET_GROUP_APPRENTICES: "Auszubildende",
  JOB_TARGET_GROUP_EXPERT: "Experte",
  JOB_TARGET_GROUP_GRADUATE: "Absolvent",
  JOB_TARGET_GROUP_PROFESSIONALLY_EXPERIENCED_AND_GRADUATED:
    "Berufserfahrene und Hochschulabsolventen",
  JOB_TARGET_GROUP_PUPILS: "Schüler",
  JOB_TARGET_GROUP_STUDENTS: "Studenten",

  LEFT_SIDEBAR_PROJECT: "Project",
  LEFT_SIDEBAR_PRODUCTS: "Products",
  LEFT_SIDEBAR_WIZARDS: "Wizards",
  LEFT_SIDEBAR_LIBRARY: "Library",
  LEFT_SIDEBAR_MAIN_MENU: "Main menu",
  LEFT_SIDEBAR_CONFIGURATION_MENU: "Configuration menu",
  LEFT_SIDEBAR_PRODUCT_MENU: "Configuration menu",
  LEFT_SIDEBAR_ZONE_PROPERTIES: "Zone Properties",
  LEFT_SIDEBAR_ROOM_CONTROL: "TROX Ox Room Control",

  NEWS_CATEGORY_COMPETENCES_ARCHITECT: "Architekt",
  NEWS_CATEGORY_COMPETENCES_BUYER: "Einkäufer",
  NEWS_CATEGORY_COMPETENCES_FACILITY: "Facility Manager",
  NEWS_CATEGORY_COMPETENCES_FITTER: "Monteur",
  NEWS_CATEGORY_COMPETENCES_PLANNER: "Planer",

  NEWS_CATEGORY_RUBRIC_ACADEMY: "Academy",
  NEWS_CATEGORY_RUBRIC_CARE: "TROX Care",
  NEWS_CATEGORY_RUBRIC_COMPANY: "Unternehmen",
  NEWS_CATEGORY_RUBRIC_MARKET: "Markt",
  NEWS_CATEGORY_RUBRIC_PRESS: "Presse",
  NEWS_CATEGORY_RUBRIC_PRODUCTS: "Produkte",
  NEWS_CATEGORY_RUBRIC_PROJECTS: "Projekte",
  NEWS_CATEGORY_RUBRIC_ROADSHOW: "Roadshow",

  TEXT_BUTTON_BACK_TO_OVERVIEW: "Zurück zur Übersicht",
  TEXT_BUTTON_RESET_FILTERS: "Filter zurücksetzen",
  TEXT_LANGUAGE: "LANGUAGE",
  TEXT_LESS_TEXT: "weniger Text",
  TEXT_MORE_TEXT: "mehr Text",
  TEXT_MY_TROX: "myTROX",
  TEXT_NOT_LOGGED_IN: "Anmelden",
  TEXT_READ_MORE: "mehr erfahren",
  TEXT_SAVE_CHANGES: "Save changes",
  TEXT_WARNING: "Warning",
};
