import { toast } from "react-toastify";

export const reusableUpdateTost = (ref, render, key, type = "error") =>
  toast.update(ref?.current, {
    render,
    type,
    toastId: key,
    isLoading: false,
    theme: "colored",
    hideProgressBar: type !== "error",
    autoClose: type === "error" ? 5000 : false,
    closeButton: true,
  });
