export const PRODUCTS_PER_PAGE = 10;

// TODO: PRODUCTS_LIST_MOCK is only temp solution - needs to be replaces with real data
export const PRODUCTS_LIST_MOCK = [
  [
    {
      id: "prod1",
      title: "System Controller SC-CP",
      presentation: "123-123-123-123-123-123",
      quantity: 1,
      unitPrice: "5000,00 €",
      totalPrice: "5000,00 €",
      priceGroup: 1,
    },
    {
      id: "prod2",
      title: "Switch",
      presentation: "123-123-123-123-123-123",
      quantity: 1,
      unitPrice: "3000,00 €",
      totalPrice: "3000,00 €",
      priceGroup: 1,
    },
    {
      id: "prod3",
      title: "FAM",
      presentation: "123-123-123-123-123-123",
      quantity: 3,
      unitPrice: "3000,00 €",
      totalPrice: "9000,00 €",
      priceGroup: 1,
    },
    {
      id: "prod4",
      title: "Connection Module",
      presentation: "123-123-123-123-123-123",
      quantity: 12,
      unitPrice: "1000,00 €",
      totalPrice: "12000,00 €",
      priceGroup: 1,
    },
    {
      id: "prod5",
      title: "FD-24",
      presentation: "123-123-123-123-123-123",
      quantity: 4,
      unitPrice: "500,00 €",
      totalPrice: "2000,00 €",
      priceGroup: 1,
    },
    {
      id: "prod6",
      title: "FDLS",
      presentation: "123-123-123-123-123-123",
      quantity: 2,
      unitPrice: "500,00 €",
      totalPrice: "1000,00 €",
      priceGroup: 1,
    },

    {
      id: "prod7",
      title: "MD-230",
      presentation: "123-123-123-123-123-123",
      quantity: 3,
      unitPrice: "300,00 €",
      totalPrice: "900,00 €",
      priceGroup: 1,
    },
    {
      id: "prod8",
      title: "FD-230",
      presentation: "123-123-123-123-123-123",
      quantity: 3,
      unitPrice: "300,00 €",
      totalPrice: "900,00 €",
      priceGroup: 1,
    },
    {
      id: "prod9",
      title: "FDK",
      presentation: "123-123-123-123-123-123",
      quantity: 2,
      unitPrice: "500,00 €",
      totalPrice: "1000,00 €",
      priceGroup: 1,
    },
    {
      id: "prod10",
      title: "FK2-EU",
      presentation: "123-123-123-123-123-123",
      quantity: 2,
      unitPrice: "1000,00 €",
      totalPrice: "2000,00 €",
      priceGroup: 1,
    },
  ],
  [
    {
      id: "prod11",
      title: "TVE",
      presentation: "123-123-123-123-123-123",
      quantity: 4,
      unitPrice: "500,00 €",
      totalPrice: "2000,00 €",
      priceGroup: 1,
    },
    {
      id: "prod12",
      title: "TVM",
      presentation: "123-123-123-123-123-123",
      quantity: 2,
      unitPrice: "500,00 €",
      totalPrice: "1000,00 €",
      priceGroup: 1,
    },
    {
      id: "prod13",
      title: "FD-230",
      presentation: "Dummy",
      quantity: 4,
      unitPrice: "500,00 €",
      totalPrice: "2000,00 €",
      priceGroup: 1,
    },
    {
      id: "prod14",
      title: "TVE",
      presentation: "Dummy",
      quantity: 2,
      unitPrice: "500,00 €",
      totalPrice: "1000,00 €",
      priceGroup: 1,
    },
  ],
];
