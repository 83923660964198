import React from "react";
import * as Scrivito from "scrivito";

import { translate } from "utils";
import { Icon } from "components/Icon/Icon";

export const Clipboard = Scrivito.connect(
  ({
    title,
    quantity,
    presentation,
    handleCopyCancel,
    localPath,
    projectName,
  }) => (
    <div className="position-absolute h-100 w-100" style={{ top: "-74px" }}>
      <div
        className="position-sticky p-5 border bg-white d-flex justify-content-between flex-nowrap align-items-center"
        style={{ zIndex: "9999", height: "92px", top: "8px" }}
      >
        <div className="d-flex flex-column">
          <h5 className="mb-0">Clipboard</h5>
          <div className="d-flex flex-nowrap">
            {localPath ? (
              <div>
                {projectName} {localPath.replaceAll("/", " / ")} {title}
              </div>
            ) : (
              <>
                <div className="pe-2 border-end">{title}</div>
                <div className="px-2 border-end">
                  {translate("QUANTITY")}: {quantity}
                </div>
                <div className="ps-2">
                  {translate("PRESENTATION_ORDER_CODE")}: {presentation}
                </div>
              </>
            )}
          </div>
        </div>
        <div
          className="d-flex align-items-center cursor-pointer"
          onClick={handleCopyCancel}
        >
          Cancel
          <Icon
            prefix="trox"
            iconName="xmark-regular"
            className="ms-2 icon-16"
          />
        </div>
      </div>
    </div>
  ),
);
