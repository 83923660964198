export const transformationParameters = (variant, transformationObj) => {
  const transformation = transformationObj || {
    thumb: { width: 280, height: 280, fit: "crop" },
    large: { width: 400, height: 400, fit: "crop" },
    topicTeaserElement: { width: 400, height: 184, fit: "crop", crop: "top" },
    landingpageTopicTeaser: {
      width: 352,
      height: 239,
      fit: "crop",
      crop: "top",
    },
    serviceTeaser: { width: 400, height: 139, fit: "crop", crop: "top" },
    productColumnWidget: { width: 400, height: 225, fit: "crop", crop: "top" },
    mediaboxLarge: { width: 610, fit: "resize" },
    mediaboxMedium: { width: 435, fit: "resize" },
    galleryboxLarge: { width: 460, height: 400, fit: "resize" },
    productNavigationLarge: {
      width: 257,
      height: 90,
      fit: "crop",
      crop: "top",
    },
    productNavigationMedium: {
      width: 254,
      height: 120,
      fit: "crop",
      crop: "top",
    },
    productNavigationSmall: { width: 57, height: 37, fit: "crop", crop: "top" },
    productSliderSlide300: { width: 300, height: 300, fit: "resize" },
    referenceTeaser1058: { width: 1058, fit: "resize" },
    tabImage1016: { width: 1016, fit: "resize" },
    tabImage660: { width: 660, fit: "resize" },
    listItemTeaser: { width: 237, height: 183, fit: "crop", crop: "top" },
    newsItemContent: { width: 700, fit: "resize" },
    newsItemGallery: { width: 700, height: 608, fit: "resize" },
    eventItemContent: { width: 700, height: 608, fit: "resize" },
    eventItemGallery: { width: 700, height: 608, fit: "resize" },
    contentFill702: { width: 702, height: 398, fit: "crop", crop: "top" },
    galleryThumbnail: { width: 115, fit: "resize" },
    myTroxLandingPageSlider: {
      width: 1280,
      height: 380,
      fit: "crop",
      crop: "bottom",
    },
    myTroxServiceWidget: { width: 257, height: 106, fit: "crop" },
    searchResultsDropdownProductSeries: {
      width: 120,
      height: 85,
      fit: "resize",
    },
    subNavigationChild: {
      width: 190,
      height: 135,
      fit: "crop",
      focus: "center",
    },
  };
  return transformation[variant] || {};
};
