import React from "react";
import * as Scrivito from "scrivito";

import { Icon } from "../../Icon/Icon";

export const ProductListSearchControl = Scrivito.connect(() => (
  <div className="mx-2">
    <Icon prefix="trox" iconName="magnifying-glass-regular" />
  </div>
));
