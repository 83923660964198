import * as Scrivito from "scrivito";
import axios from "axios";
import { isBrowserEnv } from "utils/isWindowObjAvailable";
import logo from "assets/images/trox-logo.png";
import {
  getFullBodyData,
  getFullHeadData,
} from "../components/_ProductConfigurator/_helpers";
import { translate } from "./localsUtils";

const getOrdecodeData = (parts) =>
  parts.map((part) => part.options.map((option) => option)).flat();

export const encodeImageToBase64 = async (imageUrl) => {
  if (!imageUrl) {
    return null;
  }

  const response = await axios.get(imageUrl, { responseType: "blob" });
  const reader = new FileReader();
  reader.readAsDataURL(response.data);

  return new Promise((resolve, reject) => {
    reader.onloadend = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
  });
};

export const downloadLink = (blob, linkName) => {
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = linkName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(blob);
};

export const getProductImageUrl = async (
  imagesList,
  imageType = "ProductPhoto",
) => {
  const productImageUrl =
    imagesList?.length &&
    (await Scrivito.load(() => {
      const productPhoto = imagesList.find((image) => image.typ === imageType);
      const productImagePimId = productPhoto ? productPhoto.pimId : null;
      const imageObject = Scrivito.Obj.where(
        "pimId",
        "equals",
        productImagePimId,
      ).first();

      if (productImagePimId && imageObject) {
        return Scrivito.urlFor(imageObject);
      }
    }));
  return productImageUrl;
};

export const handlePdfReportDownload = async ({
  productData,
  setCreatePdfActive,
  isZipped = false,
  getReportCallback,
  lang,
}) => {
  const {
    product,
    productImageList: { productImages },
    specificationText: { text },
  } = await productData;

  const productImageUrl = await getProductImageUrl(productImages);

  const reportImageUrl =
    productImages?.length &&
    (await Scrivito.load(() => {
      const reportImagePimId = productImages.find(
        (image) => image.typ !== "ProductPhoto",
      )?.pimId;
      return (
        reportImagePimId &&
        Scrivito.urlFor(
          Scrivito.Obj.where("pimId", "equals", reportImagePimId).first(),
        )
      );
    }));

  const orderCodeParts = getOrdecodeData(product.ordercode.parts);
  const logoImageUrl = isBrowserEnv() ? `${window.location.origin}${logo}` : "";

  const logoImageBase64 = await encodeImageToBase64(logoImageUrl);
  const productImageBase64 = (await encodeImageToBase64(productImageUrl)) || "";
  const reportImageBase64 = (await encodeImageToBase64(reportImageUrl)) || "";

  const strategyId = product.currentStrategyId;
  const strategy = product.availableStrategies.filter(
    (s) => s.id === strategyId,
  );

  const flattedInputs = [];
  (product.inputs || []).forEach((i) =>
    i.properties.forEach((prop) =>
      flattedInputs.push({
        name: prop.presentation,
        value: `${prop.value} ${prop.unit}`,
      }),
    ),
  );
  const flattedOutputs = [];
  (product.outputs || []).forEach((o) =>
    o.properties.forEach((prop) =>
      flattedOutputs.push({
        name: prop.presentation,
        value: `${prop.value} ${prop.unit}`,
      }),
    ),
  );
  setCreatePdfActive(true);

  return getReportCallback({
    languageIso2L: lang,
    viewImages: [
      {
        type: "Logo",
        image: logoImageBase64.split(",")[1],
      },
      {
        type: "Product",
        image: productImageBase64.split(",")[1],
      },
      {
        type: "Report",
        image: reportImageBase64.split(",")[1],
        imageHeader: translate(
          "PRODUCT_CONFIGURATOR_REPORT_SCHEMATIC_SIDE_VIEW_LABEL",
        ),
      },
    ],
    orderCode: {
      orderCodePresentation: product.ordercode.presentation,
      orderCodeParts: orderCodeParts
        .filter((part) => part.state === 0)
        .map((part) => ({
          name: part.title,
          value: part.presentation || part.currentSignature.value,
          description: part.currentSignature.title,
        })),
    },
    inputs: {
      headerLabel: translate("PRODUCT_CONFIGURATOR_REPORT_INPUT_LABEL"),
      ioReportList: [
        ...flattedInputs,
        {
          name: translate("PRODUCT_CONFIGURATOR_REPORT_STRATEGY"),
          value: strategy[0]?.presentation,
        },
      ],
    },
    outputs: {
      headerLabel: translate("PRODUCT_CONFIGURATOR_REPORT_RESULTS_LABEL"),
      ioReportList: flattedOutputs,
    },
    productAcousticHeaders: {
      acousticLabel: translate("PRODUCT_CONFIGURATOR_REPORT_ACOUSTING_LABEL"),
      acousticHeaders: getFullHeadData(product.outputsAcoustic),
    },
    acousticData: {
      productAcousticList: getFullBodyData(product.outputsAcoustic),
    },
    descriptionText: {
      descriptionLabel: translate("PRODUCT_CONFIGURATOR_REPORT_DESCRRIPTION"),
      description: text,
    },
    airflowPatternReport: null,
    notes: null,
    version: "EPF Version 2.10.3.5",
  }).then(({ data }) => {
    if (data && data.createProductReport?.base64Data) {
      const atobw = Uint8Array.from(
        atob(data.createProductReport?.base64Data),
        (c) => c.charCodeAt(0),
      );
      const blob = new Blob([atobw], {
        type: "application/pdf",
      });
      if (isZipped) {
        setCreatePdfActive(false);
        return blob;
      }
      downloadLink(blob, `${product.ordercode.presentation}.pdf`);
    }
    setCreatePdfActive(false);
  });
};
