import { useEffect } from "react";

export const useProjectTreeIntersectionObserver = (targetRef, callback) => {
  useEffect(() => {
    const options = {
      threshold: 0.1,
      rootMargin: "0px",
    };
    const observer = new IntersectionObserver(callback, options);

    if (targetRef.current) {
      observer.observe(targetRef.current);

      return () => {
        observer.disconnect();
      };
    }
  }, [callback, targetRef]);
};
