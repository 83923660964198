const handleProjectListFilterUpdate = (filter, { checkingWord, id }) => {
  // Uncheck "All Projects" and "Shared" ("Not Shared"),
  // check "Not shared" ("Shared") and don't touch others
  if (
    (filter.id === "all-projects" && filter.isActive) ||
    (checkingWord && checkingWord === filter.name)
  ) {
    filter.isActive = false;
  }
  if (filter.id === id) {
    filter.isActive = !filter.isActive;
  }
  return filter;
};

export const handleProjectListFiltersUpdate = (prevFilters, options) => {
  const { id } = options;
  const isIdActive = prevFilters.some(
    (filter) => filter.id === id && filter.isActive,
  );

  const updatedFilters = prevFilters.map((filter) => {
    const updatedFilter = handleProjectListFilterUpdate(filter, options);

    if (filter.id === "all-projects" && !isIdActive && updatedFilter.isActive) {
      updatedFilter.isActive = false;
    }

    return updatedFilter;
  });

  const noOtherFilterActive = updatedFilters.every(
    (filter) => filter.id === "all-projects" || !filter.isActive,
  );

  if (noOtherFilterActive) {
    const allProjectsFilter = updatedFilters.find(
      (filter) => filter.id === "all-projects",
    );
    allProjectsFilter.isActive = true;
  }
  return updatedFilters;
};

export const activateFilter = (filters, activeId) => {
  const res = filters.map((filter) => ({
    ...filter,
    isActive: filter.id === activeId,
  }));
  return res;
};

export const updateProjectFilters = (
  translations,
  overallStatistics,
  projectsCount,
  setSearchQuery,
  setSharedStatusSwitcher,
  setFilters,
  projectStatuses,
) => {
  const newFilters = [
    {
      id: "all-projects",
      name: translations.allProjects,
      count: projectsCount,
      onClick: () => {
        setSearchQuery(null);
        setSharedStatusSwitcher(null);
        setFilters((prevFilters) =>
          activateFilter(prevFilters, "all-projects"),
        );
      },
      isActive: true,
    },
  ];

  if (overallStatistics && Object.keys(overallStatistics).length) {
    // "Not Shared" filter
    newFilters.push({
      id: "not-shared",
      name: translations.notShared,
      count: overallStatistics.notShared,
      icon: "user-regular",
      iconPrefix: "trox",
      onClick: () => {
        setSharedStatusSwitcher((prev) =>
          prev === null || prev ? false : null,
        );
        setFilters((prevFilters) =>
          handleProjectListFiltersUpdate(prevFilters, {
            checkingWord: translations.shared,
            id: "not-shared",
          }),
        );
      },
    });

    // "Shared" filter
    newFilters.push({
      id: "shared",
      name: translations.shared,
      count: overallStatistics.shared,
      icon: "user-group-regular",
      iconPrefix: "trox",
      onClick: () => {
        setSharedStatusSwitcher((prev) =>
          prev === null || !prev ? true : null,
        );
        setFilters((prevFilters) =>
          handleProjectListFiltersUpdate(prevFilters, {
            checkingWord: translations.notShared,
            id: "shared",
          }),
        );
      },
    });

    // Status filters
    projectStatuses.forEach(({ status, color }) => {
      newFilters.push({
        id: status,
        name: status,
        count: overallStatistics?.statuses?.[status],
        color,
        isActive: false,
        isStatusFilter: true,
      });
    });
  }

  return newFilters;
};
